/**
 * Delay for showing loading indicator. In ms.
 */
export const LOADING_DELAY = 250;

/**
 * Supported cultures 
 */
export const SUPPORTED_LANGUAGES = [
    {
        key: 'en',
        keyLong: 'en-US',
        label: 'English',
        iso: 'eng'
    },
    {
        key: 'de',
        keyLong: 'de-DE',
        label: 'Deutsch',
        iso: 'deu'
    },
    {
        key: 'fr',
        keyLong: 'fr-FR',
        label: 'Français',
        iso: 'fra'
    },
    {
        key: 'hr',
        keyLong: 'hr-HR',
        label: 'Hrvatski',
        iso: 'hrv'
    },
    {
        key: 'it',
        keyLong: 'it-IT',
        label: 'Italiano',
        iso: 'ita'
    },
    {
        key: 'tr',
        keyLong: 'tr-TR',
        label: 'Türkçe',
        iso: 'tur'
    },
    {
        key: 'sq',
        keyLong: 'sq-AL',
        label: 'Shqip',
        iso: 'sqi'
    },
    {
        key: 'sv',
        keyLong: 'sv-SE',
        label: 'Svenska',
        iso: 'swe'
    },
    {
        key: 'da',
        keyLong: 'da-DK',
        label: 'Dansk',
        iso: 'dan'
    }
]