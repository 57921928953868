import { httpClient } from '../../../utils/http';

const url = `${BaseAddress}${App.utils.getAgencyKey()}/reality-checks`;
const resolveUrl = `${BaseAddress}${App.utils.getAgencyKey()}/reality-checks/`;
export class RealityCheckService {
    async getRealityCheckData() {
        return await httpClient.get(url, null, { responseType: 'json' });
    }

    async resolveRealityCheckData(userId, logout = false) {
        const requestHeaders = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        return await httpClient.get(`${resolveUrl}?logout=${logout}`, requestHeaders);
    }


}