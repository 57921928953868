const httpClient = App.utils.httpClient;

/**
 * @typedef {Object} HighlightOffer
 * @property {string} tournament
 * @property {string} dateTime
 * @property {string} teamOne
 * @property {string} teamTwo
 * @property {{tip: string, value: number}[]} odds
 */

/** 
* @typedef {Object} TopTournamentEntry
* @property {number} sortOrder
* @property {string} topTournamentId
* @property {string} tournamentId
* @property {Tournament[]} tournament
*/

/** 
 * @typedef {Object} TopTournament
 * @property {string} abrv
 * @property {string} description
 * @property {string} iconUrl
 * @property {string} name
 * @property {string[]} tournamentIds
 */

/**
 * @typedef {Object} Tournament
 * @property {string} name
 * @property {string} abrv
 * @property {string} id
 * @property {SportCategory} sportCategory
 */

/**
 * @typedef {Object} SportCategory
 * @property {string} name
 * @property {string} abrv
 * @property {string} id
 * @property {string} sportId
 * @property {number} sortOrder
 * @property {Sport} sport
 */

/**
 * @typedef {Object} Sport
 * @property {string} name
 * @property {string} abrv
 * @property {string} id
 * @property {number} sortOrder
 */


class HighlightOfferService {

    /**
     * @returns {Promise<TopTournament[]>}
     */
    async findTopTournaments() {
        const url = '/api/promo/tournaments';

        return await httpClient.get(url, {
            'Content-Type': 'application/json'
        }, {
            responseType: 'json'
        });
    }
}

export default HighlightOfferService;