import { observable, action, computed } from 'mobx';

import { OddTypeStore } from '@gp/gp-javascript-utils';
import { NameProvider } from '@gp/utility';

import { WebHub } from '../hubs/offer';
import { TvInfoService } from '../services/components';
import {
    PrematchSubheaderViewStore,
    LiveSubheaderViewStore,
} from './components/subheader';
import { PrematchMenuViewStore, LiveMenuViewStore, LiveEventMenuViewStore } from './components/sports-menu';
import { BetSlipStore } from './components/bet-slip';
import { SearchBarViewStore } from './components/search';
import { StatisticsStore } from './components/statistics';
import { ResultsDataStore, ResultsMenuStore } from '../../ui/pages/results/stores';
import ResizeStore from './ResizeStore';

class RootStore {
    @observable isAdditionalOfferVisible = false;

    /**
     * Register modal is displayed after successfull register on live screen
     * It uses isFromRegister query string parameter to determine whether to show modal or no
     */
    @observable displayRegisterModal = false;

    get hub() {
        return this.webHub.offerHub;
    }

    get cashoutHub() {
        return this.webHub.cashoutHub;
    }

    constructor() {
        this.nameProvider = new NameProvider();
        this.webHub = new WebHub();

        this.resizeStore = new ResizeStore();
        this.prematchSubheaderViewStore = new PrematchSubheaderViewStore(this);
        this.liveSubheaderViewStore = new LiveSubheaderViewStore(this);
        this.prematchMenuViewStore = new PrematchMenuViewStore(this);
        this.liveMenuViewStore = new LiveMenuViewStore(this);
        this.liveEventMenuViewStore = new LiveEventMenuViewStore(this);

        this.oddTypeStore = new OddTypeStore();
        this.searchBarViewStore = new SearchBarViewStore();
        this.statisticsStore = new StatisticsStore();

        // must be before betSlipStore
        this.betSlipStore = new BetSlipStore(this);

        this.resultsMenuStore = new ResultsMenuStore();
        this.resultsDataStore = new ResultsDataStore();

        this.tvInfoService = new TvInfoService();
    }

    @action.bound
    async onInitialize() {
        await this.webHub.connect();
    }

    @action.bound
    onDispose() {
        this.webHub.destroy();
    }

    @action.bound
    setAdditionalOfferVisible(value) {
        this.isAdditionalOfferVisible = value;
    }

    @action.bound
    setDisplayRegisterModal(value) {
        this.displayRegisterModal = value;
    }

    @action.bound
    onCloseRegisterModal() {
        this.displayRegisterModal = false;
    }
}

export default RootStore;