import { LiveStatus } from '@gp/models'

const PeriodConverter = App.state.PeriodConverter
/**
 * Takes period value as string and returns filter that is used in creating request for prematch offer menu.
 * @param {string} period
 * @returns {Filter}
 */
export function generatePrematchOfferMenuFilter(period) {

    const filter = {
        liveStatus: LiveStatus.PREMATCH,

    }

    if (period !== 'full' && PeriodConverter.IsValidPeriod(period)) {
        filter.startTime = {
            lt: PeriodConverter.CalculateOfferInterval(period),
        }
    }

    return [filter]
}