import { action, runInAction } from 'mobx';
import BasePopupStore from "./BasePopupStore";

class LiveSessionExpireStore extends BasePopupStore {
    // TODO: MAYBE MOVE THIS TO CONFIGURATION
    liveSessionExpireDuration = 600;

    constructor(rootStore) {
        super();
        this.rootStore = rootStore;

        this.onInitialize();

        document.addEventListener('click', this.onClick);
    }

    onInitialize() {
        const isLiveOfferPathnameActive = window.location.pathname.includes(`/${App.utils.getCurrentCulture()}/live`);

        if (isLiveOfferPathnameActive && !this.rootStore.automaticLogoutStore.isActive) {
            this.startTimer(this.liveSessionExpireDuration);
        }
    }

    @action.bound
    startTimer(expiresIn) {
        this.clearTimer();

        const shouldDisplayLiveSessionExpire = () => {
            return window.location.pathname.includes('live') && !App.state.rootStore.realityCheckStore.isActive && !App.state.rootStore.sessionExpireStore.isActive;
        }

        this.timerId = setTimeout(() => {
            if (shouldDisplayLiveSessionExpire()) {
                runInAction(() => {
                    // stop all sessions
                    this.rootStore.triggerDisconnect();

                    this.rootStore.sessionExpireStore.clearTimer();
                    this.activatePopup();
                });
            }
        }, expiresIn * 1000);
    }

    @action.bound
    onRefresh() {

        App.state.rootStore.userAuthStore.resolveConnection();
        this.startTimer(600);
        this.deactivatePopup();
    }


    onClick = () => {
        const isLiveOfferPathnameActive = App.state.history.location.pathname.includes(`/${App.utils.getCurrentCulture()}/live`);

        if (isLiveOfferPathnameActive && !App.state.rootStore.realityCheckStore.isActive && !App.state.rootStore.automaticLogoutStore.isActive && !App.state.rootStore.sessionExpireStore.isActive) {
            this.startTimer(this.liveSessionExpireDuration);
        }
    }
}

export default LiveSessionExpireStore;