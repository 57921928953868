const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
}
class BetSlipApiService {

    get baseUrl() {
        return `${BaseAddress}${App.utils.getAgencyKey()}/bet-slips`;
    }

    async findBets(filter = null, page = 1, rpp = 10, sort = 'dateCreated|desc') {
        let query;
        if (filter) {
            query = new URLSearchParams(filter);
        } else {
            query = new URLSearchParams();
        }
        query.set('page', page);
        query.set('rpp', rpp);
        query.set('sort', sort);
        query.set('translate', true);
        return await httpClient.get(
            `${this.baseUrl}?${query}`,
            requestHeaders,
            { responseType: "json" }
        )
    }

    async getBet(slipId, embed = '') {
        return await httpClient.get(
            `${this.baseUrl}/${slipId}?embed=${embed}`,
            requestHeaders,
            { responseType: "json" }
        )
    }

    getSystemCombinations(id) {
        return httpClient.get(`${this.baseUrl}/${id}/combinations`, requestHeaders, { responseType: "json" });
    }

    getCashoutHistory(id) {
        return httpClient.get(`${this.baseUrl}/${id}/bet-slip-jacktime-cashout-history-value`, requestHeaders, { responseType: "json" });
    }

    putCancelBet(id) {
        return httpClient.put(`${this.baseUrl}/${id}/cancel`, null, requestHeaders, { responseType: "json" });
    }

}

export default BetSlipApiService;