import { observable, action } from "mobx";
import { BettingActivityService } from "../../../ui/pages/membership/betting-activity/services";

class BettingActivityStore {

    @observable bettingActivityFetchedData = null;
    @observable isLoading = false;
    @observable bettingActivityError = null;

    constructor() {
        this.bettingActivityService = new BettingActivityService();
        const isBettingActivityNeeded = JSON.parse(localStorage.getItem("user"));
        if (isBettingActivityNeeded != null && isBettingActivityNeeded.bettingActivityNeeded) {
            this.fetchBettingActivityData();
        }
    }

    @action.bound
    setIsLoading(data) {
        this.isLoading = data;
    }

    @action.bound
    setBettingActivityError(error) {
        this.bettingActivityError = error;
    }

    @action.bound
    async fetchBettingActivityData() {
        try {
            this.setIsLoading(true)
            const response = await this.bettingActivityService.getBettingActivityData();
            this.bettingActivityFetchedData = response?.data;
        }
        catch (error) {
            console.error(error);
            this.bettingActivityError = error;
        } finally {
            this.setIsLoading(false)
        }
    }
    @action.bound
    async updateBettingActivityReview() {
        try {
            await this.bettingActivityService.updateBettingActivityReview();
        }
        catch (error) {
            console.error(error);
        }
    }

}

export default BettingActivityStore;