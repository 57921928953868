import { observable, computed, action, runInAction, reaction } from 'mobx';
import { MyBetsService } from '../../../../services/components';

export class PlayedLiveEventsStore {

    //#region  observables

    @observable eventIds = [];
    playedOneClickTips = [];
    @observable count = 0;

    //#endregion observables


    //#region computed

    @computed get isEnabled() {
        return App.state.rootStore.userAuthStore.user != null;
    }

    //#endregion computed



    //#region  constructor

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.myBetsService = new MyBetsService();
    }

    //#endregion constructor



    //#region data fetching 

    @action.bound
    async onInitialize() {

        this.disposeUserAuthenticatedReaction();

        if (!this.isEnabled) {
            this.eventIds = [];
            this.count = 0;
            return;
        }

        this.userAuthenticatedReaction = reaction(
            () => ({ isEnabled: this.isEnabled }),
            () => {
                if (!this.isEnabled) {
                    this.onDispose();
                }
            },
            {
                fireImmediately: true
            }
        )

        try {
            const result = await this.myBetsService.getEventIdsFromLiveBets();
            runInAction(() => {
                this.eventIds = result;
            })
        } catch (e) {
            console.error(e);
        }

        this.initSubscription();
    }

    @action.bound
    initSubscription() {
        this.disposeSubscription();

        if (this.eventIds.length === 0) {
            this.eventIds = [];
            this.count = 0;
            return;
        }

        const filter = {
            subscriptionId: 'live-from-my-bets-counter',
            count: {
                playedEventsCount: {
                    liveStatus: 1,
                    id: {
                        eq: this.eventIds.peek()
                    }
                },
            }
        }

        this.subscription = this.rootStore.hub.getCounts(filter)
            .subscribe(response => {
                runInAction(() => {
                    if (!isNaN(response.playedEventsCount)) {
                        this.count = response.playedEventsCount;
                    }
                });
            }, err => {
                console.error("live from my bets count error", err);
                runInAction(() => {
                    this.count = 0;
                });
            });

    }

    //#endregion data fetching



    //#region actions

    @action.bound
    updateEvents(eventIds) {

        let haveAddedNewEventId = false;

        for (const eventId of eventIds) {
            if (!this.eventIds.includes(eventId)) {
                this.eventIds.push(eventId);
                haveAddedNewEventId = true;
            }
        }

        if (haveAddedNewEventId) {
            this.initSubscription();
        }
    }

    @action.bound
    addPlayedOneClickTip(tip) {
        if (tip == null) {
            return;
        }

        this.playedOneClickTips.push(tip);
    }

    @action.bound
    successfulOneClickTips(tipIds) {

        const eventIds = [];
        for (const tipId of tipIds) {

            const indexOfTip = this.playedOneClickTips.findIndex(tip => tip.id === tipId);

            if (indexOfTip === -1) {
                return;
            }

            const tip = this.playedOneClickTips[indexOfTip];
            this.playedOneClickTips.splice(indexOfTip, 1);

            eventIds.push(tip.eventId);
        }

        this.updateEvents(eventIds);
    }

    //#endregion actions



    //#region disposers

    @action.bound
    onDispose() {
        this.disposeUserAuthenticatedReaction();
        this.disposeSubscription();
        this.eventIds = [];
        this.count = 0;
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    disposeUserAuthenticatedReaction() {
        if (this.userAuthenticatedReaction != null) {
            this.userAuthenticatedReaction();
            this.userAuthenticatedReaction = null;
        }
    }

    //#endregion disposers
}