const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
};
const baseUrl = 'api/lookups';
class LookupApiService {
    async findLookup(type) {
        const url = `/${baseUrl}/${type}`;
        const response = await httpClient.get(url, requestHeaders, { responseType: 'json' });
        return response;
    }
}

export default LookupApiService;
