import { resultsService as ResultsDataService } from "../../../../administration/services/results";
import { Response } from "../../../../administration/services/common";
import { DateTime } from "luxon";
import { ScoreType } from "../../../../administration/services/results";
import { orderBy } from 'lodash';

class ResultsService {
    constructor() {}

    async getMenu(filter) {
        const fetchedResult = await ResultsDataService.getMenu(filter);

        const createTournament = (data) => {
            return {
                id: data.tournament.id,
                sportId: data.sport.id,
                categoryId: data.sportCategory.id,
                abrv: data.tournament.abrv,
                name: data.tournament.name,
                count: data.eventCount,
                children: null
            };
        };

        const createCategory = (data) => {
            return {
                id: data.sportCategory.id,
                abrv: data.sportCategory.abrv,
                name: data.sportCategory.name,
                count: data.eventCount,
                children: [createTournament(data)]
            };
        }

        const createSport = (data) => {
            return {
                id: data.sport.id,
                abrv: data.sport.abrv,
                name: data.sport.name,
                count: data.eventCount,
                children: [createCategory(data)]
            };
        }

        const orderedResults = orderBy(fetchedResult, ['sport.sortOrder', 'sport.id', 'sportCategory.sortOrder', 'sportCategory.id', 'tournament.sortOrder', 'tournament.id']);

        const formattedResult = orderedResults.reduce((acc, item) => {
            const sportIdx = acc.findIndex(s => s.id === item.sport.id);

            if (sportIdx > -1) {
                const sport = acc[sportIdx];
                const sportCategoryIdx = sport.children ? sport.children.findIndex(c => c.id === item.sportCategory.id) : -1;

                if (sportCategoryIdx > -1) {
                    const category = sport.children[sportCategoryIdx];
                    const tournamentIdx = category.children ? category.children.findIndex(t => t.id === item.tournament.id) : -1;

                    if (tournamentIdx > -1) {
                        category.children[tournamentIdx].count += item.eventCount;
                        category.count += item.eventCount;
                        sport.count += item.eventCount;
                    }
                    else {
                        category.children.push(createTournament(item));
                        category.count += item.eventCount;
                        sport.count += item.eventCount;
                    }
                }
                else {
                    sport.children.push(createCategory(item));
                    sport.count += item.eventCount;
                }
            }
            else {
                acc.push(createSport(item));
            }

            return acc;
        }, []);

        return new Response(formattedResult);
    }

    async getResults(filter) {
        let fetchedResult = await ResultsDataService.getResults(filter);

        if (filter.tournamentIds == null) {
            fetchedResult = orderBy(fetchedResult, ['event.tournament.sport.sportSetting.sortOrder', 'event.startTime'], ['asc', 'desc']);
        }
        else {
            fetchedResult = fetchedResult.sort((a,b) => filter.tournamentIds.indexOf(a.event.tournament.id) - filter.tournamentIds.indexOf(b.event.tournament.id));
        }

        const formattedResult = fetchedResult.reduce((acc, item) => {
            const itemWithSportAndCategoryIdx = acc.findIndex(r => r.sport.id === item.event.tournament.sport.id && r.sportCategory.id == item.event.tournament.sportCategory.id);

            const eventData = item.event;
            const teamOne = eventData.teamOne;
            const teamTwo = eventData.teamTwo;
            const resultData = eventData.resultParsed;
            const pens = resultData?.scorePerPeriod?.find(item => item.periodType === 'PEN');
            const eventDate = DateTime.fromISO(eventData.startTime).toFormat('cccc,dd.LL ', { locale: App.utils.getCurrentCulture() });
            const bettingOffer = item.bettingOffers != null ? item.bettingOffers.find(tip => tip.feedType === 0) : null;

            const filteredServiceId = eventData.externalServiceIds?.find(x => x.providerId === 'BetRadarId');
            let sourceData = null;

            if (filteredServiceId != null) {
                const extractedProviderId = filteredServiceId.ids[0]?.match(/(?!sr:match:)(\d+)(?=([+]?))/gm);
                sourceData = {
                    providerId: extractedProviderId != null ? extractedProviderId[0] : null,
                    providerName: filteredServiceId.providerId
                };
            }

            const createEventName = (name, gender) => {
                return `${name}${gender === 1 ? ` ${App.state.localizationService.t('OFFER.GENDER.WOMEN')}` : ''}`
            };

            const scorePerPeriodFormatted = resultData.scorePerPeriod ? resultData.scorePerPeriod.filter(item => item.periodType !== 'OT' && item.periodType !== 'PEN').map(item => {
                return {
                    home: item.home,
                    away: item.away,
                    name: item.name
                };
            }) : null;

            var mappedEvent = {
                id: eventData.id,
                teamOneId: teamOne.id,
                teamOneName: teamOne.name,
                teamTwoId: teamTwo.id,
                teamTwoName: teamTwo.name,
                startTime: eventData.startTime,
                source: sourceData,
                tournamentGroupId: eventData.tournamentGroupId,
                fullTime: {
                    away: 0,
                    home: 0
                },
                teamOne: createEventName(teamOne.name, teamOne.gender),
                teamTwo: createEventName(teamTwo.name, teamTwo.gender),
                matchStatus: {
                    name: eventData.matchStatus?.name,
                    abrv: eventData.matchStatus?.abrv
                },
                tournament: {
                    sport: {
                        abrv: eventData.tournament?.sport?.abrv
                    }
                },
                fullTime: resultData.fullTimeScore ? { home: resultData.fullTimeScore.home, away: resultData.fullTimeScore.away } : null,
                halfTime: resultData.halfTimeScore ? { home: resultData.halfTimeScore.home, away: resultData.halfTimeScore.away } : null,
                scorePerPeriod: scorePerPeriodFormatted,
                penalties: pens ? { home: pens.home, away: pens.away, name: pens.name } : null,
                overTimeScore: resultData.overTimeScore ? { home: resultData.overTimeScore.home, away: resultData.overTimeScore.away } : null,
                tip: bettingOffer != null ? bettingOffer.bettingTypeTip : '-',
                odd: bettingOffer != null ? `${bettingOffer.providerValue}` : '-'
            };

            if (itemWithSportAndCategoryIdx > -1) {
                const existingItem = acc[itemWithSportAndCategoryIdx];

                if (existingItem.tournaments == null) {
                    existingItem.tournaments = [];
                }

                const existingTournamentIdx = existingItem.tournaments.findIndex(t => t.id === eventData.tournament.id);

                if (existingTournamentIdx > -1) {

                    if (existingItem.tournaments[existingTournamentIdx].events == null) {
                        existingItem.tournaments[existingTournamentIdx].events = new Map();
                    }

                    if (existingItem.tournaments[existingTournamentIdx].events.has(eventDate)) {
                        existingItem.tournaments[existingTournamentIdx].events.get(eventDate).push(mappedEvent);
                    }
                    else {
                        existingItem.tournaments[existingTournamentIdx].events.set(eventDate, [mappedEvent]);
                    }
                }
                else {
                    const filteredServiceId = eventData.tournamentSeason.externalServiceIds?.find(x => x.providerId === 'BetRadarId');

                    let tournament = {
                        id: eventData.tournament.id,
                        name: eventData.tournament.name,
                        abrv: eventData.tournament.abrv,
                        events: new Map().set(eventDate, [mappedEvent])
                    };

                    if (filteredServiceId != null) {
                        const extractedProviderId = filteredServiceId.ids[0]?.match(/(?!sr:season:)(\d+)(?=([+]?))/gm);
                        tournament.source = {
                            providerId: extractedProviderId != null ? extractedProviderId[0] : null,
                            providerName: filteredServiceId.providerId
                        };
                    }

                    existingItem.tournaments.push(tournament);
                }
            }
            else {
                const sport = {
                    id: eventData.tournament.sport.id,
                    name: eventData.tournament.sport.name,
                    abrv: eventData.tournament.sport.abrv
                };

                const sportCategory = {
                    id: eventData.tournament.sportCategory.id,
                    name: eventData.tournament.sportCategory.name,
                    abrv: eventData.tournament.sportCategory.abrv
                };

                const tournament = {
                    id: eventData.tournament.id,
                    name: eventData.tournament.name,
                    abrv: eventData.tournament.abrv,
                    events: new Map().set(eventDate, [mappedEvent])
                };

                const filteredServiceId = eventData.tournamentSeason.externalServiceIds?.find(x => x.providerId === 'BetRadarId');

                if (filteredServiceId != null) {
                    const extractedProviderId = filteredServiceId.ids[0]?.match(/(?!sr:season:)(\d+)(?=([+]?))/gm);
                    
                    tournament.source = {
                        providerId: extractedProviderId != null ? extractedProviderId[0] : null,
                        providerName: filteredServiceId.providerId
                    };
                }

                const itemFinalModel = {
                    sport: sport,
                    sportCategory: sportCategory,
                    tournaments: [tournament]
                };

                switch (sport.abrv)
                {
                    case "tennis":
                    case "badminton":
                    case "volleyball":
                    case "beach-volley":
                    case "aussie-rules":
                    case "field-hockey":
                    case "american-football":
                        itemFinalModel.scoreType = ScoreType.PERPERIOD;
                        break;

                    case "darts":
                    case "formula-1":
                    case "bikes":
                    case "boxing":
                    case "mma":
                    case "cycling":
                    case "cricket":
                    case "table-tennis":
                    case "bowls":
                    case "squash":
                    case "snooker":
                    case "stock-car-racing":
                        itemFinalModel.scoreType = ScoreType.FTONLY;
                        break;

                    case "baseball":
                        itemFinalModel.scoreType = ScoreType.FTONLYWITHOT;
                        break;

                    case "ice-hockey":
                    case "waterpolo":
                    case "basketball":
                    case "floorball":
                        itemFinalModel.scoreType = ScoreType.PERPERIODWITHOT;
                        break;

                    case "rugby":
                    case "soccer":
                    case "futsal":
                    case "handball":
                        itemFinalModel.scoreType = ScoreType.HEAD2HEADWITHOT;
                        break;

                    default:
                        itemFinalModel.scoreType = ScoreType.HEAD2HEAD;
                        break;
                }

                switch (sport.abrv)
                {
                    case "ice-hockey":
                        itemFinalModel.columnsCount = 3;
                        break;

                    case "aussie-rules":
                    case "basketball":
                    case "waterpolo":
                    case "field-hockey":
                    case "american-football":
                    case "floorball":
                        itemFinalModel.columnsCount = 4;
                        break;

                    case "tennis":
                    case "volleyball":
                    case "beach-volley":
                    case "badminton":
                        itemFinalModel.columnsCount = 5;
                        break;

                    default:
                        itemFinalModel.columnsCount = 0;
                        break;
                }

                acc.push(itemFinalModel);
            }

            return acc;
        }, []);

        return new Response(formattedResult);
    }
}


const resultsService = new ResultsService();

export { resultsService };