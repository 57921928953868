import { observable, computed, action, reaction } from 'mobx';

import { SportMenuItem } from '../../../../services/components/SportsMenuService';
import { NodeStore, Node } from './';

const logger = App.state.logger;

/**
 * @typedef {Object} CommonStructure 
 * @property {string} culture
 * @property {string} period
 * @property {string[]} sports
 * @property {string[]} categories
 * @property {string[]} tournaments
 * @property {string[]} specials
 */

class TreeStore {
    manualCollapse = false;

    /**
     * @type {CommonStructure}
     */
    initialState;

    /**
     * @type {NodeStore[]}
     */
    @observable nodes = [];

    /**
     * @type {string[]}
     */
    selected = [];
    specials = [];

    /**
     * 
     * @param {SportMenuItem[]} rawMenu 
     * @param {{selected: CommonStructure}} initialState 
     */
    constructor(rawMenu, initialState = {}) {
        this.initialState = initialState;

        this.selected = [...initialState.selected.sports, ...initialState.selected.categories, ...initialState.selected.tournaments];
        this.specials = initialState.selected.specials || [];

        const menu = this.mapSportsMenu(rawMenu);

        // create node stores from mapped menu
        this.nodes = menu.map(m => new NodeStore(m, this));

        this.urlSyncReactionDisposer = reaction(() => this.selectedItems, items => {
            if (this.manualCollapse) {
                this.manualCollapse = false;
                return;
            }

            const url = items.url
            logger.logTrace("Structured sports filter url:", url);


            window.scrollTo(0, 0);

            if (!url || url === '') {
                App.state.redirectPreserveSearchParams(`/${initialState.culture}/sports/${initialState.period}`);
                return;
            }

            App.state.redirectPreserveSearchParams(`/${initialState.culture}/sports/${initialState.period}/f${url}`)
        });
    }

    onDispose() {
        if (this.urlSyncReactionDisposer != null) {
            this.urlSyncReactionDisposer();
            this.urlSyncReactionDisposer = null
        }
    }

    /**
     * @returns {{normal: string[], specials: string[]}} List of tournamentIds
     * @todo 3-nested loop, maybe optimize using flatten state ?!
     */
    @computed get selectedTournaments() {
        let output = {
            isEmpty: true,
            normal: [],
            specials: []
        };

        this.nodes
            .filter(s => s.isSomeChildChecked)
            .forEach(s => {
                s.children
                    .filter(sc => sc.isSomeChildChecked)
                    .forEach(sc => {
                        sc.children.filter(t => t.checkState === 1).forEach(t => {
                            if (t.node.value === 'special') output.specials.push(sc.node.value);
                            else output.normal.push(t.node.value);
                        })
                    });
            });

        output.isEmpty = !(output.normal.length > 0 || output.specials.length > 0);

        return output;
    }

    /**
     * @returns {boolean} True if there are any selected sports, categories or tournaments
     */
    @computed get hasSelected() {
        return this.selectedCounts.sports > 0 || this.selectedCounts.categories > 0 || this.selectedCounts.tournaments > 0;
    }

    /**
     * @returns {{sports: number, categories: number, tournaments: number}} Number of selected items in sports, categories and tournaments
     */
    @computed get selectedCounts() {
        const { sports, categories, tournaments } = this.selectedItems;

        return {
            sports: sports.length,
            categories: categories.length,
            tournaments: tournaments.length
        }
    }

    /**
     * @returns {{sports:[], categories:[], tournaments:[], segments:[], url: string}}
     */
    @computed get selectedItems() {
        // let selected = [];
        const selected = {
            sports: [],
            categories: [],
            tournaments: [],
            segments: []
        };

        this.nodes.forEach(sport => {
            if (sport.checkState > 0) {
                let resultString = `/${sport.node.value}`;

                if (sport.checkState === 2) {
                    sport.children.forEach(category => {
                        if (category.checkState > 0) {
                            resultString += `|${category.node.value}`;

                            if (category.checkState === 2) {
                                category.children.forEach(tournament => {
                                    if (tournament.checkState === 1) {
                                        resultString += `-${tournament.node.value}`;
                                        selected.tournaments.push(tournament.node.value);
                                    }
                                });
                            }
                            else {
                                selected.categories.push(category.node.value);
                            }
                        }
                    });
                }
                else {
                    selected.sports.push(sport.node.value);
                }

                selected.segments.push(resultString);
            }
        });

        // return selected.join('');
        const url = selected.segments.join('');

        return {
            ...selected,
            url: url
        };
    }


    @action.bound
    collapseTree() {
        if (this.hasSelected) {
            this.manualCollapse = true;
        }

        this.nodes.forEach(n => n.collapse());
    }

    /**
     * @param {SportMenuItem[]} menu
     * @returns {Node[]}
     */
    mapSportsMenu = (menu) => {
        const mapped = menu.map(sport => {
            // SPORTS
            return {
                label: sport.sport.name,
                abrv: sport.sport.abrv,
                value: sport.sport.id,
                count: sport.count,
                children: sport.subItems.map(category => {
                    // CATEGORIES
                    return {
                        label: category.sportCategory.name,
                        abrv: category.sportCategory.abrv,
                        value: category.sportCategory.id,
                        count: category.count,
                        children: category.subItems.map(tournament => {
                            // TOURNAMENTS
                            return {
                                label: tournament.tournament.name,
                                abrv: tournament.tournament.abrv,
                                value: tournament.tournament.abrv === 'special-offer' ? 'special' : tournament.tournament.id,
                                count: tournament.count
                            };
                        })
                    };
                })
            };
        });

        return mapped;
    }
}

export default TreeStore;