import { observable, action, computed } from 'mobx';
import { insert, Sorter } from '@gp/utility';

export class BaseNode {

    //#region  observable

    @observable parent = null;
    @observable node = {};
    @observable children = [];

    //#endregion observable

    //#region computed

    /**
    * If true, node is parent
    * @returns {boolean} True if node has children
    */
    @computed get isParent() {
        return this.children.length > 0;
    }

    /**
     * @returns {boolean} True if node does not have children
     */
    @computed get isLeaf() {
        return !this.isParent;
    }

    /**
     * @returns {boolean} True if node has parent
     */
    @computed get isChild() {
        return this.parent != null;
    }

    //#endregion computed


    //#region constructor

    constructor(parent = null) {
        this.parent = parent;
    }

    //#endregion constructor



    //#region assign data

    /**
     * 
     * @param {array} rawStructure array of items containing:
     * n for node 
     * c for children
     */
    @action.bound
    assignStructure(rawStructure) {

        if (rawStructure == null) {
            return;
        }

        const { n, c } = rawStructure;

        this.assignChildren(c);

        this.assignNodeData(n);
    }

    /**
     * 
     * @param {array} children array of items containing n and c
     */
    @action.bound
    assignChildren(children) {

        if (children == null) {
            this.children = [];
            return;
        }

        this.pruneChildren(children)

        for (const childItem of children) {

            const { n } = childItem;

            const childInMyChildren = this.children.find(child => child.node.id === n.id);
            if (childInMyChildren != null) {
                childInMyChildren.assignStructure(childItem);
                continue;
            }

            this.insertNewChild(childItem);
        }
    }

    @action.bound
    insertNewChild(childItem) {
        const newChild = new this.constructor(this);
        newChild.assignStructure(childItem);

        if (newChild.node.sortOrder == null) {
            this.children.push(newChild);
            return;
        }

        if(this.children.length === 0) {
            this.children.push(newChild);
            return;
        }

        insert(this.children, newChild, Sorter.sort(
            (a, b) =>  {
                if(parseInt(a.node.sortOrder) == NaN || parseInt(b.node.sortOrder) == NaN) {
                    return 0;
                }

                const result = Math.sign(a.node.sortOrder - b.node.sortOrder);
                return result;
            }
        ))
    }


    @action.bound
    pruneChildren(freshChildren) {
        for (let i = this.children.length - 1; i >= 0; i--) {
            const currentChild = this.children[i];

            if (currentChild == null) {
                continue;
            }

            const childInFreshWithCurrentChildId = freshChildren.find(({ n }) => n.id === currentChild.node.id);

            if (childInFreshWithCurrentChildId == null) {
                const deletedChildren = this.children.splice(i, 1);
                for (const deletedChild of deletedChildren) {
                    deletedChild.onDispose();
                }
            }
        }
    }

    @action.bound
    assignNodeData(freshNodeData) {


        // TODO this could cause entire sport menu to rerender
        // Maybe copy in only those fields that have changed?? and Object.assign object properties
        this.node = freshNodeData;
    }

    //#endregion assign data



    //#region disposer

    @action.bound
    onDispose() {

        for (const child in this.children) {
            child.onDispose();
        }

        this.parent = null;
        this.node = {};
        this.children = [];
    }

    //#endregion disposer
}
export class CheckBoxMenuNode extends BaseNode {


    //#region  observable

    @observable checked = false;
    @observable isExpanded = false;

    //#endregion observable



    //#region  constructor

    constructor(parent) {
        super(parent);
    }

    //#endregion constructor



    //#region computed

    @computed get checkedLeafNodeIds() {

        if (this.isLeaf) {
            if (this.checkState > 0) {
                return [this.node.id];
            } else {
                return [];
            }
        }

        return this.children.reduce((acc, child) => {
            acc.push(...child.checkedLeafNodeIds);
            return acc;
        }, [])
    }

    /**
     * 0 - node is leaf and is not checked, node is not leaf and some or all of its children are checked (not checked)
     * 1 - node is leaf and is checked, node is not leaf and all of its children are checked (checked)
     * 2 - node is not leaf and some of its children are checked (indeterminate state)
     * @returns {0|1|2}
     */
    @computed get checkState() {
        if (this._initialChecked) {
            return this._initialChecked ? 1 : 0;
        }

        if (this.isLeaf) {
            return this.checked ? 1 : 0;
        }

        if (this.isEveryChildChecked) {
            return 1;
        }

        if (this.isSomeChildChecked) {
            return 2;
        }

        return 0;
    }

    /**
     * @returns {boolean} True if every child node is checked
     */
    @computed get isEveryChildChecked() {
        return this.children.every(c => c.checkState === 1);
    }

    /**
     * @returns {boolean} True if atleast one child node is checked
     */
    @computed get isSomeChildChecked() {
        return this.children.some(c => c.checkState > 0);
    }

    //#endregion computed



    //#region node actions

    /**
     * Set check state
     * @param {boolean} value
     */
    @action.bound
    onCheck(value) {

        // node checked, we are not toggling
        if (this.isLeaf) {
            this.checked = value;
            return;
        }

        this.children.forEach(child => {
            child.onCheck(value);
        });

        if (value) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    @action.bound
    onToggleCheck() {
        if (
            this.checkState === 0 ||
            this.checkState === 2
        ) {
            this.onCheck(true);
        } else {
            this.onCheck(false);
        }

    }

    @action.bound
    toggleExpanded() {
        if (!this.isLeaf || this.parent == null) {
            this.isExpanded = !this.isExpanded;
        }
    }

    @action.bound
    expand() {
        if (
            (!this.isLeaf && !this.isExpanded) ||
            this.parent == null
        ) {
            this.isExpanded = true;
        }
    }

    @action.bound
    collapse() {
        if (
            (!this.isLeaf && this.isExpanded) ||
            this.parent == null
        ) {
            this.isExpanded = false;
        }
    }

    //#endregion node actions

}

export class CheckBoxMenuNodeWithShadow extends CheckBoxMenuNode {

    @observable shadowChecked = false;



    //#region shadow check state computed

    @computed get shadowCheckedLeafNodeIds() {

        if (this.isLeaf) {
            if (this.shadowCheckState > 0) {
                return [this.node.id];
            } else {
                return [];
            }
        }

        return this.children.reduce((acc, child) => {
            acc.push(...child.shadowCheckedLeafNodeIds);
            return acc;
        }, [])
    }

    /**
     * 0 - node is leaf and is not checked, node is not leaf and some or all of its children are checked (not checked)
     * 1 - node is leaf and is checked, node is not leaf and all of its children are checked (checked)
     * 2 - node is not leaf and some of its children are checked (indeterminate state)
     * @returns {0|1|2}
     */
    @computed get shadowCheckState() {

        if (this.isLeaf) {
            return this.shadowChecked ? 1 : 0;
        }

        if (this.isEveryChildShadowChecked) {
            return 1;
        }

        if (this.isSomeChildShadowChecked) {
            return 2;
        }

        return 0;
    }

    /**
     * @returns {boolean} True if every child node is checked
     */
    @computed get isEveryChildShadowChecked() {
        return this.children.every(c => c.shadowCheckState === 1);
    }

    /**
     * @returns {boolean} True if at least one child node is checked
     */
    @computed get isSomeChildShadowChecked() {
        return this.children.some(c => c.shadowCheckState > 0);
    }

    //#endregion shadow check state computed



    //#region actions

    /**
     * Set check state
     * @param {boolean} value
     */
    @action.bound
    onShadowCheck(value) {

        // node checked, we are not toggling
        if (this.isLeaf) {
            this.shadowChecked = value;
            this.checked = value;
            return;
        }

        for (const child of this.children) {
            child.onShadowCheck(value);
        }
    }

    @action.bound
    onShadowToggleCheck() {
        if (
            this.checkState === 0 ||
            this.checkState === 2
        ) {
            this.onShadowCheck(true);
        } else {
            this.onShadowCheck(false);
        }

    }

    //#endregion actions



    //#region sync actions 

    @action.bound
    setShadow() {
        this.shadowChecked = this.checked;
        if (!this.isLeaf) {
            for (const child of this.children) {
                child.setShadow();
            }
        }
    }

    @action.bound
    resetCheckStateOnShadow() {
        this.checked = this.shadowChecked;
        if (!this.isLeaf) {
            for (const child of this.children) {
                child.resetCheckStateOnShadow();
            }
        }
    }

    //#endregion sync actions
}

