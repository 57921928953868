import { action } from 'mobx';
import BasePopupStore from "./BasePopupStore";

class RealityCheckStore extends BasePopupStore {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;

        this.onInitialize();

        document.addEventListener('click', this.onClick);
    }

    onInitialize() {
        const currentDate = new Date();
        const realityCheckDurationLeft = JSON.parse(localStorage.getItem("reality-check-expiration"))
        const expiresIn = realityCheckDurationLeft - currentDate.getTime();
        const isRealityCheckExpired = currentDate.getTime() >= realityCheckDurationLeft;
        
        if (App.utils.isUserAuthenticated() && !isRealityCheckExpired && !this.rootStore.automaticLogoutStore.isActive) {
            this.startTimer(expiresIn);
        }
    }

    @action.bound
    startTimer(expiresIn) {
        if (!IsRealityCheckEnabled || !App.utils.isUserAuthenticated()) {
            return;
        }
        this.clearTimer();

        const shouldDisplayRealityCheck = () => {
            return App.utils.isUserAuthenticated() && !App.state.rootStore.automaticLogoutStore.isActive && !App.state.rootStore.sessionExpireStore.isActive && !App.state.rootStore.liveSessionExpireStore.isActive;
        }

        this.timerId = setTimeout(() => {
            if (shouldDisplayRealityCheck()) {
                // stop all sessions
                this.rootStore.triggerDisconnect();

                this.activatePopup();
            }
        }, expiresIn);
    }

    onClick = () => {
        const realityCheckDurationLeft = JSON.parse(localStorage.getItem("reality-check-expiration"))
        const currentDate = new Date();
        const expiresIn = realityCheckDurationLeft - currentDate.getTime();

        const isRealityCheckExpired = currentDate.getTime() >= realityCheckDurationLeft;

        if (IsRealityCheckEnabled && App.utils.isUserAuthenticated() && !this.isActive && !isRealityCheckExpired && !App.state.rootStore.sessionExpireStore.isActive && !App.state.rootStore.liveSessionExpireStore.isActive && !App.state.rootStore.automaticLogoutStore.isActive) {
            this.startTimer(expiresIn);
        }
        else {
            if (!App.utils.isUserAuthenticated()) {
                this.clearTimer();
            }
        }
    }
}

export default RealityCheckStore;