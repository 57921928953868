import { merge } from 'lodash';

function getAntiforgeryToken() {
    return document.querySelector('input[name=__RequestVerificationToken]').value;
}

class HttpClient {
    constructor() {
        this.defaultHeaders = {
            'Caller-Environment': EnvironmentKey
        }
        this._internalRequest = this._internalRequest.bind(this);
        this.request = this.request.bind(this);
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }

    /**
     *  Sends
     * @param {RequestInfo} url
     * @param {RequestInit} options
     */
    _internalRequest(url, options) {
        return fetch(url, options);
    }

    /**
     *  Sends
     * @param {RequestInfo} url
     * @param {RequestInit} options
     */


    async request(url, options) {
        if (App.state != null) {
            App.state.rootStore.userAuthStore.syncToken();
        }
        merge(options.headers, App.utils.getApplicationHeaders());

        const rawResponse = await this._internalRequest(url, options);
        if (rawResponse.redirected) {
            window.location.href = rawResponse.url;
            return;
        }

        if (rawResponse.status === 308) {
            const body = await rawResponse.json();

            window.location.href = window.location.origin + body.url;
            return;
        }

        if (rawResponse.status === 204) {
            return null;
        }

        if (rawResponse.status === 401 || rawResponse.status === 403) {

            App.utils.setCookie('authorization', '', -100);
            App.utils.setCookie('authorization_token', '', -100);
            App.utils.setCookie('reality_check', '', -100);
            App.utils.setCookie('reality_check_date', '', -100);
            App.utils.setCookie('user_data_id', '', -100);
            App.utils.setCookie('user_id', '', -100);
            App.utils.setCookie('auto_logout_time', '', -100);
            App.utils.setCookie('deposit_limit', '', -100);
            App.utils.setCookie('remember_me', '', -100);
            App.utils.setCookie('oasis_lock_enabled', '', -100);
            App.utils.setCookie('one_click_bet', '', -100);
            App.state.rootStore.userAuthStore.removeUserFromLocalStorage();
            window.location.href = `/${App.utils.getCurrentCulture()}/auth/login?isSessionTerminated=true`;
            throw new Error('Unauthorized');

        }

        if (typeof (nToastNotify) !== 'undefined') {
            const messages = nToastNotify.getMessagesFromFetchResponse(rawResponse);
            nToastNotify.showMessages(messages)
        }

        let response;
        switch (options.responseType) {
            case 'text':
                response = await rawResponse.text();
                break;
            case 'blob':
                response = await rawResponse.blob();
                break;
            case 'arrayBuffer':
                response = await rawResponse.arrayBuffer();
                break;
            case 'formData':
                response = await rawResponse.formData();
                break;
            case 'json':
                response = await rawResponse.json();
                break;
            default:
                response = rawResponse;
                break;
        }

        if (rawResponse.ok) {
            return response;
        }

        // if we got here, error happened
        // throw what we got from the response
        throw {
            statusCode: rawResponse.status,
            data: response,
            rawResponse: rawResponse
        };
    }

    get(url, headers = null, options = {}) {
        let innerHeaders = {
            ...this.defaultHeaders
        };
        if (headers) {
            merge(innerHeaders, headers);
        }

        return this.request(url, {
            ...options,
            method: 'GET',
            headers: innerHeaders
        });
    }

    post(url, data = null, headers = null, options = {}) {
        let innerHeaders = {
            ...this.defaultHeaders,
            RequestVerificationToken: getAntiforgeryToken(),
        };
        if (headers) {
            merge(
                innerHeaders,
                headers
            );
        }

        return this.request(url, {
            ...options,
            // credentials: 'same-origin',
            method: 'POST',
            body: data,
            headers: innerHeaders
        });
    }

    put(url, data = null, headers = null, options = {}) {
        let innerHeaders = {
            ...this.defaultHeaders,
            RequestVerificationToken: getAntiforgeryToken(),
        };

        if (headers) {
            merge(innerHeaders, headers);
        }

        return this.request(url, {
            ...options,
            method: 'PUT',
            body: data,
            headers: innerHeaders
        });
    }

    delete(url, headers = null, options = {}) {
        let innerHeaders = {
            ...this.defaultHeaders,
            RequestVerificationToken: getAntiforgeryToken(),
        };

        if (headers) {
            merge(innerHeaders, headers);
        }

        return this.request(url, {
            ...options,
            method: 'DELETE',
            headers: innerHeaders
        });
    }
}

const httpClient = new HttpClient();
export { httpClient };