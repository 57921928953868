import { observable, action, runInAction, computed, reaction } from 'mobx';
import { OfferStore, EventType } from '@gp/gp-javascript-utils';

class OfferPageStore extends OfferStore {
    pageSize = 10;
    @observable pageNumber = 1;
    @observable totalItems = 0;

    constructor(rootStore, options) {
        super(rootStore, options);

        this.rootStore = rootStore;
    }

    async onInitialize() {

    }

    disposeSubscription() {

    }

    /**
     * @param {number} page 
     */
    @action.bound
    async onPageChange(page) {
        if (this.pageNumber !== page) {
            this.pageNumber = page;

            this.disposeSubscription();
            await this.onInitialize();
        }
    }
}

export { OfferPageStore }