const httpClient = App.utils.httpClient;

class PrematchOfferService {


    /**
     * Fetch event additional offer
     * @param {{eventIds: string[]}} filter 
     */
    async getEventAdditionalOffer(filter) {
        const baseUrl = `${BaseProxyAddress}${App.utils.getAgencyKey()}/offer`;
        var f = {
            subscriptionCallback: 'not-relevant',
            channels: [
                {
                    name: 'event',
                    filter: {
                        id: {
                            eq: filter.eventIds
                        }
                    }
                },
                {
                    name: 'betOffer'
                }
            ],
            namberOfEvents: null,
            language: App.utils.getCurrentLanguage()
        }

        const response = await httpClient.post(baseUrl, JSON.stringify(f), {
            'Content-Type': 'application/json'
        }, {
            responseType: 'json'
        });

        return response;
    }
}

// function transformResult(rawResponse) {
//     if (rawResponse) {
//         const lookup = rawResponse.lookups;
//         if (rawResponse.events) {
//             rawResponse.events.forEach(e => {
//                 const tournament = lookup.tournaments[e.tournamentId];
//                 const category = lookup.categories[tournament.categoryId];
//                 const sport = lookup.sports[category.sportId];
//                 e.sport = sport;
//                 e.category = category;
//                 e.tournament = tournament;

//                 if (e.teamOneId) {
//                     e.teamOne = lookup.teams[e.teamOneId];
//                 }

//                 if (e.teamTwoId) {
//                     e.teamTwo = lookup.teams[e.teamTwoId];
//                 }
//             });
//         }

//         if (rawResponse.bettingOffers) {
//             rawResponse.bettingOffers.forEach(o => {
//                 if (o.playerId) {
//                     o.player = lookup.players[o.playerId];
//                 }
//                 if (o.teamId) {
//                     o.team = lookup.teams[o.teamId];
//                 }
//                 o.bettingType = lookup.bettingTypes[o.typeId];
//             });
//         }
//     }

//     return rawResponse;
// }

/**
 * @typedef {Object} Offer offer response model
 * @property {string[]} firstColumnBettingTypes
 * @property {string[]} secondColumnBettingTypes
 * @property {string[]} thirdtColumnBettingTypes
 * @property {string[]} fourthColumnBettingTypes
 * @property {string[]} fifthColumnBettingTypes
 * @property {Lookup} lookups
 * @property {Event[]} events
 * @property {BettingOffer[]} bettingOffer
 * @property {number} startingVersion
 * @property {number} version
 */

/**
 * @typedef {Object} Event
 * @property {string} id
 * @property {number} bettingStatus
 * @property {0|1|2} eventType
 * @property {bool} isTopEvent
 * @property {Date} liveChange
 * @property {number} liveStatus
 * @property {string} matchStatusId
 * @property {string} sportCategoryId
 * @property {string} sportId
 * @property {Date} startTime
 * @property {string} stopReason
 * @property {string} stateId
 * @property {string} teamOneId
 * @property {string} teamTwoId
 * @property {string} tournamentId
 * @property {Array} tvChannels
 * @property {string} venueId
 * @property {number} totalOfferCount
 * @property {bool} hasLiveCoverage
 * @property {{providerName: string, providerId: string}} source
 */

/**
 * @typedef {Object} BettingOffer
 * @property {string} id
 * @property {string} offerKeyId
 * @property {number} status
 * @property {string} typeId
 * @property {string} tip
 * @property {string} description
 * @property {string} eventId
 * @property {number} value
 * @property {Object} specifier
 * @property {string} playerId
 * @property {string} teamId
 */

/**
 * @typedef {Object} Lookup
 * @property {{[sportId: string]: Sport}} sports
 * @property {{[categoryId: string]: Category}} categories
 * @property {{[tournamentId: string]: Tournament}} tournaments
 * @property {{[teamId: string]: Team}} teams
 * @property {{[playerId: string]: Player}} teams
 * @property {{[bettingTypeId: string]: BettingType}} bettingTypes
 * @property {{[bettingTipId: string]: BettingTip}} bettingTips
 */

/**
 * @typedef {Object} Sport
 * @property {string} id
 * @property {string} abrv
 * @property {string} name
 * @property {number} sortOrder
 */

/**
 * @typedef {Object} Category
 * @property {string} id
 * @property {string} abrv
 * @property {string} name
 * @property {string} sportId
 * @property {Sport} sport
 * @property {number} sortOrder
 */

/**
 * @typedef {Object} Tournament
 * @property {string} id
 * @property {string} abrv
 * @property {string} name
 * @property {string} categoryId
 * @property {number} sortOrder
 */

/**
 * @typedef {Object} Team
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} Player
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} BettingType
 * @property {string} id
 * @property {string} abrv
 * @property {string} name
 * @property {string} displayName
 * @property {string} nameForBettingSlip
 * @property {string} nameForOfferList
 * @property {number} sortOrder
 * @property {number} sortOrderCashRegister
 * @property {string[]} tips
 */

/**
 * @typedef {Object} BettingTip
 * @property {string} id
 * @property {string} abrv
 * @property {string} name
 * @property {string} format
 * @property {number} sortOrder
 */

export default PrematchOfferService;