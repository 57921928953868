import { action, runInAction } from "mobx";
import BasePopupStore from "./BasePopupStore";

class AutomaticLogoutStore extends BasePopupStore {
	constructor(rootStore) {
		super();
		this.rootStore = rootStore;

		this.onInitialize();

		document.addEventListener("click", this.onClick);
	}

	onInitialize() {
		const currentDate = new Date();
		const autoLogoutDurationLeft = JSON.parse(
			localStorage.getItem("auto-logout-period")
		);
		const automaticLogoutExpiresIn =
			autoLogoutDurationLeft - currentDate.getTime();

		if (App.utils.isUserAuthenticated() && autoLogoutDurationLeft != null) {
			this.startTimer(automaticLogoutExpiresIn);
		}
	}

	@action.bound
	startTimer(expiresIn) {
		const user = JSON.parse(localStorage.getItem("user"));
		if (
			!App.utils.isUserAuthenticated() ||
			user?.autoLogoutPeriod == null
		) {
			return;
		}
		this.clearTimer();

		this.timerId = setTimeout(async () => {
			const isPrivateRouteActive =
				location.pathname.includes("account-settings") ||
				location.pathname.includes("account-statement") ||
				location.pathname.includes("my-bets");

			const deactivateRestOfThePopups = () => {
				App.state.rootStore.liveSessionExpireStore.deactivatePopup();
				App.state.rootStore.sessionExpireStore.deactivatePopup();
				App.state.rootStore.realityCheckStore.deactivatePopup();
			};

			if (App.utils.isUserAuthenticated()) {
				runInAction(() => {
					// stop all sessions
					this.rootStore.triggerDisconnect();

					if (isPrivateRouteActive) {
						deactivateRestOfThePopups();
						this.deactivatePopup();
					} else {
						deactivateRestOfThePopups();
						this.activatePopup();
					}
				});

				if (isPrivateRouteActive) {
					const url = window.location.pathname;
					const returnUrl = url.substring(3);
					await App.state.rootStore.userAuthStore.logoutUser({
						isAutomaticLogout: true,
					});
					App.state.redirect(
						`/${App.utils.getCurrentCulture()}/auth/login?isAutomaticLogout=true&returnUrl=${returnUrl}`
					);
				} else {
					await App.state.rootStore.userAuthStore.logoutUser({
						isAutomaticLogout: true,
					});
					// on logout we need to resolve connection but in this special case while automatic logout popup is opened
					// we need to terminate hub connection until user closes the automatic logout popup
					this.rootStore.triggerDisconnect();
				}
			}
		}, expiresIn);
	}

	onClick = () => {
		const autoLogoutDurationLeft = JSON.parse(
			localStorage.getItem("auto-logout-period")
		);
		const currentDate = new Date();
		const automaticLogoutExpiresIn =
			autoLogoutDurationLeft - currentDate.getTime();

		if (App.utils.isUserAuthenticated() && autoLogoutDurationLeft != null) {
			this.startTimer(automaticLogoutExpiresIn);
		} else {
			if (!App.utils.isUserAuthenticated()) {
				this.clearTimer();
			}
		}
	};
}

export default AutomaticLogoutStore;
