const httpClient = App.utils.httpClient;
import * as qs from "qs";

const requestHeaders = {
	"Content-Type": "application/json",
};

export class UserDataService {
	getUserInfo() {
		const baseUrl = `${BaseAddress}platform/agency-users`;
		const user = JSON.parse(localStorage.getItem("user"));
		const userId = user.id;

		return httpClient.get(
			`${baseUrl}/${App.utils.getAgencyId()}/${userId}`,
			requestHeaders,
			{ responseType: "json" }
		);
	}

	loginUser(userData) {
		const requestHeadersCustom = {
			"Content-Type": "application/x-www-form-urlencoded",
		};

		const url = "/api/user/login";

		return httpClient.post(
			url,
			qs.stringify(userData),
			requestHeadersCustom,
			{ responseType: "json" }
		);
	}

	ssoLogin(token) {
		const url = "/api/user/sso";

		return httpClient.get(`${url}?token=${token}`, requestHeaders, {
			responseType: "json",
		});
	}

	logoutUser(reasonsObject = {}) {
		const requestModel = Object.assign(
			{
				isRealityCheckLogout: false,
				isAutomaticLogout: false,
			},
			reasonsObject
		);

		requestModel.token = App.utils.getUserToken().token;
		requestModel.agencyId = App.utils.getAgencyId();
		requestModel.agencyKey = App.utils.getAgencyKey();
		requestModel.shopId = App.utils.getShopId();

		const url = "/api/user/logout";

		return httpClient.post(
			url,
			JSON.stringify(requestModel),
			requestHeaders
		);
	}

	promoMaterialsApproval(isAccepted) {
		const user = JSON.parse(localStorage.getItem("user"));
		const userId = user.id;

		const baseUrl = `${BaseAddress}platform/agency-users`;

		return httpClient.put(
			`${baseUrl}/promo-material-update/${userId}/${isAccepted}`,
			null,
			requestHeaders
		);
	}

	validatePlayer() {
		const user = JSON.parse(localStorage.getItem("user"));

		const userId = user.id;

		const url = `${BaseAddress}/platform/oasis-blocks/validate-player`;

		const body = JSON.stringify({
			AgencyIdentifier: App.utils.getAgencyKey(),
			ShopId: App.utils.getShopId(),
			UserId: userId,
		});

		return httpClient.post(url, body, requestHeaders, {
			responseType: "json",
		});
	}

	//move this to special service

	findOffers(filter) {
		const url = `${BaseProxyAddress}${App.utils.getAgencyKey()}/offer`;

		return httpClient.post(url, JSON.stringify(filter), requestHeaders, {
			responseType: "json",
		});
	}

	forgotUsername(model) {
		const baseUrl = `${BaseAddress}platform/agency-users`;

		return httpClient.post(
			`${baseUrl}/recover-username`,
			JSON.stringify(model),
			requestHeaders
		);
	}

	// move to special service
	requestPasswordRecovery(model) {
		const url = `${BaseAddress}platform/gp-recover-password`;

		return httpClient.post(url, JSON.stringify(model), requestHeaders, {
			responseType: "json",
		});
	}

	checkPasswordRecoveryToken(passwordToken, username) {
		const baseUrl = `${BaseAddress}platform/agency-users`;

		return httpClient.get(
			`${baseUrl}/verify-recover-password-token?passwordToken=${passwordToken}&username=${username}`,
			requestHeaders,
			{ responseType: "json" }
		);
	}

	recoverPassword(model) {
		const url = `${BaseAddress}platform/gp-recover-password`;

		return httpClient.put(url, JSON.stringify(model), requestHeaders);
	}

	setOfflineUserPasswordAndEmail(model) {
		const url = "/api/user/account-activation";

		return httpClient.post(url, JSON.stringify(model), requestHeaders, {
			responseType: "json",
		});
	}

	getCardUserInfoAsync() {
		const url = `${BaseAddress}platform/agency-users/offline-user-data/${App.utils.getAgencyId()}/${App.utils.getAccountActivationToken()}`;

		return httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	getForcePasswordChangeCardUserInfoAsync() {
		const url = `${BaseAddress}platform/agency-users/offline-user-data/password/${App.utils.getAgencyId()}/${App.utils.getAccountActivationToken()}`;

		return httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	setForcePasswordChangeForOfflineUserAsync(model) {
		const url = "/api/user/force-password-change";

		return httpClient.post(url, JSON.stringify(model), requestHeaders, {
			responseType: "json",
		});
	}

	getLimitedCompanySettings() {
		const url = `${BaseAddress}platform/company-settings/limited-by-agency/${App.utils.getAgencyKey()}`;

		return httpClient.get(url, requestHeaders, {
			responseType: "json",
		});
	}

	acceptRegulation() {
		const url = `${BaseAddress}${App.utils.getAgencyKey()}/regulation-consent-histories/accept-regulation`;

		return httpClient.post(url, null, requestHeaders);
	}

	// TODO: old method, deprecated use getUserAccounts instead
	fetchUserAccounts() {
		const url = `/api/user/fetch-user-accounts/${
			App.utils.getUserFromLocalStorage().id
		}`;

		return httpClient.get(url, requestHeaders, { responseType: "json" });
	}

	// TODO: this should be simple http request, do mapping elsewhere
	async getUserAccounts() {
		const userBalance = await httpClient.get(
			`${BaseAddress}${App.utils.getAgencyKey()}/user-accounts/users/${
				App.utils.getUserFromLocalStorage().id
			}?embed=accountType,currency`,
			requestHeaders,
			{ responseType: "json" }
		);

		if (userBalance && userBalance.item) {
			const allowed = userBalance.item.filter((ub) =>
				AllowedAccounts.includes(ub.accountType.abrv)
			);
			const mapped = allowed.map((a) => {
				return {
					id: a.id,
					accountTypeId: a.accountType.id,
					name: a.accountType.name,
					abrv: a.accountType.abrv,
					balance: a.balance,
					currency: a.currency,
				};
			});
			return mapped;
		}
		return null;
	}

	creat24hLock() {
		const url = `${BaseAddress}${App.utils.getAgencyKey()}/tw-gp-users/oasis-temporary-block`;

		return httpClient.put(url, null, requestHeaders, {
			responseType: "json",
		});
	}
}
