import { observable, action, runInAction, computed, when } from 'mobx';

import moment from 'moment';

import { OfferMapper, PrematchOfferStore } from '@gp/offer';
import { Sorter } from '@gp/utility';
import { LiveStatus, EventType } from '@gp/models';

import { PrematchOfferService } from '../../../../services/components';

import AdditionalOfferStore from '../../../AdditionalOfferStore';
import { LoaderStore } from '../../../../../state/stores/common';

const logger = App.state.logger;

const DefaultConfig = {
    columns: ["1", "2"]
};

class LastMinuteViewStore extends PrematchOfferStore {

    config = DefaultConfig;
    columns = null;

    //#region observable

    @observable isExpanded = true;
    @observable isFetchingData = false;
    @observable isStoreInitialized = false;

    //#endregion observable



    //#region  computed

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get isEmpty() {
        return this.eventsMap.size === 0 && !this.isFetchingData && this.isStoreInitialized;
    }

    @computed get eventsList() {
        return this.events
            .map(e => {
                e.sport = this.getMappedSport(e);
                return e;
            })
            .sort(Sorter.sort(
                'startTime',
                'id',
            ))
            .slice(0, 15);
    }

    //#endregion computed



    //#region constructor

    /**
     * @param {*} rootStore 
     * @param {{columns: string[]}} config number of columns to fetch
     */
    constructor(rootStore, config = null) {
        super(rootStore, {
            logger: logger
        });

        this.rootStore = rootStore;

        this.service = new PrematchOfferService();

        this.loader = new LoaderStore();
        this.loader.isLoading = true;
        this.loader.suspend();
        this.additionalOfferStore = new AdditionalOfferStore(rootStore);

        if (config != null) {
            Object.assign(this.config, config);
        }
    }

    //#endregion constructor



    //#region fetching data

    @action.bound
    async onLastMinuteOfferInitialize(filter) {

        this.disposeWhenHubStarted();
        this.wehHubStartedDisposer = when(
            () => this.rootStore.hub.isStarted,
            () => this.fetchData(filter),
        );
    }

    @action.bound
    async fetchData(filter) {

        this.loader.suspend();
        this.isFetchingData = true;

        const requestObj = this.generateOfferRequestObj(filter);

        try {
            const offer = await this.rootStore.hub.getOffer(requestObj);

            runInAction(() => {
                this.assignOfferData(offer);
            });
        } catch (e) {
            console.error(e);
        } finally {
            this.onDoneFetching();
        }
    }

    //#region request object

    generateOfferRequestObj(additionalFilterParams) {

        const filter = this.generateFilter(additionalFilterParams);

        return {
            paging: {
                pageNumber: 1,
                pageSize: 15
            },
            filter,
        }
    }

    generateFilter(additionalFilterParams) {
        let timeIntervalFilter = {
            startTime: {
                gt: moment.utc().toDate(),
                lt: moment.utc().add(4, 'hours').toDate()
            }
        }

        const prematchOffersFilter = this.generateDefaultPrematchOfferFilters();

        const filter = {
            ...timeIntervalFilter,
            eventType: EventType.NORMAL,
            liveStatus: LiveStatus.PREMATCH,
            offers: prematchOffersFilter
        }

        if (additionalFilterParams?.excludeEventIds != null) {

            filter.id = {
                '!eq': additionalFilterParams.excludeEventIds
            }

        }

        return filter;
    }

    generateDefaultPrematchOfferFilters() {

        const bettingTypeConfiguration = OfferMapper.getSportBettingTypesByCategory('lastMinute');
        return [
            {
                bettingType: {
                    abrv: {
                        eq: bettingTypeConfiguration.filter.normal
                    }
                },
            },
            {
                bettingType: {
                    abrv: {
                        eq: bettingTypeConfiguration.filter.marginal
                    }
                },
                isFavorite: true
            },
        ];
    }

    //#endregion request object


    @action.bound
    onDoneFetching() {

        this.isStoreInitialized = true;
        this.isFetchingData = false;
        this.loader.resume();
    }

    //#endregion fetching data



    //#region  disposers

    @action.bound
    onDispose() {
        this.disposeWhenHubStarted();

        this.isExpanded = true;

        this.isStoreInitialized = false;
        this.isFetchingData = false;
        this.loader.suspend();

        this.additionalOfferStore.onDispose();

        this.reset();
    }

    @action.bound
    disposeWhenHubStarted() {
        if (this.wehHubStartedDisposer) {
            this.wehHubStartedDisposer()
            this.wehHubStartedDisposer = null;
        }
    }

    //#endregion disposers



    //#region actions

    onAdditionalOfferOpen(eventId) {

        const { location: { pathname } } = App.state.history;

        const url = pathname.replace('home', 'sports');

        App.state.redirect(url + '?event=' + eventId);
    }

    @action.bound
    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    @action.bound
    expand() {
        this.isExpanded = true;
    }


    @action.bound
    setEventIdsForSwitcher(eventId) {
        let list = this.eventsList.map(({ id }) => id);

        const eventIndex = list.indexOf(eventId);

        this.rootStore.eventSwitcherViewStore.setEventList(list, eventIndex);
    }

    //#endregion actions
}

export default LastMinuteViewStore;