import { observable, action } from 'mobx';
import { OddTypeStore as GpOddTypeStore } from '@gp/gp-javascript-utils';

class OddTypeStore extends GpOddTypeStore {
    @observable isOpen = false;

    constructor(config = null) {
        super(config);
    }

    @action.bound
    onOpen() {
        this.isOpen = true;
    }

    @action.bound
    onClose() {
        this.isOpen = false;
    }
}

export default OddTypeStore;