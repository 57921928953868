import { action, runInAction } from 'mobx';
import BasePopupStore from "./BasePopupStore";

class SessionExpireStore extends BasePopupStore {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;

        window.addEventListener('storage', e => {
            if (e.key === 'session-expiration-time') {
                this.startTimer(DefaultSessionDuration, true);
            }
        });

        this.onInitialize();

        document.addEventListener('click', this.onClick);
    }

    shouldStartTimer() {
        return DefaultSessionDuration != null && 
            !isNaN(DefaultSessionDuration) && 
            !this.rootStore.liveSessionExpireStore.isActive && 
            !this.rootStore.realityCheckStore.isActive && 
            !this.rootStore.automaticLogoutStore.isActive && 
            !this.rootStore.isDepositLimitUpdatePopupActive;
    }

    onInitialize() {
        if (this.shouldStartTimer()) {
            this.startTimer(DefaultSessionDuration);
        }
    }

    @action.bound
    startTimer(expiresIn, receivedMessage = false) {
        this.clearTimer();
        const currentDate = new Date();

        const sessionExpirationTime = currentDate.getTime() + (expiresIn * 1000);
        if (localStorage.getItem('session-expiration-time') == null || !receivedMessage) {
            localStorage.setItem('session-expiration-time', sessionExpirationTime);
        }

        this.timerId = setTimeout(() => {
            runInAction(async () => {
                const newCurrentDate = new Date();
                const localStorageSessionExpiryTime = localStorage.getItem('session-expiration-time');
                if (newCurrentDate.getTime() > localStorageSessionExpiryTime) {
                    // stop all sessions
                    runInAction(() => {
                        this.rootStore.triggerDisconnect();
                    });

                    if (App.utils.isUserAuthenticated() && localStorage.getItem('sln') !== 'true' && !App.state.rootStore.automaticLogoutStore.isActive) {

                        localStorage.setItem('status.loggedout', JSON.stringify(true));
                        localStorage.removeItem('status.loggedout');

                        // redirect ater session expire, don't show popup 
                        // logout user 
                        const url = window.location.pathname;
                        const returnUrl = url.substring(3);
                        await App.state.rootStore.userAuthStore.logoutUser();
                        if (!App.state.rootStore.automaticLogoutStore.isActive) {
                            App.state.rootStore.realityCheckStore.deactivatePopup();
                            App.state.history.push(`/${App.utils.getCurrentCulture()}/auth/login?isSessionExpired=true&returnUrl=${returnUrl}`)
                        }
                    }
                    else {
                        const pathname = window.location.pathname;
                        const shouldSessionExpirePopupShow = !pathname.includes('login') && 
                            !pathname.includes('betting-activity') && 
                            !pathname.includes('account-activation') && 
                            !pathname.includes('accept-new-terms') &&
                            !App.state.rootStore.automaticLogoutStore.isActive;

                        if (shouldSessionExpirePopupShow) {
                            this.activatePopup();
                        }
                    }
                }
            });
        }, expiresIn * 1000);
    }

    onClick = () => {
        const isLiveOfferPathnameActive = App.state.history.location.pathname.includes(`/${App.utils.getCurrentCulture()}/live`);

        if (!isLiveOfferPathnameActive && !App.state.rootStore.liveSessionExpireStore.isActive && !App.state.rootStore.realityCheckStore.isActive && !App.state.rootStore.automaticLogoutStore.isActive) {
            this.startTimer(DefaultSessionDuration);
        }
    }
}

export default SessionExpireStore;