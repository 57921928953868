import { observable, action, reaction, computed, runInAction } from 'mobx';
import { EventType } from '@gp/gp-javascript-utils';
import { Mapper } from '@gp/gp-offer-utils';

import { OfferPageStore } from '../../OfferPageStore';
import { LoaderStore } from '../../../../state/stores/common';

const logger = App.state.logger;

class LiveViewStore extends OfferPageStore {
    columns = {};

    @observable period = 'events';

    @observable isInitialize = false;
    @observable isStoreInitialized = false;

    @observable firstColumnBettingTypes = []; //bettingTypesPerColumn.firstColumn;
    @observable secondColumnBettingTypes = []; //bettingTypesPerColumn.secondColumn;
    @observable thirdColumnBettingTypes = []; //bettingTypesPerColumn.thirdColumn;
    @observable fourthColumnBettingTypes = []; //bettingTypesPerColumn.fourthColumn;

    @computed get isLive() {
        // upcoming are not live events
        return false;
    }

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get isEmpty() {
        return (!this.events || this.events.length <= 0) && this.isStoreInitialized && !this.isLoading;
    }

    /**
     * @param {RootStore} rootStore 
     */
    constructor(rootStore) {
        super(rootStore, {
            storageProvider: App.state.localStorageProvider,
            logger: logger,
        });

        this.pageSize = 30;

        // this.upcomingCountReactionDisposer = reaction(() => ({
        //     upcoming: this.totalItems,
        // }), count => {
        //     this.rootStore.liveSubheaderViewStore.setCount('upcomingCount', count.upcoming);
        // }, {
        //     fireImmediately: true
        // });

        this.loader = new LoaderStore();
    }

    @action.bound
    async initializeStore() {
        runInAction(() => {
            this.loader.suspend();
            this.isInitialize = true;
        })

        this._resetData();

        const bettingTypeConfiguration = Mapper.getSportBettingTypesByCategory('upcoming');
        const offerResponse = await this.hub.getOffer({
            paging: {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            },
            sorting: ['startTime'],
            filter: {
                eventType: EventType.Normal,
                liveStatus: 0,
                isUpcoming: true,
                offers: [
                    {
                        bettingType: {
                            abrv: {
                                eq: bettingTypeConfiguration.filter.normal,
                            }
                        }
                    },
                    {
                        bettingType: {
                            abrv: {
                                eq: bettingTypeConfiguration.filter.marginal
                            }
                        },
                        isFavorite: true
                    }
                ]
            }
        });

        this.columns = bettingTypeConfiguration.columns;
        this.assignPageableOfferData(offerResponse);

        runInAction(() => {
            this.loader.resume();
            this.isInitialize = false;
            this.isStoreInitialized = true;
        })
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    onDispose() {
        this.disposeSubscription();
        this.onReset();
        this.isStoreInitialized = false;
    }

    @action.bound
    onReset() {
        this.loader.resume();
        this.period = 'events';

        this._resetData();
    }

    @action.bound
    setPeriod(period) {
        if (period !== this.period) {
            this.period = period;
        }
    }

    /**
     * Changes to new page
     * @param {number} newPage 
     */
    @action.bound
    async onPageChange(newPage) {
        if (newPage > 0 && this.pageNumber !== newPage) {
            this.pageNumber = newPage;
            await this.initializeStore();
        }
    }

    /**
     * Changes page size
     * @param {number} newPageSize 
     */
    @action.bound
    async onPageSizeChange(newPageSize) {
        if (newPageSize > 0 && this.pageSize !== newPageSize) {
            this.pageSize = newPageSize;
            await this.initializeStore();
        }
    }
}

export default LiveViewStore;