export function fixBody(useFixedPosition, elementId) {
    if (useFixedPosition) {
        let windowY = document.documentElement.getBoundingClientRect().top;
        document.body.style.position = 'fixed';
        document.body.style.top = `${windowY}px`;
    }
    else {
        const el = document.getElementById(elementId);
        if (el != null) {
            el.style.pointerEvents = 'auto';
        }
        document.body.style.pointerEvents = 'none';
    }
}

export function setBodyOverflow() {
    document.body.style.overflow = 'hidden';
}

export function resetBody(useFixedPosition, shouldResetView = false, elementId) {
    if (useFixedPosition) {
        const scrollY = document.body.getBoundingClientRect().top;
        document.body.style.position = '';
        if (shouldResetView) {
            document.body.style.top = '';
        }
        window.scrollTo({top:parseInt(scrollY || '0') * -1, behavior:"instant"});
    }
    else {
        const el = document.getElementById(elementId);
        if (el != null) {
            el.style.pointerEvents = '';
        }
        document.body.style.pointerEvents = '';
    }
}

export function resetBodyOverflow() {
    document.body.style.overflow = '';
}

export function fixSpecificElementByClass(elemName) {
    let el = document.getElementsByClassName(elemName)[0];

    el.style.position = 'fixed';
    el.style.top = `${el.getBoundingClientRect().top}px`;
}

export function resetSpecificElementByClass(shouldResetView = true, elemName) {
    let el = document.getElementsByClassName(elemName)[0];

    el.style.position = '';
    if (shouldResetView) {
        el.style.top = '';
    }
    window.scrollTo(0, parseInt(el.getBoundingClientRect().top || '0') * -1);
}