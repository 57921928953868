import { observable, action, computed } from 'mobx';

class EventSwitcherViewStore {

    //#region observables

    /** Event ids to loop over. */
    @observable eventIds = [];

    @observable currentIndex = 0;
    @observable lastUsedDirection = 'right';

    //#endregion observables



    //#region computed

    @computed get isAvailable() {
        return this.eventIds.length >= 2;
    }

    //#endregion computed



    //#region actions

    @action.bound
    setEventList(newList, currentEventIndex) {
        this.eventIds = newList;
        this.currentIndex = currentEventIndex;
    }

    //#endregion actions


    //#region switcher actions

    @action.bound
    moveLeft() {
        if (this.eventIds.length <= 1) {
            return;
        }

        this.currentIndex--;

        if (this.currentIndex < 0) {
            this.currentIndex = this.eventIds.length - 1;
        }

        this.lastUsedDirection = 'left';

        App.state.history.replace(this.eventIds[this.currentIndex]);
    }

    @action.bound
    moveRight() {
        if (this.eventIds.length <= 1) {
            return;
        }

        this.currentIndex++;

        if (this.currentIndex >= this.eventIds.length) {
            this.currentIndex = 0;
        }

        this.lastUsedDirection = 'right';

        App.state.history.replace(this.eventIds[this.currentIndex]);
    }

    @action.bound
    removeEventId(eventId) {
        if (this.lastUsedDirection === 'right') {
            this.moveRight();
        } else if (this.lastUsedDirection === 'left') {
            this.moveLeft();
        } else {
            // Oops this should not happen
        }

        const currentEventId = this.eventIds[this.currentIndex];
        this.eventIds.splice(this.eventIds.indexOf(eventId), 1);

        this.currentIndex = this.eventIds.indexOf(currentEventId);
    }

    //#endregion switcher actions



    //#region  disposers

    @action.bound
    onDispose() {
        this.eventIds = [];
        this.currentIndex = 0;
    }
    
    //#endregion disposers
}

export default EventSwitcherViewStore;