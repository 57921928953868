import { observable, action, runInAction } from 'mobx';
import { StatisticsService } from '../../../services/components'

const logger = App.state.logger;

class StatisticsStore {
    constructor() {
        this.statisticsService = new StatisticsService();
    }

    @observable isStatisticsOpen = false;
    @observable isLoading = true;
    @observable isProcessing = false;
    @observable isSlim = false;

    @observable currentTournamentId = null;
    @observable currentEvent = null;
    @observable currentTab = 1;

    @observable standingStatisticsResult = null;
    @observable formStatisticsResult = null;
    @observable hthStatisticsResult = null;

    isNewSeason = false;

    @action.bound
    openStatisticsModal(event, isSlim, tournamentName) {
        this.isStatisticsOpen = true;
        this.currentEvent = event;
        this.isSlim = isSlim;
    }

    @action.bound
    setCurrentEvent(event) {
        this.isStatisticsOpen = true;
        this.currentEvent = event;
        this.setCurrentTournamentId(this.currentEvent.tournamentId);
    }

    @action.bound
    setCurrentTournamentId(id) {
        this.currentTournamentId = id;
    }

    @action.bound
    closeStatisticsModal() {
        this.isStatisticsOpen = false;
    }

    @action.bound
    onDispose() {
        this.currentTournamentId = null;
        this.currentEvent = null;
        this.currentTab = 1;

        this.standingStatisticsResult = null;
        this.formStatisticsResult = null;
        this.hthStatisticsResult = null;

        this.isLoading = true;
        this.isStatisticsOpen = false;
    }

    @action.bound
    async onInitialize() {
        await this.openLeaguesStatistics();
    }

    @action.bound
    async openLeaguesStatistics() {

        if (this.isProcessing) {
            return;
        }

        if (this.standingStatisticsResult) {
            this.currentTab = 1;
            return;
        }
        this.isProcessing = true;
        this.currentTab = 1;
        this.currentGroups = {};

        const result = await this.statisticsService.getStandings(this.currentTournamentId, this.currentEvent != null ? this.currentEvent.tournamentGroupId : null);
        runInAction(() => {
            if (result && result.item != null && result.item.length > 0) {
                this.standingStatisticsResult = result.item;
                result.item.forEach(i => {
                    if (i.positionTotal == 0) this.isNewSeason = true;
                    if (this.currentGroups[i.tournamentGroupId] == null) {
                        this.currentGroups[i.tournamentGroupId] = {
                            name: i.tournamentGroup.name,
                            teams: [i]
                        }
                    } else {
                        this.currentGroups[i.tournamentGroupId].teams.push(i);
                    }
                })

            }
            this.isLoading = false;
            this.isProcessing = false;
        })
    }

    @action.bound
    async openFormStatistics() {

        if (this.isProcessing) {
            return;
        }

        if (this.formStatisticsResult) {
            this.currentTab = 2;
            return;
        }

        this.isLoading = true;
        this.isProcessing = true;
        this.currentTab = 2;
        const result = await this.statisticsService.getForms(this.currentTournamentId, this.currentEvent != null ? this.currentEvent.tournamentGroupId : null);
        runInAction(() => {
            if (result) this.formStatisticsResult = result.item;
            this.isLoading = false;
            this.isProcessing = false;
        })
    }

    @action.bound
    async openHeadToHeadStatistics() {
        if (this.isProcessing) {
            return;
        }

        if (this.hthStatisticsResult) {
            this.currentTab = 3;
            return;
        }

        this.isLoading = true;
        this.isProcessing = true;
        this.currentTab = 3;

        const result = await this.statisticsService.headToHeadStats({ "teamOneId": this.currentEvent.teamOneId, "teamTwoId": this.currentEvent.teamTwoId });

        runInAction(() => {
            if (result) this.hthStatisticsResult = result.item;

            this.isLoading = false;
            this.isProcessing = false;
        })
    }
}

export default StatisticsStore;