import { observable, computed, action, reaction, runInAction } from 'mobx';

import { LiveStatus } from '@gp/models';

import { BaseOfferMenuStore } from './index.js'
import { ExpandableCheckBoxNodeStore as NodeStore } from './node';
import { LivePeriodSubMenuStore } from './period-sub-menu';
import { PlayedLiveEventsStore, FavoriteEventsCounterStore } from './counters';

const logger = App.state.logger;

export class LiveOfferMenuStore extends BaseOfferMenuStore {


    /** Used to remember what was selected in the menu. */
    _rememberSelectionSegmentsString = null;
    _rememberSelectedSport = null;

    liveMenuSubscriptionFilter = null;
    @computed get favoriteEventIds() {
        return this.favoriteCounterStore.eventIds;
    };
    @observable selectedSport = null;

    //#region counts

    @observable liveEventsCount = 0;
    @observable upcomingEventsCount = 0;
    @observable totalHighlightsCount = 0;
    @computed get liveFavoritesCount() {
        return this.favoriteCounterStore.count;
    };

    //#endregion counts



    //#region  observable

    @observable menu = new NodeStore();

    //#endregion observable



    //#region  constructor

    constructor() {
        super(...arguments);

        this.periodSubMenu = new LivePeriodSubMenuStore(this);

        this.liveEventsFromMyBets = new PlayedLiveEventsStore(this.rootStore);
        this.favoriteCounterStore = new FavoriteEventsCounterStore(this.rootStore);
    }

    @action.bound
    onBeforeInitialize() {

        // Offer menu should be closed by default on live page
        this.closeOfferMenu();
    }

    //#endregion constructor



    //#region sport menu

    @computed get isOfferMenuOpen() {
        return this.rootStore.isSideMenuExpanded;
    };

    @computed get isExpandDisabled() {
        return (
            this.periodSubMenu.selectedPeriodKey === 'my-favorites' ||
            this.menu.children.length <= 0
        );
    }

    @action.bound
    toggleOpenOfferMenu() {
        if (this.isOfferMenuOpen) {
            this.closeOfferMenu();
        } else {
            this.openOfferMenu();
        }
    }

    @action.bound
    openOfferMenu() {

        // if (this.selectedSport === null) {
        //     this.selectedSport = this.menu?.children?.[0];
        // }

        this.rootStore.openSideMenu();
    }

    @action.bound
    closeOfferMenu() {
        if (App.state.history.action === 'POP') {
            this.selectedSport = this._rememberSelectedSport;
        } else {
            this.selectedSport = null;
        }
        this.rootStore.closeSideMenu();
    }

    /** Store current menu selection in state so we can reset to it on history back. */
    @action.bound
    rememberSelection() {
        // This may be called multiple times, we only want to do it once when using event switcher
        if (this._rememberSelectionSegmentsString == null && App.state.history.action === 'PUSH') {
            this._rememberSelectionSegmentsString = this.menu.urlStructure;
            this._rememberSelectedSport = this.selectedSport;
        }
    }

    //#endregion sport menu



    //#region  fetch data
    //###################

    @action.bound
    fetchData() {

        this.disposeFetchData();
        this.liveEventsFromMyBets.onInitialize();

        this.createMenuCountSubscription();
        this.fetchDataDisposer = reaction(
            () => ({ period: this.periodSubMenu.selectedPeriodKey }),
            () => {
                this.createMenuSubscription();
            },
            {
                fireImmediately: true,
            }
        )
    }



    //#region live menu subscription 

    @action.bound
    createMenuSubscription() {

        const subscriptionRequest = this.generateSubscriptionRequestForMenuSubscription();
        if (JSON.stringify(subscriptionRequest) === JSON.stringify(this.liveMenuSubscriptionFilter) && this.liveMenuSubscription != null) {
            // If filter did not change and subscription exists, don't dispose menu subscription to create a new one
            return;
        }

        this.disposeLiveMenuSubscription();

        this.liveMenuSubscriptionFilter = subscriptionRequest;


        logger.logTrace('Fetch live menu', subscriptionRequest);

        this.liveMenuSubscription = this.hub.getMenuSubscription(subscriptionRequest)
            .subscribe(response => {
                this.assignMenu(response.menu);
                this.selectFirstSportOnFirstResponse();
                this.onDoneFetching();
            }, err => {
                console.error(err);
                this.onDoneFetching();
            });
    }

    generateSubscriptionRequestForMenuSubscription() {

        const filter = {};
        if (['events', 'my-favorites', 'played-events'].includes(this.periodSubMenu.selectedPeriodKey)) {
            filter.liveStatus = LiveStatus.LIVE;
        }
        else if (this.periodSubMenu.selectedPeriodKey === 'upcoming') {
            filter.liveStatus = LiveStatus.PREMATCH;
            filter.isUpcoming = true;
        }
        else if (this.periodSubMenu.selectedPeriodKey === 'highlights') {
            filter.liveStatus = LiveStatus.LIVE;
            filter.isTopEvent = true;
        }

        return {
            subscriptionId: 'live-menu',
            filter: filter
        };
    }

    //#endregion live menu subscription


    //#region live menu counts subscriptions 

    @action.bound
    createMenuCountSubscription() {

        this.disposeMenuCountSubscription();

        logger.logTrace('Initialize menu count subscription');

        const menuCountSubscription = {
            subscriptionId: 'live-menu-counter',
            count: {
                live: {
                    liveStatus: 1,
                },
                upcoming: {
                    liveStatus: 0,
                    isUpcoming: true,
                },
                highlights: {
                    liveStatus: 1,
                    isTopEvent: 1,
                },
            }
        };

        this.menuCountSubscription = this.hub.getCounts(menuCountSubscription)
            .subscribe(response => {
                if (!isNaN(response.live)) {
                    this.liveEventsCount = response.live;
                }
                if (!isNaN(response.upcoming)) {
                    this.upcomingEventsCount = response.upcoming;
                }
                if (!isNaN(response.highlights)) {
                    this.totalHighlightsCount = response.highlights;
                }
            }, err => {
                console.error(err);
                this.onDoneFetching();
            });
    }

    @action.bound
    _initFavoritesSubscription(eventIds = []) {
        // Counter store should get favorites store and react to it instead of live view store reacting to it, TODO
        this.favoriteCounterStore.updateEventIds(eventIds);
    }

    //#endregion live menu counts subscriptions

    @action.bound
    selectFirstSportOnFirstResponse() {
        // Do nothing for desktop theme
    }

    //#endregion fetch data
    //#####################



    //#region update params

    @action.bound
    async setRouterMatchObj(routerMatch) {

        const {
            params,
            path,
        } = routerMatch;

        // On history back reset current menu to what was remember;
        if (App.state.history.action === 'POP') {
            this.resetMenuOnUrlParamsFromRouter(this._rememberSelectionSegmentsString);
            this.selectedSport = this._rememberSelectedSport;
            this._rememberSelectionSegmentsString = null;
        }

        this.setParams(params);
    }

    /** 
     * @param {object} params params from react router
     * @param {boolean} shouldResetMenuOnUrl if true reset menu check state to url segments
     */
    @action.bound
    setParams(params, shouldResetMenuOnUrl) {

        const {
            period,
        } = params;



        if (shouldResetMenuOnUrl) {
            this.resetMenuOnUrlParamsFromRouter(params);
        }

        this.periodSubMenu.setPeriodKey(period);
    }

    //#endregion update params



    //#region sideMenu actions overrides

    /** 
     * @param {NodeStore} node
     * Toggles is sport expanded in side menu. 
     */
    @action.bound
    toggleSport(node) {

        if (this.periodSubMenu.selectedPeriodKey === 'events' || this.periodSubMenu.selectedPeriodKey === 'played-events' || this.periodSubMenu.selectedPeriodKey === 'my-favorites') {
            if (this.selectedSport?.node?.id !== node.node.id) {
                this.menu.onCheck(0);
                this.selectSport(node);
                return;
            }

            if (this.isOfferMenuOpen) {
                this.rootStore.closeSideMenu();
            } else {
                this.rootStore.openSideMenu();
            }

            return;
        }

        if (node?.node?.id === this.selectedSport?.node?.id) {
            this.toggleOpenOfferMenu();
        } else {
            this.selectSport(node);
            this.openOfferMenu();
        }
    }

    /**
     * @param {NodeStore} node which is expanded in menu
     */
    @action.bound
    setSelectedSport(node) {
        this.selectedSport = node;
    }


    /** Adds sport to side menu and expands side menu. */
    @action.bound
    selectSport(node) {

        this.selectedSport = node;

        if (['my-favorites', 'played-events', 'events'].includes(this.periodSubMenu.selectedPeriodKey)) {
            this.selectedSport.onCheck(1);
            this.rootStore.closeSideMenu();
            App.state.redirect('events');
        } else {
            this.openOfferMenu();
        }
    }

    /** Removes sport from side menu and closes side menu. */
    @action.bound
    deselectSport(node) {
        this.selectedSport = null;
        this.closeOfferMenu();
    }

    //#endregion sideMenu actions overrides



    //#region disposers

    @action.bound
    onDispose() {
        this.onBaseDispose();
        this.liveEventsFromMyBets.onDispose();

        this.favoriteCounterStore.onDispose();

        this.disposeMenuCountSubscription();

        this.disposeLiveMenuSubscription();
        this.disposeFetchData();
        this.resetObservables();
    }

    @action.bound
    resetObservables() {
        this.periodSubMenu.selectedPeriodKey = 'events';
        this.liveEventsCount = 0;
        this.totalHighlightsCount = 0;
        this.highlightFavoritesCount = 0;
        this.upcomingEventsCount = 0;
        this.isStoreInitialized = false;

        //this.selectedSport = null;

        this.liveMenuSubscriptionFilter = null;
    }

    @action.bound
    disposeLiveMenuSubscription() {
        if (this.liveMenuSubscription != null) {
            this.liveMenuSubscription.unsubscribe();
            this.liveMenuSubscription = null;
        }
    }

    @action.bound
    disposeFetchData() {
        if (this.fetchDataDisposer != null) {
            this.fetchDataDisposer();
            this.fetchDataDisposer = null;
        }
    }

    @action.bound
    disposeMenuCountSubscription() {
        if (this.menuCountSubscription != null) {
            this.menuCountSubscription.unsubscribe();
            this.menuCountSubscription = null;
        }
    }

    @action.bound
    disposeMenu() {
        this.menu.onDispose();
        this.menu = new CheckBoxMenuNode();
    }

    //#endregion disposers



    //#region played live events

    @computed get isPlayedLiveEventsCountEnabled() {
        return this.liveEventsFromMyBets.isEnabled;
    }
    @computed get playedLiveEventsCount() {
        return this.liveEventsFromMyBets.count;
    }

    //#endregion played live events
}