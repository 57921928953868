Number.prototype.toTwoDecimals = function() {
    return Math.round(this * 1e2) / 1e2;
}

Number.prototype.formatTip = function() {
    if(this <= 0) {
        return null;
    }
    else if(this > 0 && this < 100) {
        return this.toTwoDecimals();
    }
    else {
        return Math.floor(this);
    }
}