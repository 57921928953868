import { BettingTypeSelectorsStore as GPBettingTypeSelectorsStore } from '@gp/offer';

class BettingTypeSelectorsStore extends GPBettingTypeSelectorsStore {
	constructor() {
		super(4);
	}
}


export default BettingTypeSelectorsStore;
