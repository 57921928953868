import { observable, action, runInAction } from 'mobx';

import { MainOfferStore } from '@gp/offer';
import { LiveStatus } from '@gp/models';
import { ConsoleLogger } from '@gp/utility';

const logger = App.state.logger;

class LiveEventMenuViewStore extends MainOfferStore {
    @observable isLoading = true;

    constructor(rootStore) {
        super(rootStore, {
            logger: new ConsoleLogger(false),
            removeDelay: 10,
            throttle: 4,
            enableThrottling: true,
        });

        this.rootStore = rootStore;
    }

    @action.bound
    async onLoad() {
        const subscriptionRequest = {
            subscriptionId: 'live-events-menu',
            // compress: true,
            compress: false,
            channels: [
                {
                    name: 'event',
                    filter: {
                        liveStatus: LiveStatus.LIVE
                    }
                }

            ]
        };

        logger.logTrace('Connecting to hub: live-events-menu', subscriptionRequest);

        if (this.subscription != null) {
            return;
        }

        this.subscription = this.rootStore.hub.getOfferSubscription(subscriptionRequest)
            .subscribe(response => {
                runInAction(() => {
                    this.assignOfferData(response);
                    this.isLoading = false;
                });
            }, err => {
                console.error(err);
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    @action.bound
    onDispose() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }
}

export default LiveEventMenuViewStore;