import { SUPPORTED_LANGUAGES } from "../utils";
import LookupApiService from "./LookupApiService";

/**
 * TODO: create "cached" lookup, try to get first from there
 * this gets lookup values with localization
 */
class LookupService {
    constructor() {
        this.apiService = new LookupApiService();
    }

    /**
     * @returns {Array<Lookup>} bet statuses lookup
     */
    async findBetStatuses() {
        var response = await this.apiService.findLookup('bet-statuses');
        return this.mapLookupResponse(response);
    }

    /**
     * @returns {Array<Lookup>} bet slip statuses lookup
     */
    async findBetSlipStatuses() {
        var response = await this.apiService.findLookup('bet-slip-statuses');
        return this.mapLookupResponse(response);
    }

    /**
     * @returns {Array<Lookup>} betting account types lookup
     */
    async findBettingAccountTypes() {
        var response = await this.apiService.findLookup('betting-account-types');
        return this.mapLookupResponse(response);
    }

    /**
     * @returns {Array<Lookup>} base lookup
     */
    mapLookupResponse(response) {
        if (!response) {
            return null;
        }

        let result = response.map(el => ({
            id: el.id.value,
            abrv: el.abrv,
            name: this.getLocalizedName(el)
        }));
        return result;
    }

    getLocalizedName(lookupItem) {
        const culture = App.utils.getCurrentCulture();
        const cultureKey = SUPPORTED_LANGUAGES.find(lang => lang.key == culture).keyLong;
        return culture == 'en' ? lookupItem.name : lookupItem.languageMetadata[cultureKey].name
    }
}
export default LookupService;


/**
 * @typedef {Object} Lookup base lookup model
 * @property {string} id
 * @property {string} abrv
 * @property {string} name localized name
 */
