import { autorun, observable, action } from "mobx";

class TitleStore {
    @observable tabTitle = null;

    constructor() {
        autorun(() => {
            if (this.tabTitle) {
                document.title = this.tabTitle;
            }
        })
    }

    @action.bound
    setTitle(value) {
        this.tabTitle = value;
    }
}

export default TitleStore