import { observable, computed, action, reaction, runInAction } from 'mobx';
import { SessionStorageProvider } from '@gp/utility';

const logger = App.state.logger;

class SubheaderViewStore {
    @observable liveEventsCount = 0;
    @observable upcomingCount = 0;
    @observable favoritesCount = 0;
    @observable favoriteEventIds = [];

    @observable menu = [
        {
            name: 'LIVE.LIVE_SUB_MENU.LIVE_EVENTS',
            period: 'events',
            countProp: 'displayLiveEvents'
        },
        {
            name: 'specific:LIVE.LIVE_SUB_MENU.UPCOMING_EVENTS',
            period: 'upcoming',
            countProp: 'upcomingCount'
        },
        {
            name: 'LIVE.LIVE_SUB_MENU.MY_LIVE_FAVORITES',
            period: 'my-favorites',
            countProp: 'favoritesCount'
        }
    ]

    @computed get displayLiveEvents() {
        return this.liveEventsCount;
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.hub = rootStore.hub;

        this.sessionStorage = new SessionStorageProvider();

        this.favoriteEventIds = this.sessionStorage.init('favorites', []);
    }

    @action.bound
    onInitialize() {
        if (this.initReactionDisposer == null) {
            this.initReactionDisposer = reaction(() => this.hub.isStarted, isStarted => {
                if (isStarted) {
                    this._initSubscription();
                    this._initFavoritesSubscription(this.favoriteEventIds);
                } else {
                    this.disposeAllSubscriptions();
                }
            }, {
                // Options

                fireImmediately: true,
            });
        }
    }

    @action.bound
    _initFavoritesSubscription(eventIds = []) {
        this.favoriteEventIds = eventIds;

        if (this.favoriteEventIds.length === 0) {
            this.favoritesCount = 0;

            this.disposeFavSubscription();

            return;
        }

        const favFilter = {
            subscriptionId: 'live-favorites-counter',
            count: {
                favorites: {
                    liveStatus: 1,
                    id: {
                        eq: this.favoriteEventIds
                    }
                },
            }
        }

        this.favSubscription = this.hub.getCounts(favFilter)
            .subscribe(response => {
                runInAction(() => {
                    if (!isNaN(response.favorites)) {
                        this.favoritesCount = response.favorites;
                    }
                });
            }, err => {
                console.error("favorites count error", err);
                runInAction(() => {
                    this.favoritesCount = 0;
                });
            });
    }

    @action.bound
    _initSubscription() {
        const filter = {
            subscriptionId: 'live-subheader-live-menu-counter',
            count: {
                live: {
                    liveStatus: 1
                },
                upcoming: {
                    liveStatus: 0,
                    isUpcoming: true
                }
            }
        };

        logger.logTrace("Connecting to hub: sports", filter);

        this.subscription = this.hub.getCounts(filter)
            .subscribe(response => {
                if (response) {
                    runInAction(() => {
                        if (!isNaN(response.live)) {
                            this.liveEventsCount = response.live;
                        }
                        if (!isNaN(response.upcoming)) {
                            this.upcomingCount = response.upcoming;
                        }
                    });
                }
            }, err => {
                console.error(err);
            });
    }

    @action.bound
    setCount(key, value) {
        this[key] = value;
    }


    /***********************************************************************************
    *
    * Section: Disposers
    *
    ***********************************************************************************/
    
    
   
    @action.bound
    onDispose() {
        if (this.initReactionDisposer) {
            this.initReactionDisposer();
            this.initReactionDisposer = null;
        }

        this.disposeAllSubscriptions();
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    disposeFavSubscription() {
        if (this.favSubscription) {
            this.favSubscription.unsubscribe();
            this.favSubscription = null;
        }
    }

    @action.bound
    disposeAllSubscriptions() {
        this.disposeSubscription();
        this.disposeFavSubscription();
    }

}

export default SubheaderViewStore;