/**
 * @param {number} number number to test
 * @param {number} bottom lover boundary
 * @param {number} top upper boundary
 * @returns {boolean} true for number in range including boundaries
 */
export function numberInRange(number, bottom, top){
    number = Number(number);
    if(
        number >= bottom && 
        number <= top
    ) return true;
    else return false;
}

export function parsePageNumberToValid(pageNumber) {

    pageNumber = Number(pageNumber);

    if(numberInRange(pageNumber, 1, Math.pow(2,16) - 1)) return pageNumber;
    else return 1;
}