import { observable, action, runInAction, computed } from 'mobx';
import { LoaderStore } from '../../../../../state/stores/common';
import { PromoOfferService } from '../../../../services/components';

class PromoOfferViewStore {
    @observable offer = [];

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    constructor(rootStore) {
        this.rootStore = rootStore;

        this.service = new PromoOfferService();

        this.loader = new LoaderStore();
    }

    @action.bound
    async onInitialize() {
        this.loader.suspend();
        const offerResponse = await this.service.findPromoOffer();

        runInAction(() => {
            this.offer = offerResponse;
            this.loader.resume();
        });
    }

    @action.bound
    onDispose() {
    }
}

export default PromoOfferViewStore;