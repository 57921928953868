import { observer, computed, action } from 'mobx';

import { BaseNodeStore } from './';

export class NodeStore extends BaseNodeStore {

    /**
     * Override: Menu data for nodes will remain the same, only event count changes
     */
    @action.bound
    assignNodeData(freshNodeData) {

        // Update only node count since the rest of the data does not change for the node on update
        if (this.node == null || this.node.count == null) {
            this.node = freshNodeData;
            return;
        }
        this.node.count = freshNodeData.count;
        return;
    }
}