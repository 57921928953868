const httpClient = App.utils.httpClient;

const url = '/api/statistics';

const apiHeaders = {
    'Content-Type': 'application/json',
};

class StatisticsService {
    constructor() {
    }

    async getStandings(tournamentId, tournamentGroupIds = null) {
        let url = `${BaseAddress}platform/team-tournament-standings?page=1&rpp=2000&sort=sortPositionTotal|asc&tournamentIds=${tournamentId}&isTournamentSeasonActive=true&embed=team,tournamentOutcome,tournamentGroup&translate=true`;
        if (tournamentGroupIds) {
            url += `&tournamentGroupIds=${tournamentGroupIds}`
        }
        const response = await httpClient.get(url, apiHeaders, { responseType: "json" });
        return response;
    }

    async getForms(tournamentId, tournamentGroupIds = null) {
        let url = `${BaseAddress}platform/team-tournament-forms?page=1&rpp=100&sort=sortPositionTotal|asc&tournamentIds=${tournamentId}&isTournamentSeasonActive=true&embed=team,tournamentOutcome,tournamentGroup&translate=true`;
        if (tournamentGroupIds) {
            url += `&tournamentGroupIds=${tournamentGroupIds}`
        }
        const response = await httpClient.get(url, apiHeaders, { responseType: "json" });
        return response;
    }

    async headToHeadStats(teams) {
        const url = `${BaseAddress}platform/tournament-event-results/head-to-head?page=1&rpp=100&embed=halfTimeScore&translate=true`;
        const response = await httpClient.post(url, JSON.stringify(teams), apiHeaders, { responseType: "json" });
        return response;
    }
}

export default StatisticsService;