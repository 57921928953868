import { observable, action, computed, runInAction } from 'mobx';

const logger = App.state.logger;

class OfferViewStore {
    @observable isAdditionalOfferVisible = false;

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @observable isScore = false;

    @observable tvCoverage = null;

    constructor(rootStore, event) {
        this.rootStore = rootStore;
        this.event = event;
    }

    @action.bound
    onAdditionalOfferExpand(event) {
        logger.logTrace("Setting additional offer visibility:", !this.isAdditionalOfferVisible);

        this.isAdditionalOfferVisible = !this.isAdditionalOfferVisible;

        if (!this.isAdditionalOfferVisible && this.rootStore.betSlipStore.betSlipScrollStore && this.rootStore.betSlipStore.betSlipScrollStore.isEnabled) {
            this.rootStore.betSlipStore.betSlipScrollStore.setCurrentScroll();
            if (this.rootStore.betSlipStore.betSlipScrollStore.currentScroll !== 0) {
                if (event) {
                    window.scrollTo(0, event.currentTarget.closest('tr').previousSibling.getBoundingClientRect().top + window.scrollY - 250);
                }
            }
        }
    }

    @action.bound
    toggleIsScore() {
        this.isScore = !this.isScore;
    }

    @action.bound
    async loadTvs(event) {
        if (this.tvCoverage != null) {
            return;
        }

        const result = await this.rootStore.tvInfoService.getTvInfo(event.id);

        runInAction(() => {
            this.tvCoverage = result;
        });
    }
}

export default OfferViewStore;