const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
    "Accept": 'application/json'
}

const baseUrl = `${BaseAddress}platform/reports`;

class ReportApiService {

    getMyBetsReport(startDate, endDate) {
        var filter = {
            filter: {
                accessChannels: {
                    agencies: [
                        {
                            id: App.utils.getAgencyId()
                        }
                    ]
                },
                parameters: [
                    {
                        column: 'StartDate',
                        value: startDate
                    },
                    {
                        column: 'EndDate',
                        value: endDate
                    }
                ]
            }

        }
        return httpClient.post(baseUrl + '/mybets', JSON.stringify(filter), requestHeaders, { responseType: "json" });;
    }

}

export default ReportApiService;