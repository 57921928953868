import { observable, action } from 'mobx';

class AdditionalOfferGroupViewStore {
    @observable isExpanded = true;

    @action.bound
    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}

export default AdditionalOfferGroupViewStore;