const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
};
class EventChangeTypeLookupService {

    findLookup() {
        const url = `${BaseAddress}platform/event-change-types?translate=true`
        return httpClient.get(url, requestHeaders, { responseType: 'json' })
    }
}

const eventChangeTypeLookupService = new EventChangeTypeLookupService();

export {
    eventChangeTypeLookupService
};