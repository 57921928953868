import { observable, action, computed } from 'mobx';

const storage = App.state.localStorageProvider;
const storageKey = 'bss';

class BetSlipScrollStore {
    scrollDuration = 600;
    betSlipTop = 5;

    /**
     * Determines if scroll animation is enabled or disabled
     */
    @observable isEnabled;
    @observable currentScroll = 0;
    @observable initialPosition = 0;
    @observable prevScroll = 0;
    @observable minScroll = 0;

    @observable windowHeight = 0;
    @observable windowScrollTop = 0;
    @observable centerHeight = 0;
    @observable betSlipHeight = 0;

    @computed get isDownScroll() {
        return this.windowScrollTop >= this.prevScroll;
    }

    constructor() {
        this.isEnabled = !!storage.get(storageKey);
    }

    /**
     * 
     * @param {{minScroll: number, initialPosition: number, prevScroll: number}} initialState 
     */
    @action.bound
    onInitialize() {
        const currentScrollFlagValue = storage.get(storageKey);
        if (currentScrollFlagValue !== null) {
            this.isEnabled = currentScrollFlagValue === 1;
        }
        else {
            storage.set(storageKey, this.isEnabled ? 1 : 0);
        }

        this.setupScrollEvent();
    }

    @action.bound
    onDispose() {
        this.currentScroll = 0;
        window.removeEventListener('scroll', this.setCurrentScroll);
    }

    @action.bound
    async enableDisable() {
        if (this.isEnabled) {
            this.isEnabled = false;
            storage.set(storageKey, 0);
        }
        else {
            this.isEnabled = true
            storage.set(storageKey, 1);
        }

        //this.setupScrollEvent();
    }

    @action.bound
    setupScrollEvent() {
            this.prevScroll = 0;
            this.initialPosition = parseInt(document.querySelector('#bsWrapper').style.marginTop, 10) || 0;
            this.minScroll = document.querySelector('#bsWrapper').getBoundingClientRect().top;

        window.addEventListener('scroll', this.setCurrentScroll);
    }

    @action.bound
    setCurrentScroll() {

        if (!this.isEnabled) return;
        this.windowHeight = window.innerHeight;
        this.betSlipHeight = Number(document.querySelector('#bsWrapper')?.getBoundingClientRect().height.toFixed(3)) || 0;
        this.centerHeight = document.querySelector('.l--main__content')?.getBoundingClientRect().height || 0;

        this.windowScrollTop = window.scrollY;
        this.footerHeight = document.querySelector('.footer').scrollHeight;
        this.documentHeight = document.documentElement.scrollHeight;

        if (this.documentHeight - this.windowScrollTop <= (this.betSlipHeight - (this.windowHeight - this.footerHeight - 10))) {
            return;
        }

        if ((this.betSlipHeight >= this.centerHeight && this.betSlipHeight >= this.windowHeight) || (this.betSlipHeight >= this.centerHeight && this.centerHeight <= this.windowHeight)) {
            this.prevScroll = 0;
            this.currentScroll = 0;
            return;
        }

        this.windowScrollTop = window.scrollY;

        if (this.isDownScroll) {
            const betSlipOverflow = this.prevScroll + this.betSlipHeight;

            if (betSlipOverflow >= this.centerHeight) {
                this.prevScroll = this.windowScrollTop;
                this.currentScroll = (this.centerHeight - this.betSlipHeight);
                return;
            }

            if (this.betSlipHeight >= this.windowHeight) {
                this.prevScroll = this.windowScrollTop;
                if (this.windowScrollTop <= (this.betSlipHeight / 2)) {
                    this.currentScroll = 0;
                    return;
                }
                else {
                    this.currentScroll = this.windowScrollTop - (this.betSlipHeight / 2);
                    return;
                }
            }
        }
        else {
            if (this.betSlipHeight >= this.windowHeight) {
                if (this.windowScrollTop <= this.minScroll) {
                    this.prevScroll = 0;
                    this.currentScroll = 0;
                    return;
                }
                if (this.windowScrollTop <= (this.windowHeight / 3)) {
                    this.prevScroll = this.windowScrollTop;
                    this.currentScroll = this.windowScrollTop;
                    //return;
                }
            }

            if ((this.prevScroll + this.betSlipHeight) >= this.centerHeight) {
                this.prevScroll = this.windowScrollTop;
                this.currentScroll = 0;
                //return;
            }
        }

        this.prevScroll = this.windowScrollTop;

        let initialMargin = this.initialPosition;
        if (this.windowScrollTop >= this.minScroll) {
            initialMargin = initialMargin + this.betSlipTop + this.windowScrollTop - this.minScroll;
        }

        this.currentScroll = initialMargin;
    }
}

export default BetSlipScrollStore;