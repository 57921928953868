import { action, observable, runInAction } from 'mobx';
import { BetSlipStore as BetSlipErrorCodes } from '@gp/gp-javascript-utils';
import { BetSlipBetType } from '@gp/gp-javascript-utils';

import BaseBetSlipStore from './BetSlipStore.js';
import BetSlipScrollStore from './BetSlipScrollStore';

class BetSlipStore extends BaseBetSlipStore {
    @observable showLoginPopup = false;

    constructor(rootStore) {
        super(rootStore);

        this.betSlipScrollStore = new BetSlipScrollStore();
    }

    @action.bound
    showHideErrorModal(error) {

        if (!error) {
            this.showValidationModal = false;
            return;
        }

        if (error.statusCode === BetSlipErrorCodes.Unauthenticated) {
            this.showLoginPopup();
        }
        else {
            this.showValidationModal = true;
        }
    }

    @action.bound
    toggleConfirmationModal(e) {
        e.preventDefault();

        if (App.utils.isUserAuthenticated()) {
            this.showConfirmationModal = !this.showConfirmationModal;
        }
        else {
            this.toggleLoginPopup();
        }

        if (this.showConfirmationModal) {
            App.utils.setBodyOverflow();
        }
        else {
            App.utils.resetBodyOverflow();
        }
    }

    @action.bound
    async onSubmitSlip() {
        this.loader.suspend();
        this.showConfirmationModal = false;
        if (this.betSlipState.slip.isLive) {
            this.startCountdown();
        }

        await this.betSlipState.submitSlip();

        runInAction(() => {
            if (!this.betSlipState.hasErrors) {
                this.betSlipState.reset(true, { resetSubmitDelay: false });
            }
        });

        App.utils.resetBodyOverflow();
        this.loader.resume();
    }

    @action.bound
    onResetSlip() {
        this.showSlipResetModal = true;
        App.utils.setBodyOverflow();
    }

    @action.bound
    onAcceptSlipReset() {
        this.betSlipState.reset(true, { resetSubmitDelay: false });
        this.fullErrorMessage = "";
        this.showSlipResetModal = false;
        App.utils.resetBodyOverflow();
    }

    @action.bound
    onRejectSlipReset() {
        this.showSlipResetModal = false;
        App.utils.resetBodyOverflow();
    }

    @action.bound
    changeBetSlipBetType(value) {
        if (value === BetSlipBetType.OneClickBet && this.betSlipState.isSlipEmpty) {
            this.betSlipState.onBetSlipBetTypeChange(value);
            return;
        }
        else if (value === BetSlipBetType.OneClickBet && !this.betSlipState.isSlipEmpty) {
            this.showOneClickBetClearSlipModal = true;
            App.utils.setBodyOverflow();
            return;
        }

        this.betSlipState.onBetSlipBetTypeChange(value);
    }

    @action.bound
    onBetSlipBetTypeChangeAccept() {
        this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.OneClickBet);
        this.showOneClickBetClearSlipModal = false;
        App.utils.resetBodyOverflow();
    }

    @action.bound
    onCloseOneClickBetChangeModal() {
        this.showOneClickBetClearSlipModal = false;
        App.utils.resetBodyOverflow();
    }

    @action.bound
    toggleLoginPopup() {
        this.showLoginPopup = !this.showLoginPopup;
    }
}

export default BetSlipStore;
