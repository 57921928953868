import { action, observable, computed, runInAction } from "mobx";
import { resultsService as ResultsService } from "../services";

export class ResultsDataStore {
    @observable resultData;
    @observable dayParam = 0;
    @observable isSearch = false;

    constructor(){}

    @action.bound
    async onInitialize() {
        const selectedTournaments = App.offer.rootStore.resultsMenuStore.selectedTournaments;
        const searchString = App.offer.rootStore.searchBarViewStore.searchTerm;

        this.isSearch = searchString != null && searchString !== '';

        const resultDataRequest = await ResultsService.getResults({
            previousDays: selectedTournaments.length > 0 || (searchString != null && searchString !== '') ? 7 : this.dayParam,
            tournamentIds: selectedTournaments.length > 0 && (searchString === null || searchString === '') ? selectedTournaments.map(t => t.id) : null,
            searchPhrase: this.isSearch ? searchString.toLowerCase() : null
        });

        if (resultDataRequest.success) {
            this.resultData = resultDataRequest.data;
        }
    }

    @action.bound
    async changeDayParam(dayParamValue) {
        if (this.dayParam !== dayParamValue) {
            this.dayParam = dayParamValue;
        }
    }

    @action.bound
    async resetResultsData() {
        runInAction(() => {
            this.dayParam = 0;
            this.isSearch = false;
            App.offer.rootStore.searchBarViewStore.onTermChange('');
        })
    }
}