const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
    "Accept": 'application/json'
}

class KeepAliveService {

    /**
     * 
     * @returns {promise}
     */
    ping() {
        return httpClient.get(`${BaseAddress}platform/keep-alive`, requestHeaders);
    }

}

export default KeepAliveService;