import { UserDataService } from "../../../administration/services/common";
import { BetSlipApiService } from "../../../services";

const httpClient = App.utils.httpClient;

const url = '/api/slip';

const apiHeaders = {
    'Content-Type': 'application/json',
};

class BetSlipService {
    constructor() {
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.apiService = new BetSlipApiService();
        this.userApiService = new UserDataService();
    }

    async validate(slip, options = null) {
        options = this.getOptions(options);

        const url = `${BaseAddress}${App.utils.getAgencyKey()}/bet-slips/betting-slip-validate/2`;

        return await httpClient.post(url, JSON.stringify(slip), apiHeaders, options);
    }

    async submit(slip, options = null) {
        options = this.getOptions(options);

        const url = `${BaseAddress}${App.utils.getAgencyKey()}/bet-slips/betting-slip-validate/4`;

        return await httpClient.post(url, JSON.stringify(slip), apiHeaders, options);
    }

    async delay() {
        var options = this.getOptions(null);

        return await httpClient.get(`${url}/delay`, apiHeaders, options);
    }

    async getSlip(slipId) {
        return await this.apiService.getBet(slipId, 'betSlipOffers,player.coreUser,betSlipStatus,betStatus,betSlipType,betSlipCombinations');
    }

    async getSlipWithoutCombinations(slipId) {
        return await this.apiService.getBet(slipId, 'betSlipOffers,player.coreUser,betSlipStatus,betStatus,betSlipType');
    }

    async getSlipByEncryptedBetSlipNumber(encryptedBetSlipNumber) {
        var options = this.getOptions(null);
        return await httpClient.get(`${url}/check-bet-slip/${encryptedBetSlipNumber}`, apiHeaders, options);
    }

    async getUserBalance() {
        return await this.userApiService.getUserAccounts();
    }

    getOptions = (options = null) => {
        options = options || {};
        options.responseType = 'json';

        return options;
    }

    /**
     * cash out bet slip
     * @param {string} id bet slip id
     * @param {number} amount bet slip cashout amount
     * @param {bool} isChangeAccepted true if change in cashout is acceptable
     */
    async cashout(id, calculatedCashoutAmount, cType, isChangeAccepted, isLive) {
        const url = `${BaseAddress}${App.utils.getAgencyKey()}/bet-slips/${id}/cashout`;
        const response = await httpClient.put(url, JSON.stringify({
            betSlipId: id,
            oldCashoutAmount: calculatedCashoutAmount,
            cType: cType,
            isChangedAmountAccepted: isChangeAccepted
        }), apiHeaders, {
            responseType: 'json'
        });

        return response;
    }

    getCashoutHistory(id) {
        return this.apiService.getCashoutHistory(id);
    }
}

export default BetSlipService;