import { observable, action } from 'mobx';
import { CheckBoxNodeStore } from "./";

export class ExpandableCheckBoxNodeStore extends CheckBoxNodeStore {


    //#region  observable

    @observable isExpanded = false;

    //#endregion observable



    //#region node actions

    @action.bound
    toggleExpanded() {
        if (!this.isLeaf || this.parent == null) {
            this.isExpanded = !this.isExpanded;
        }
    }

    @action.bound
    expand() {
        if (
            (!this.isLeaf && !this.isExpanded) ||
            this.parent == null
        ) {
            this.isExpanded = true;
        }
    }

    @action.bound
    collapse() {
        if (
            (!this.isLeaf && this.isExpanded) ||
            this.parent == null
        ) {
            this.isExpanded = false;
        }
    }

    @action.bound
    collapseTree() {

        this.collapse();

        this.children?.forEach(child => child.collapseTree());
    }

    //#endregion node actions

}