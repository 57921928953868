import { observable, action, runInAction } from 'mobx';

import { LiveStatus } from '@gp/models';

/**
 * @classdesc Store that manges count subscription for live favorite events
 * @param {object} rootStore Constructor takes rootStore, it is required for hub connection
 * @property {array} eventIds list of event ids for which this store creates count subscription
 * 
 * @function updateEventIds updates eventIds for which this store gets count
 * @function onDispose
 * @function {action} initSubscription internally used to create count subscription for eventIds array, don't call this directly
 */
export class FavoriteEventsCounterStore {

    //#region observable

    @observable eventIds = [];
    @observable count = 0;

    //#endregion observable



    //#region constructor

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    //#endregion constructor



    //#region fetching data

    @action.bound
    initSubscription() {

        this.disposeSubscription();

        if (this.eventIds.length === 0) {
            this.count = 0;
            return;
        }

        const favFilter = {
            subscriptionId: 'live-favorites-counter',
            count: {
                favorites: {
                    liveStatus: LiveStatus.LIVE,
                    id: {
                        eq: this.eventIds
                    }
                },
            }
        }

        this.favSubscription = this.rootStore.hub.getCounts(favFilter)
            .subscribe(response => {
                runInAction(() => {
                    if (!isNaN(response.favorites)) {
                        this.count = response.favorites;
                    }
                });
            }, err => {
                console.error("favorites count error", err);
                runInAction(() => {
                    this.count = 0;
                });
            });

    }

    //#endregion fetching data



    //#region actions

    @action.bound
    updateEventIds(ids) {
        this.eventIds = ids;
        this.initSubscription();
    }

    //#endregion actions



    //#region disposers

    @action.bound
    onDispose() {
        this.eventIds = [];
        this.count = 0;
        this.disposeSubscription();
    }


    @action.bound
    disposeSubscription() {
        if (this.favSubscription) {
            this.favSubscription.unsubscribe();
            this.favSubscription = null;
        }
    }

    //#endregion disposers
}