export class AuthMessageHandler {
    bc = null;

    onInit() {
        if (window.BroadcastChannel != null) {
            this.bc = new BroadcastChannel('user-auth-channel');

            this.bc.onmessage = (e) => {
                if (e.data === 'logout') {
                    this.syncOnLogout();
                }
            };
        }
        else {
            window.addEventListener('storage', e => {
                if (e.key === 'userToken') {
                    const currentUser = localStorage.getItem('userToken');
                    if (App.state.rootStore.userAuthStore.user != null && currentUser == null) {
                        this.syncOnLogout();
                    }
                }
            });
        }
    }

    sendLogoutMessage() {
        if (this.bc != null) {
            this.bc.postMessage('logout');
        }
    }

    syncOnLogout() {
        App.state.rootStore.userAuthStore.removeUserFromLocalStorage();
        App.state.rootStore.userAuthStore.resolveConnection();
        if (App.offer.rootStore.betSlipStore.deactivateOneClickBet) {
            App.offer.rootStore.betSlipStore.deactivateOneClickBet();
        }
        if (App.offer.rootStore.betSlipStore.resetOneClickBetStorage) {
            App.offer.rootStore.betSlipStore.resetOneClickBetStorage();
        }
        if (App.offer.rootStore.betSlipStore.resetBetSlipTypeToDefault) {
            App.offer.rootStore.betSlipStore.resetBetSlipTypeToDefault();
        }
    }
}