
import { computed } from 'mobx';
class CurrencyStore {
    @computed get currency() {
        if (!App.utils.isUserAuthenticated() || !JSON.parse(localStorage.getItem('userAccounts'))) {
            return this.ISOToDisplayCode(DefaultCurrency);
        } else {
            const userAccounts = JSON.parse(localStorage.getItem('userAccounts'));
            return userAccounts[0].currency.displayCode;
        }
    }

    ISOToDisplayCode(currencyISO) {
        switch (currencyISO) {
            // British pound
            case "GBP":
                return "£";
            // Swedish, Norwegian, Danish krone
            case "SEK":
            case "NOK":
            case "DKK":
                return "KR";
            // Croatian kuna
            case "HRK":
                return "KN";
            // Bosnia and Hercegovina convertible mark
            case "BAM":
                return "KM";
            // Turkish lira
            case "TRY":
                return "₺";
            case "USD":
                return "$";
            // Namibian dolar
            case "NAD":
                return "N$";
            // Bulgarian lev
            case "BGN":
                return "лв";
            case "EUR":
            default:
                return "€";
        }
    }

}

export default CurrencyStore;