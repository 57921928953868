import { DateTime } from "luxon";
import { LookupService } from "../../../lookups";
import { BetSlipApiService } from "../../../services";

const httpClient = App.utils.httpClient;

const headers = {
    'Content-Type': 'application/json',
};

const CLOSED_BET_STATUSES = ['returned', 'cancelled', 'won', 'lost'];

class MyBetsService {
    constructor() {
        this.getMiniBets = this.getMiniBets.bind(this);
        this.getLastBet = this.getLastBet.bind(this);
        this.lookupService = new LookupService();
        this.betSlipService = new BetSlipApiService();
    }

    async getMiniBets(type = null) {
        var filter = {};
        filter.embed = 'betSlipOffers,betSlipStatus,betStatus,betSlipType,betSlipCombinations,bettingAccountType';
        var betStatuses = await this.lookupService.findBetStatuses();
        if (type) {
            switch (type) {
                case 'live': filter.isLive = true; break;
                case 'open': filter.betStatusIds = [betStatuses.find(s => s.abrv == 'open').id]; break;
                case 'closed': filter.betStatusIds = betStatuses.filter(bs => CLOSED_BET_STATUSES.includes(bs.abrv)).map(b => b.id); break;
            }
        }

        const result = await this.betSlipService.findBets(filter, 1, 5);
        if (result != null && result.item != null) {
            result.item.forEach(element => {
                element['displayedAmount'] = element.calculatedCashoutAmount >= 1 ? element.calculatedCashoutAmount.toFixed(2) : null;
            });
            return result.item;
        }
        return result;
    }

    async getLastBet() {
        return await httpClient.get(`/${App.utils.getCurrentCulture()}/my-bets/mini/last`, headers, { responseType: 'json' })
    }

    /** Gets event Ids for all events that have been submitted as live events from bet slip offers. */
    async getEventIdsFromLiveBets() {
        var betStatuses = await this.lookupService.findBetStatuses();
        var filter = {
            embed: 'betSlipOffers',
            from: DateTime.utc().startOf('day').toISO(),
            to: DateTime.utc().toISO(),
            isLive: true,
            betStatusIds: [betStatuses.find(s => s.abrv == 'open').id]
        }
        const result = await this.betSlipService.findBets(filter, 1, 100);
        if (result == null || result.item == null || result.item.length < 1) {
            return [];
        }
        var eventIds = result.item.reduce((acc, bet) => {
            var eIds = bet.betSlipOffers.map(o => o.eventId);
            acc.push(...eIds);
            return acc;
        }, []);
        var uniqueIds = [... new Set(eventIds)];
        return uniqueIds;
    }

    async getSystemCombinations(betSlipId) {
        return this.betSlipService.getSystemCombinations(betSlipId);
    }

}

export default MyBetsService;