const httpClient = App.utils.httpClient;

class SportsMenuService {

    constructor() {
        this.getPrematchMenu = this.getPrematchMenu.bind(this);
    }

    /**
     * Gets prematch offer menu
     * @param {Filter} filter Sports menu filter 
     * @returns {Promise<Menu>} Menu
     */
    async getPrematchMenu(filter) {

        const url = new URL(`${BaseProxyAddress}${App.utils.getAgencyKey()}/offer/sport-menu`);
        const requestBody = {
            "Language": App.utils.getCurrentLanguage(),
            "Filter": filter,
        }

        const headers = {
            'Content-Type': 'application/json',
        };

        const options = { responseType: 'json' }

        return await httpClient.post(url, JSON.stringify(requestBody), headers, options);
    }

    async getFavorites(query) {
        const url = new URL(`${window.location.origin}/api/SportsMenu/FindFavorites`);

        this.prepareSearchParams(url, query);

        return await httpClient.get(url, null, { responseType: 'json' });
    }

    /**
     * @param {URL} url 
     * @param {*} query 
     */
    prepareSearchParams = (url, query) => {
        _.each(Object.keys(query), key => {
            url.searchParams.append(key, query[key]);
        })
    };
}

//#region filter type def

/**
 * @typedef {OfferMenuFilter[]} Filter 
 */

/**
 * @typedef {Object} OfferMenuFilter
 * @property {number} EventType
 * @property {number} LiveStatus
 * 
 * @property {string} SportCategoryId
 * @property {string} TournamentId
 * 
 * @property {StartTime} StartTime
 */

/**
 * @typedef {Object} StartTime
 * @property {string} eq
 * @property {string} lt
 * @property {string} gt
 */


//#endregion filter typedef

//#region Menu type def

/**
 * @typedef {SportMenuItem[]} Menu
 */

/**
 * @typedef {Object} SportMenuItem
 * @property {Sport} sport 
 * @property {number} count
 * @property {SportCategoryMenuItem[]} subItems
 */

/** 
 * @typedef {Object} Sport
 * @property {String} abrv
 * @property {String} dateCreated
 * @property {String} dateUpdated
 * @property {Object} id
 * @property {Boolean} isActive
 * @property {String} name
 * @property {Number} sortOrder
*/

/**
 * @typedef {Object} SportCategoryMenuItem
 * @property {SportCategory} sportCategory 
 * @property {number} count
 * @property {Tournament[]} subItems
 */

/** 
 * @typedef {Object} SportCategory
 * @property {String} abrv
 * @property {String} dateCreated
 * @property {String} dateUpdated
 * @property {Object} id
 * @property {Object} sportId
 * @property {String} name
 * @property {Number} sortOrder
*/

/** 
 * @typedef {Object} Tournament
 * @property {String} abrv
 * @property {String} dateCreated
 * @property {String} dateUpdated
 * @property {Object} id
 * @property {Object} sportCategoryId
 * @property {String} name
 * @property {Number} sortOrder
*/

//#endregion Menu type def

export default SportsMenuService;