import { observable, computed, action, reaction } from 'mobx';

class AdditionalOfferStore {
    @observable additionalOfferEventData = {
        eventId: null,
        isLive: false
    };

    @computed get isAdditionalOfferVisible() {
        return this.additionalOfferEventData.eventId != null;
    }

    constructor(rootStore) {
        this.rootStore = rootStore;

        reaction(() => this.isAdditionalOfferVisible, isVisible => {
            this.rootStore.setAdditionalOfferVisible(isVisible);
        });
    }

    @action.bound
    onDispose() {
        // dispose only if we had visible additional offer
        if (this.isAdditionalOfferVisible) {
            this.setAdditionalOfferEvent(null);
        }
    }

    @action.bound
    async onAdditionalOfferExpand({ eventId, isLive}) {
        if (this.additionalOfferEventData.eventId === eventId) {
            this.setAdditionalOfferEvent(null);
        }
        else {
            this.setAdditionalOfferEvent(eventId, isLive);
        }
    }

    @action.bound
    setAdditionalOfferEvent(eventId, isLive) {
        if (this.additionalOfferEventData.eventId != eventId) {
            this.additionalOfferEventData.eventId = eventId;
            if (isLive) {
                this.additionalOfferEventData.isLive = isLive;
            }
        }
    }
}

export default AdditionalOfferStore;