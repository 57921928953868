import { observable, action, computed } from 'mobx';

class SearchBarViewStore {
    @observable searchTerm = '';

    @observable displayValidationPopup = false;

    @computed get trimSearchTerm() {
        return this.searchTerm ? this.searchTerm.trim() : '';
    }

    @computed get isValid() {
        return App.utils.isSearchValid(this.trimSearchTerm);
    }

    @computed get applySearch() {
        return this.searchTerm != null && this.trimSearchTerm !== '';
    }

    @computed get searchFilterArray() {
        return [
            this._getSearchObject('teamOne'),
            this._getSearchObject('teamTwo'),
            this._getEventSearchObject()
        ]
    }

    constructor(config = {}) {
        this.config = config;

        this._getSearchObject = this._getSearchObject.bind(this);
        this._getSearchString = this._getSearchString.bind(this);
    }

    @action.bound
    closeValidationPopup() {
        this.displayValidationPopup = false;
    }

    /**
     * @param {string} term 
     */
    @action.bound
    onChange(term) {
        this.searchTerm = term

        // validate
        if (!this.isValid) {
            this.displayValidationPopup = true;
            return;
        }
        else {
            this.displayValidationPopup = false;
        }

        App.state.redirect(`/${App.utils.getCurrentCulture()}/search/full/${this.trimSearchTerm}`)
    }

    /**
     * @param {string} searchTerm 
     */
    @action.bound
    setValue(searchTerm) {
        this.searchTerm = searchTerm;

        if (this.config.onTermChange) {
            this.config.onTermChange(this.trimSearchTerm)
        }
    }

    @action.bound
    onReset() {
        this.searchTerm = '';

        if (this.config.onTermChange) {
            this.config.onTermChange('');
        }
    }

    /**
     * @private
     * @param {string} value 
     */
    _getSearchString(value) {
        return `%${value}%`;
    }

    /**
     * @private
     * @param {string} key 
     */
    _getSearchObject(key) {
        return {
            [key]: {
                name: {
                    ilike: this._getSearchString(this.searchTerm)
                }
            }
        };
    }

    _getEventSearchObject() {
        return {
            name: {
                ilike: this._getSearchString(this.searchTerm)
            }
        }
    }
}

export default SearchBarViewStore;