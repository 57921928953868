export function isUserAuthenticated() {
	let userToken = getUserToken();
	if (userToken) {
		return true;
	}
	return false;
}

export function getShopId() {
	if (isUserAuthenticated()) {
		const user = getUserFromLocalStorage();
		if (user) {
			return user.shopId;
		}
	}
	// default from appSettings (webpack.config.common)
	return ShopId;
}

export function getAgencyKey() {
	if (isUserAuthenticated()) {
		const user = getUserFromLocalStorage();
		if (user) {
			return user.agencyKey;
		}
	}
	const accountActivationData = getAccountActivationData();
	if (accountActivationData) {
		return JSON.stringify(accountActivationData.agencyKey);
	}
	// default from appSettings (webpack.config.common)
	return AgencyKey;
}

export function getAgencyId() {
	if (isUserAuthenticated()) {
		const user = getUserFromLocalStorage();
		if (user) {
			return user.agencyId;
		}
	}
	const accountActivationData = getAccountActivationData();
	if (accountActivationData) {
		return accountActivationData.agencyId;
	}
	// default is not defined, return empty string
	return "";
}

export function getApplicationHeaders() {
	let headers = {
		"Accept-Language": App.utils.getCurrentLanguage(),
		"Shop-Id": getShopId(),
		"Agency-Key": getAgencyKey(),
	};
	if (getAgencyId()) {
		headers["Agency-Id"] = getAgencyId();
	}
	let token = getUserToken();
	if (token && token.token) {
		headers["Authorization"] = "Bearer " + token.token;
	}
	return headers;
}

export function getUserToken() {
	let tokenString = localStorage.getItem("userToken");
	if (tokenString) {
		return JSON.parse(tokenString);
	}
	return null;
}

export function getUserFromLocalStorage() {
	if (isUserAuthenticated()) {
		let userString = localStorage.getItem("user");
		if (userString) {
			return JSON.parse(userString);
		}
	}
	return null;
}

function getAccountActivationData() {
	const accountActivationStringValue =
		localStorage.getItem("accountActivation");
	if (accountActivationStringValue) {
		return JSON.parse(accountActivationStringValue);
	}
	return null;
}

export function getAccountActivationToken() {
	const accAct = getAccountActivationData();
	if (accAct) {
		return accAct.activationToken;
	}
	return null;
}
