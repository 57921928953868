class ValidationStore {
    constructor() {
        this.isDobValid = false;
        this.paymentDatesValid = false;
        this.isEmailAvailable = false;
        this.isUsernameAvailable = false;
        this.isPasswordValid = false;
        this.isBettingLimitValid = false;

        this.setDobValid = this.setDobValid.bind(this);
        this.setPaymentDatesValid = this.setPaymentDatesValid.bind(this);
    }

    setPasswordValid(value) {
        this.isPasswordValid = value;
    }

    setDobValid(value) {
        this.isDobValid = value;
    }

    setPaymentDatesValid(value) {
        this.paymentDatesValid = value;
    }

    setEmailAvailable(value) {
        this.isEmailAvailable = value;
    }

    setUsernameAvailable(value) {
        this.isUsernameAvailable = value;
    }
    setBettingLimitValid(value) {
        this.isBettingLimitValid = value;
    }
}

export default ValidationStore;