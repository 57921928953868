const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
}
export class DataConfirmationService {
    async acceptDataVerification(body) {
        const url = '/api/user/data-confirmation';
        return await httpClient.post(url, JSON.stringify(body), requestHeaders, { responseType: 'json' });
    }

    async fetchCountryLookups() {
        const url = `${BaseAddress}platform/countries?sort=name|asc&fields=id,name&rpp=1000&translate=true`;
        return await httpClient.get(url, requestHeaders, { responseType: 'json' });
    }
}

