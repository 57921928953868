import { observable, computed, action } from 'mobx';

class SubheaderViewStore {
    @observable _selected = 'full';
    @observable isExpanded = false;

    menu = [
        {
            period: 'today',
            name: 'PREMATCH.SIDEMENU.PERIOD.TODAY',
        },
        {
            period: '3h',
            name: 'PREMATCH.SIDEMENU.PERIOD.3H',
        },
        {
            period: '48h',
            name: 'PREMATCH.SIDEMENU.PERIOD.48H',
        },
        {
            period: '3d',
            name: 'PREMATCH.SIDEMENU.PERIOD.3D',
        },
        {
            period: 'full',
            name: 'PREMATCH.SIDEMENU.PERIOD.FULL',
        }
    ];

    @computed get selected() {
        return this.menu.find(i => i.period === this._selected) || {
            name: 'PREMATCH.SUBHEADER.FULL',
            period: 'full'
        };
    }

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action.bound
    setSelected(value) {
        if (this._selected !== value) {
            this._selected = value;
        }
        this.isExpanded = false;
    }

    @action.bound
    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }
}

export default SubheaderViewStore;