import { observable, computed, action, reaction, when } from 'mobx';
import { SportsMenuService } from '../../../services/components';
import { generatePrematchOfferMenuFilter } from '../sports-menu/helpers';

import { BaseOfferMenuStore } from './'
import { ExpandableCheckBoxNodeStore as NodeStore, CheckStateEnum } from './node';
import { PrematchPeriodSubMenuStore } from './period-sub-menu';

export class PrematchOfferMenuStore extends BaseOfferMenuStore {

    @observable selectedSport = null;

    //#region sideMenu actions overrides

    /** 
    * @param {ExpandableCheckBoxNodeStore} node sport node to be selected in main sports list
    */
    @action.bound
    toggleSport(node) {
        if (node?.node?.id === this.selectedSport?.node?.id) {
            this.deselectSport(node);
        } else {
            this.selectSport(node);
        }
    }

    /** 
    * @param {ExpandableCheckBoxNodeStore} node sport node to be selected in main sports list
    */
    @action.bound
    selectSport(node) {

        this.selectedSport = node;
        this.openOfferMenu();
    }

    /** Removes sport from side menu and closes side menu. */
    @action.bound
    deselectSport() {
        this.selectedSport = null;
        this.closeOfferMenu();
    }

    //#endregion sideMenu actions overrides



    //#region observable

    @observable menu = new NodeStore();

    //#endregion observable



    //#region constructor

    constructor() {
        super(...arguments)

        this.sportMenuService = new SportsMenuService();
        this.periodSubMenu = new PrematchPeriodSubMenuStore(this);
        this.startUrlSync();
    }

    //#endregion constructor



    //#region sport menu

    @observable isOfferMenuOpen = false;

    @computed get isExpandDisabled() {
        return (
            this.menu.children.length <= 0
        );
    }

    @action.bound
    toggleOpenOfferMenu() {
        if (this.isOfferMenuOpen) {
            this.closeOfferMenu();
        } else {
            this.openOfferMenu();
        }
    }

    @action.bound
    openOfferMenu() {

        if (this.selectedSport === null) {
            this.selectedSport = this.menu?.children?.[0];
        }

        this.isOfferMenuOpen = true;
        this.periodSubMenu.isPeriodMenuExpanded = false;
        this.rootStore.openSideMenu();
    }

    @action.bound
    closeOfferMenu() {
        this.selectedSport = null;

        this.isOfferMenuOpen = false;
        this.rootStore.closeSideMenu();
    }

    //#endregion sport menu



    //#region fetching data


    @action.bound
    async fetchData() {
        if (!this.isFetchingData) {
            this.onStartFetching();
        }

        const fetchingForPeriod = this.periodSubMenu.selectedPeriodKey;
        const filter = generatePrematchOfferMenuFilter(fetchingForPeriod);
        const menuResponse = await this.sportMenuService.getPrematchMenu(filter);

        if (fetchingForPeriod !== this.periodSubMenu.selectedPeriodKey) {
            return;
        }

        this.assignMenu(menuResponse);

        this.onBeforeFetchFinish();

        this.onDoneFetching();
    }

    @action.bound
    selectFirstSport() {

        // Don't select first sport on home page;
        if (!App.state.history.location.pathname.includes('land')) {
            return;
        }

        const selectedSport = this.menu?.children?.[0];
        this.selectSport(selectedSport);
    }

    @action.bound
    onBeforeInitialize() {
        this.openOfferMenu();
    }

    @action.bound
    onBeforeFetchFinish() {

        // Since store is constantly being reinitialized check first if there is a valid first sport
        if (this.selectedSport == null || this.menu.children?.find(sport => sport.node.id === this.selectedSport?.node.id) == null) {
            this.selectFirstSport(this.menu.children?.[0]);
        }

        if (this.urlSync == null) {
            this.startUrlSync();
        }
    }



    //#endregion fetching data



    //#region disposers

    @action.bound
    onDispose() {
        this.onBaseDispose();
        this.disposeWhenStoreIsInitialized();
        this.disposeUrlSyn();
    }

    @action.bound
    disposeWhenStoreIsInitialized() {
        if (this.waitForStoreInitialize != null) {
            this.waitForStoreInitialize();
            this.waitForStoreInitialize = null;
        }
    }

    @action.bound
    disposeUrlSyn() {
        if (this.urlSync != null) {
            this.urlSync();
            this.urlSync = null;
        }
    }

    //#endregion disposers



    //#region url update


    @action.bound
    updateUrl() {

        const url = this.getUrl();
        App.state.redirect(url);
    }

    getUrl() {
        const segments = this.menu.urlStructure;
        // If there are any segments then it will redirect to sports page
        const urlBase = this.getBaseUrl(segments === '');
        const queryParams = App.state.history.location.search;

        if (segments == null || segments === '') {
            return urlBase + queryParams;
        }

        return urlBase + `/f${segments}` + queryParams;
    }

    getBaseUrl(persistPage = false) {
        const period = this.periodSubMenu.selectedPeriodKey;
        const page = App.state.history.location.pathname.includes('home') && persistPage ? 'home' : 'sports';

        return `/${App.utils.getCurrentCulture()}/${page}/${period}`;

    }

    //#endregion url update



    //#region update params

    @action.bound
    async setRouterMatchObj(routerMatch) {

        const {
            params,
            path,
        } = routerMatch;

        // If store is not yet initialized then after initialization is done reset menu based on url
        const shouldResetMenuOnUrl = !this.isStoreInitialized || App.state.history.action !== "PUSH" || path.includes('tournament');
        this.disposeWhenStoreIsInitialized();

        this.waitForStoreInitialize = when(
            () => this.isStoreInitialized,
            () => {
                this.setParams(params, shouldResetMenuOnUrl);

                if (path.includes('land')) {
                    this.selectFirstSport();
                }

                // Clear menu selection if we switch to home page
                if (path.includes('home')) {
                    this.menu.onCheck(0);
                    this.menu.collapseTree();
                }
            });

    }

    /** 
     * @param {object} params params from react router
     * @param {boolean} shouldResetMenuOnUrl if true reset menu check state to url segments
     */
    @action.bound
    setParams(params, shouldResetMenuOnUrl) {

        const {
            period,
            segments,
        } = params;



        if (shouldResetMenuOnUrl) {
            this.resetMenuOnUrlParamsFromRouter(segments);
        }

        this.periodSubMenu.setPeriodKey(period);
    }

    //#endregion update params



    //#region  url sync

    @action.bound
    startUrlSync() {

        this.urlSync = reaction(
            () => ({
                selected: this.menu.urlStructure,
                period: this.periodSubMenu.selectedPeriodKey,
            }),
            () => {
                this.updateUrl();
            }
        )
    }

    //#endregion url syn
}
