import { observable, action, computed } from 'mobx';

class MultiEventViewStore {
    @observable event;
    @observable enableBettingTypeGroups = false;
    @observable displayBettingGroup = 'all';

    @observable isLMTAvailable = true;

    constructor(componentProps) {
        this.enableBettingTypeGroups = componentProps.enableBettingTypeGroups;
        this.event = componentProps.event;
    }

    @action.bound
    lmtDisable() {
        this.isLMTAvailable = false;
    }

    @action.bound
    setDisplayBettingGroup(value) {
        if (value !== this.displayBettingGroup) {
            this.displayBettingGroup = value;
        }
    }
}

export default MultiEventViewStore;