import { observable, computed, action, runInAction, reaction } from 'mobx';
import moment from 'moment';
import { merge } from 'lodash';

import { EventType, LiveStatus } from '@gp/models';
import { OfferMapper, PrematchOfferStore } from '@gp/offer';
import { ConsoleLogger } from '@gp/utility';

import AdditionalOfferStore from '../../AdditionalOfferStore';
import { BettingTypeSelectorsStore } from '../../components/selectors';
import { parsePageNumberToValid } from '../../../../ui/common/utils';
import { LoaderStore } from '../../../../state/stores/common';

const PeriodConverter = App.state.PeriodConverter;
const logger = App.state.logger;

const DefaultConfig = {
    columns: []
};

class SportsViewStore extends PrematchOfferStore {
    config = DefaultConfig;

    //#region observable

    @observable firstColumnBettingTypes = []; //bettingTypesPerColumn.firstColumn; //['3way', 'winner'];
    @observable secondColumnBettingTypes = []; //bettingTypesPerColumn.secondColumn; //['double-chance'];
    @observable thirdColumnBettingTypes = []; //bettingTypesPerColumn.thirdColumn; //['over-under', 'over-under-games-in-the-match'];
    @observable fourthColumnBettingTypes = []; //bettingTypesPerColumn.fourthColumn; //['draw-no-bet'];
    @observable fifthColumnBettingTypes = []; //bettingTypesPerColumn.fifthColumn; //['who-wins-1st-half'];

    @observable selectedSports = [];
    @observable selectedCategories = [];
    @observable selectedTournaments = [];
    /**
     * This is array of sportCategoryIds for which to load special offer
     */
    @observable selectedSpecials = [];

    @observable hiddenSports = [];
    @observable hiddenTournaments = [];

    @observable period = 'full';

    @observable isStoreInitialized = false;
    @observable isFetchingData = false;

    //#endregion observable



    //#region  computed 

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    /** Override */
    @computed get isEmpty() {
        return this.eventsMap.size === 0 && !this.isStoreInitialized && !this.isFetchingData && !this.isLoading;
    }

    @computed get allTournamentIds() {
        return this.rootStore.prematchMenuViewStore.menu.selectedTournaments;
    }

    @computed get isRemoveAllVisible() {
        const selectedItemsCurrentState = this.rootStore.prematchMenuViewStore.menu.selectedItems;
        return selectedItemsCurrentState.categories.length > 1 || selectedItemsCurrentState.sports.length > 0 || (selectedItemsCurrentState.categories.length + selectedItemsCurrentState.tournaments.length) > 1;
    }

    @computed get totalPages() {
        const remainder = this.totalItems % this.pageSize;
        const totalPages = remainder === 0 ? (this.totalItems / this.pageSize) : ((this.totalItems - remainder) / this.pageSize) + 1;

        return totalPages;
    }

    //#endregion computed



    //#region constructor

    constructor(rootStore, config = undefined) {
        super({
            logger: new ConsoleLogger(false),
            removeDelay: 10,
            throttle: 4,
            enableThrottling: true,
            pageSize: 5,
            bettingTypeSelectorsStore: new BettingTypeSelectorsStore()
        });


        if (config != null) {
            this.config = merge({}, DefaultConfig, config);
        }

        this.rootStore = rootStore;
        this.loader = new LoaderStore();

        this.additionalOfferStore = new AdditionalOfferStore(rootStore);
        this.bettingTypeSelectorsStore = new BettingTypeSelectorsStore();
    }

    //#endregion constructor



    //#region fetching data

    @action.bound
    async initializeStore(pageNumber) {

        this.loader.suspend();
        this.isFetchingData = true;
        this.disposeSubscription();

        this.pageNumber = parsePageNumberToValid(pageNumber);

        App.state.updateUrlParams({ page: this.pageNumber }, true);

        await this.fetchPrematchOffer();

        if (this.totalPages === 0 || !this.isFetchingData) {
            this.onDoneFetching();
            return;
        }

        if (this.pageNumber > this.totalPages) {
            return this.changePage(this.totalPages);
        }

        if (this.liveEvents.length > 0) {
            this.createLiveSubscription();
        } else {
            this.onDoneFetching();
        }
    }

    @action.bound
    async fetchPrematchOffer() {
        const filters = this.generatePrematchFilters();

        let offerResponse = null;
        try {
            offerResponse = await this.rootStore.hub.getOffer({
                paging: {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize
                },
                filter: filters,
                grouping: 'tournamentGroup'
            });
        }
        catch (err) {
            logger.logError(err);
            this.onDoneFetching();
            return;
        }

        runInAction(() => {
            this.assignOfferData(offerResponse);
        })
    }

    @action.bound
    createLiveSubscription() {

        this.disposeSubscription();

        const subscriptionRequest = this.generateLiveSubscriptionRequest();

        logger.logTrace("Connecting to hub: sports-live", subscriptionRequest);

        this.subscription = this.rootStore.hub.getOfferSubscription(subscriptionRequest)
            .subscribe(response => {
                runInAction(() => {
                    this.assignLiveData(response, true);
                    this.onDoneFetching();
                });
            }, err => {
                console.error(err);
                this.onDoneFetching();
            });
    }

    generatePrematchFilters() {

        let bettingOfferIntervalEndDate = PeriodConverter.CalculateOfferInterval(this.period)

        let timeIntervalFilter = {
            startTime: {
                lt: bettingOfferIntervalEndDate
            }
        }

        if (this.period === 'full') {
            timeIntervalFilter = {}
        }

        const filters = [];

        let applyDefault = true;
        if (this.allTournamentIds == null || this.allTournamentIds === '') {
            return;
        }

        if (this.allTournamentIds.normal.length > 0) {
            // tournament filter for all normal events
            const prematchBts = OfferMapper.getBettingTypes('prematch');
            filters.push({
                ...timeIntervalFilter,
                tournamentId: {
                    eq: this.allTournamentIds.normal
                },
                liveStatus: LiveStatus.PREMATCH,
                eventType: EventType.NORMAL,
                offers: [
                    {
                        bettingType: {
                            abrv: {
                                eq: prematchBts.normal
                            }
                        },
                    },
                    {
                        bettingType: {
                            abrv: {
                                eq: prematchBts.marginal
                            }
                        },
                        isFavorite: true
                    }
                ]
            });
            // for live events
            const liveBts = OfferMapper.getBettingTypes('live');
            filters.push({
                tournamentId: {
                    eq: this.allTournamentIds.normal
                },
                liveStatus: LiveStatus.LIVE,
                eventType: EventType.NORMAL,
                offers: [
                    {
                        bettingType: {
                            abrv: {
                                eq: liveBts.normal
                            }
                        },
                    },
                    {
                        bettingType: {
                            abrv: {
                                eq: liveBts.marginal
                            }
                        },
                        isFavorite: true
                    }
                ]
            });

            applyDefault = false;
        }

        if (this.selectedSpecials.length > 0 || this.allTournamentIds.specials.length > 0) {
            filters.push({
                ...timeIntervalFilter,
                sportCategoryId: {
                    eq: [...(this.selectedSpecials || []), ...(this.allTournamentIds.specials || [])]
                },
                eventType: {
                    eq: [EventType.SHORT_TERM, EventType.LONG_TERM]
                },
                offers: [
                    {
                        bettingType: {
                            groupId: {
                                eq: ["special"]
                            }
                        }
                    }
                ]
            });

            applyDefault = false;
        }

        if (applyDefault) {
            filters.push({
                ...timeIntervalFilter,
                eventType: EventType.NORMAL
            });
        }

        return filters
    }

    generateLiveSubscriptionRequest() {
        const allBts = OfferMapper.getBettingTypes('live');
        return ({
            subscriptionId: 'sports-live',
            // compress: true,
            compress: false,
            channels: [
                {
                    name: 'event',
                    filter: {
                        id: {
                            eq: this.liveEvents.map(e => e.id)
                        },
                        liveStatus: LiveStatus.LIVE
                    }
                },
                {
                    name: 'betOffer',
                    filter: [
                        {
                            bettingType: {
                                abrv: {
                                    eq: allBts.normal
                                }
                            },
                        },
                        {
                            bettingType: {
                                abrv: {
                                    eq: allBts.marginal
                                }
                            },
                            isFavorite: true
                        }
                    ]
                }
            ],
        });
    }

    @action.bound
    onDoneFetching() {
        this.loader.resume();
        this.isStoreInitialized = true;
        this.isFetchingData = false;
    }

    //#endregion fetching data



    //#region  disposers

    @action.bound
    onDispose() {
        this.disposeSubscription();

        this.loader.resume();
        this.isStoreInitialized = false;
        this.isFetchingData = false;

        this.reset();

        this.onReset();
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    onReset() {
        this.selectedSports = [];
        this.selectedCategories = [];
        this.selectedTournaments = [];
        this.selectedSpecials = [];

        this.hiddenSports = [];
        this.hiddenTournaments = [];

        this.pageNumber = 1;
        this.totalItems = 0;

        this.period = 'full';

        this.additionalOfferStore.onDispose();
        this.configuration.bettingTypeSelectorsStore.reset();
    }

    //#endregion disposers



    @action.bound
    setPeriod(period) {
        if (this.period !== period) {
            this.period = period;
        }
    }

    @action.bound
    setSelectedSports(sports) {
        this.selectedSports = sports;
    }

    @action.bound
    setSelectedCategories(categories) {
        this.selectedCategories = categories;
    }

    @action.bound
    setSelectedTournaments(tournaments) {
        this.selectedTournaments = tournaments;
    }

    @action.bound
    setSelectedSpecials(specials) {
        this.selectedSpecials = specials;
    }

    @action.bound
    hideShowSport(sportId) {
        if (this.hiddenSports.includes(sportId)) {
            this.hiddenSports = this.hiddenSports.filter(s => s !== sportId)
        } else {
            this.hiddenSports.push(sportId);
        }
    }

    @action.bound
    hideShowTournament(tournamentId) {
        if (this.hiddenTournaments.includes(tournamentId)) {
            this.hiddenTournaments = this.hiddenTournaments.filter(t => t !== tournamentId)
        } else {
            this.hiddenTournaments.push(tournamentId);
        }
    }

    /**
     * OVERRIDES
     */

    @action.bound
    async changePage(newPage) {
        if (newPage !== this.pageNumber) {
            this.pageNumber = newPage;

            this.additionalOfferStore.setAdditionalOfferEvent(null);

            // scroll to top
            window.scrollTo(0, 0);
            await this.initializeStore(newPage);

        }
    }
}

export default SportsViewStore;