import { observable, action, autorun, computed } from 'mobx';

// in px
const LIMIT_1 = 1390;
const LIMIT_2 = 1200;

/**
 * Resize is triggered in MainLayout.jsx
 * Resize is only applied to desktop versions of the application
 */
class ResizeStore {
    @observable width = 0;
    @observable height = 0;

    @computed get availableColumns() {
        if (this.width > LIMIT_1) {
            return 4;
        }
        else if (this.width > LIMIT_2) {
            return 3;
        }

        return 2;
    }

    @computed get visibleColumns() {
        return Array(this.availableColumns).fill().map((_, idx) => `${idx + 1}`);
    }

    /**
     * @param {{width: number, height: number}} params 
     */
    @action.bound
    updateWidthAndHeight(params) {
        if (params.width !== this.width) {
            this.width = params.width;
        }
        if (params.height !== this.height) {
            this.height = params.height;
        }
    }
}

export default ResizeStore;