import { action, observable } from "mobx";
import { resultsService as ResultsService } from "../services";

export class ResultsMenuStore {
    @observable resultMenu;
    @observable selectedTournaments = [];
    @observable selectedSport = null;
    @observable expandedCategories = [];
    @observable isMenuExpanded = false;

    constructor(){}

    @action.bound
    async onInitialize() {
        const resultMenuRequest = await ResultsService.getMenu({ previousDays: 7 });

        if (resultMenuRequest.success) {
            this.resultMenu = resultMenuRequest.data;
        }
    }

    @action.bound
    setSelectedTournaments(tournament) {
        const selectedTournamentIdx = this.selectedTournaments.findIndex(t => t.id === tournament.id);
        if (selectedTournamentIdx > -1) {
            this.selectedTournaments.splice(selectedTournamentIdx, 1);
            if (this.selectedTournaments.length === 0) {
                App.offer.rootStore.resultsDataStore.changeDayParam(0);
            }
        }
        else {
            this.selectedTournaments.unshift(tournament);
        }
        if (this.selectedTournaments.length > 0) {
            this.isSearch = false;
            App.offer.rootStore.searchBarViewStore.onTermChange('');
            App.offer.rootStore.resultsDataStore.changeDayParam(null);
        }
    }

    @action.bound
    setExpandedCategories(categoryId) {
        const expandedCategoryIdx = this.expandedCategories.findIndex(c => c === categoryId);
        if (expandedCategoryIdx > -1) {
            this.expandedCategories.splice(expandedCategoryIdx, 1);
        }
        else {
            this.expandedCategories.push(categoryId);
        }
    }

    @action.bound
    toggleMenuExpanded(isManualToggle = false) {
        if (isManualToggle) {
            const firstSportId = this.resultMenu.length > 0 ? this.resultMenu[0].id : null;

            if (firstSportId != null && this.selectedSport === null) {
                this.selectedSport = firstSportId;
            }
        }
        this.isMenuExpanded = !this.isMenuExpanded;
    }

    @action.bound
    setSelectedSport(sportId) {
        if (sportId != null) {
            this.selectedSport = sportId;
        }
        else {
            this.selectedSport = null;
        }
    }
    
    @action.bound
    resetSelectedTournaments() {
        this.selectedTournaments = [];
    }
}