import { observable, action, runInAction, computed, when } from 'mobx';
import { isEqual, sortBy } from 'lodash';
import { LiveStatus } from '@gp/models';
import { LoaderStore } from '../../../../state/stores/common';

const logger = App.state.logger;

class LiveMenuViewStore {

    //#region disposers

    liveMenuSubscription = null;
    whenHubStartedDisposer = null;

    //#endregion disposers



    //#region observable

    @observable isInitialized = false;
    @observable period = 'events';
    @observable culture;
    @observable menuOriginal = [];

    @observable selected = [];
    @observable selectedSportIds = [];

    @observable liveEventsCount = 0;
    @observable upcomingEventsCount = 0;
    @observable liveFavoritesCount = 0;
    @observable totalHighlightsCount = 0;
    @observable highlightFavoritesCount = 0;

    //#endregion observable



    //#region computed

    @computed get menu() {
        return (this.menuOriginal.length > 0) && this.menuOriginal.map(i => ({ sport: i.sport, count: i.count })) || [];
    }

    @computed get menuFilter() {
        let filter = {};
        if (this.period === 'events') {
            filter.liveStatus = LiveStatus.LIVE;
        }
        else if (this.period === 'upcoming') {
            filter.liveStatus = LiveStatus.PREMATCH;
            filter.isUpcoming = true;
        }
        else if (this.period === 'highlights') {
            filter.liveStatus = LiveStatus.LIVE;
            filter.isTopEvent = true;
        }
        else if (this.period === 'my-favorites') {
            filter.liveStatus = LiveStatus.LIVE;
        }

        return filter;
    }

    @computed get selectedUrl() {
        if (this.selected.indexOf('favorites') > -1) {
            return 'favorites';
        }
        else if (this.selected.indexOf('highlights') > -1) {
            return 'highlights';
        }
        else {
            return this.selected.join('/');
        }
    }

    @computed get liveCount() {
        return this.liveEventsCount;
    }

    @computed get highlightsCount() {
        return this.totalHighlightsCount;
    }

    @computed get favoritesCount() {
        return this.liveFavoritesCount;
    }

    @computed get isLoading() {
        this.loader.isLoading;
    }

    @computed get isExpanded() {
        return this.rootStore.isSideMenuExpanded;
    }

    //#endregion computed



    //#region constructor

    constructor(rootStore) {
        this.rootStore = rootStore;
        const hub = rootStore.hub;
        this.hub = hub;
        this.loader = new LoaderStore();
    }

    //#endregion constructor



    //#region actions

    @action.bound
    onPeriodChange(period) {
        if (this.period !== period) {
            this.period = period;
        }

        // await this.onInitialize();
    }

    @action.bound
    setCulture(culture) {
        this.culture = culture;
    }

    /**
     * @param {'favorites'|'highlights'|string[]} selected Either favorites, highlights or array of selected sport ids
     */
    @action.bound
    setSelected(selected) {
        if (selected === 'favorites' || selected === 'highlights') {
            this.selected = [selected];
        }
        else {
            if (!isEqual(sortBy(this.selected), sortBy(selected))) {
                this.selected = selected;
            }
        }
    }

    //#endregion actions



    //#region fetching data

    /**
     * Used to initialize live menu. Call on componentDidMount()
     * @param {{culture: string, period: string}} initObject
     */

    @action.bound
    onInitialize() {

        this.loader.suspend();
        this.isFetchingData = true;

        this.disposeWhenHubStarted();
        this.whenHubStartedDisposer = when(
            () => this.hub.isStarted,
            this.fetchData
        )
    }

    @action.bound
    fetchData() {
        this.createMenuSubscription();
        this.createMenuCountSubscription();
    }

    @action.bound
    createMenuCountSubscription() {
        if (this.period !== 'events') {
            this.selected = [];
        }

        logger.logTrace('Initialize menu count subscription');

        const menuCountSubscription = {
            subscriptionId: 'live-menu-counter',
            count: {
                live: {
                    liveStatus: 1,
                },
                upcoming: {
                    liveStatus: 0,
                    isUpcoming: true,
                },
                highlights: {
                    liveStatus: 1,
                    isTopEvent: 1,
                },
            }
        };

        this.menuCountSubscription = this.hub.getCounts(menuCountSubscription)
            .subscribe(response => {
                if (!isNaN(response.live)) {
                    this.liveEventsCount = response.live;
                }
                if (!isNaN(response.upcoming)) {
                    this.upcomingEventsCount = response.upcoming;
                }
                if (!isNaN(response.highlights)) {
                    this.totalHighlightsCount = response.highlights;
                }
            }, err => {
                console.error(err);
                this.onDoneFetching();
            });
    }

    @action.bound
    _initFavoritesSubscription(eventIds = []) {
        this.favoriteEventIds = eventIds;

        if (this.favoriteEventIds.length === 0) {
            this.liveFavoritesCount = 0;

            if (this.favSubscription) {
                this.favSubscription.unsubscribe();
                this.favSubscription = null;
            }

            return;
        }

        const favFilter = {
            subscriptionId: 'live-favorites-counter',
            count: {
                favorites: {
                    liveStatus: 1,
                    id: {
                        eq: this.favoriteEventIds
                    }
                },
                highlightsFavorites: {
                    liveStatus: 1,
                    isTopEvent: 1,
                    id: {
                        eq: this.favoriteEventIds
                    }
                },
            }
        }

        this.favSubscription = this.hub.getCounts(favFilter)
            .subscribe(response => {
                runInAction(() => {
                    if (!isNaN(response.favorites)) {
                        this.liveFavoritesCount = response.favorites;
                    }
                    if (!isNaN(response.highlightsFavorites)) {
                        this.highlightFavoritesCount = response.highlightsFavorites;
                    }
                });
            }, err => {
                console.error("favorites count error", err);
                runInAction(() => {
                    this.liveFavoritesCount = 0;
                });
            });

    }

    @action.bound
    createMenuSubscription() {

        this.disposeLiveMenuSubscription();
        let subscriptionRequest = {
            subscriptionId: 'live-menu',
            filter: this.menuFilter
        };

        logger.logTrace('Fetch live menu', subscriptionRequest);

        this.liveMenuSubscription = this.hub.getMenuSubscription(subscriptionRequest)
            .subscribe(response => {
                runInAction(() => {
                    this.assignMenu(response);
                    this.onDoneFetching();
                });
            }, err => {
                console.error(err);
                this.onDoneFetching();
            });
    }

    @action.bound
    onDoneFetching() {
        this.isInitialized = true;
        this.isFetchingData = false;
        this.loader.resume();
    }

    @action.bound
    assignMenu(menu) {
        this.menuOriginal = menu.menu;
    }

    //#endregion fetching data



    //#region disposers

    @action.bound
    onDispose() {
        // dispose reaction first otherwise it will trigger double routing
        if (this.urlChangeReactionDisposer) {
            this.urlChangeReactionDisposer();
        }

        if (this.initReactionDisposer) {
            this.initReactionDisposer();
        }

        if (this.menuCountSubscription) {
            this.menuCountSubscription.unsubscribe();
        }

        this.disposeWhenHubStarted();
        this.disposeLiveMenuSubscription();

        this.resetObservables();
    }

    @action.bound
    resetObservables() {
        this.period = 'events';
        this.liveEventsCount = 0;
        this.totalHighlightsCount = 0;
        this.highlightFavoritesCount = 0;
        this.upcomingEventsCount = 0;
        this.isInitialized = false;
    }

    @action.bound
    disposeWhenHubStarted() {
        if (this.whenHubStartedDisposer != null) {
            this.whenHubStartedDisposer();
            this.whenHubStartedDisposer = null;
        }
    }

    @action.bound
    disposeLiveMenuSubscription() {
        if (this.liveMenuSubscription != null) {
            this.liveMenuSubscription.unsubscribe();
            this.liveMenuSubscription = null;
        }
    }

    @action.bound
    clearSelectedSportIds() {
        this.selected = [];
        this.selectedSportIds = [];
    }

    //#endregion disposers



    //#region sideMenu 

    @action.bound
    toggleMenuExpand() {
        this.rootStore.toggleSideMenu();
    }


    //#endregion
    
    @action.bound
    onSelect(itemId) {
        logger.logTrace('Selected menu item', itemId);

        if (itemId === 'highlights' || itemId === 'favorites') {
            logger.logTrace('Replace selected.');
            this.selected = this.selected.indexOf(itemId) > -1 ? [] : [itemId];
        }
        else {
            // copy to internal var so we don't change state and trigger reactions
            let internalSelected = this.selected;
            if (internalSelected.indexOf('highlights') > -1 || internalSelected.indexOf('favorites') > -1) {
                internalSelected = [];
            }

            if (internalSelected.indexOf(itemId) === -1) {
                logger.logTrace('Added selected sport.', itemId);
                internalSelected.push(itemId);
            }
            else {
                logger.logTrace('Removed selected sport.', itemId);
                internalSelected.remove(itemId);
            }

            // apply state change
            this.selected = internalSelected.slice();
        }
    }

 

}

export default LiveMenuViewStore;