import { DateTime } from "luxon";
import { EventChangeTypeLookupService } from "../search-page/lookups";
import { BettingTypesLookupService } from "../search-page/lookups";

const httpClient = App.utils.httpClient;

const requestHeaders = {
    'Content-Type': 'application/json'
};

const baseUrl = `${BaseAddress}platform`;
const bettingTypesList = [
    '3way',
    'winner',
    'head2head',
    'head2head-1x2',
    'winner-incl-extra-innings',
    'winner-incl-overtime',
    'winner-incl-super-over'
]

class ResultsService {

    constructor() {}

    async getMenu(filter) {
        const timeFilter = getTimeSpan(filter.previousDays);

        return await httpClient.get(`${baseUrl}/events-with-results-per-tournaments?from=${timeFilter.startTimeFrom}&to=${timeFilter.startTimeTo}&translate=True&rpp=7000`, requestHeaders, { responseType: 'json' })
    }

    async getResults(filter) {
        const timeFilter = getTimeSpan(filter.previousDays);
        const bettingTypes = await BettingTypesLookupService.findLookup();

        return await httpClient.post(`${baseUrl}/events/with-results`, JSON.stringify({
            'eventWithTips': true,
            'isEndOfMatch': true,
            'isAvailableOnInternet': true,
            'feedTypes': [0, 1],
            'tournamentIds': filter.tournamentIds,
            'sportCategoryIds': filter.sportCategoryIds,
            'bettingTypeIds': bettingTypes.item.filter(bt => bettingTypesList.includes(bt.abrv)).map(item => item.id),
            'startTimeFrom': timeFilter.startTimeFrom,
            'startTimeTo': timeFilter.startTimeTo,
            'searchPhrase': filter.searchPhrase
        }), requestHeaders, { responseType: 'json' });
    }

    async getEventResult(eventId) {
        const eventChangeTypes = await EventChangeTypeLookupService.findLookup();

        const eventChangeTypeIds = eventChangeTypes.item.filter(function (item) {
            return item.abrv == "score-change";
        }).map(item => item.id);

        return await httpClient.get(`${baseUrl}/event-changes?eventIds=${eventId}&eventChangeTypeIds=${eventChangeTypeIds.join(',')}&sort=time|asc`, requestHeaders, { responseType: 'json' });
    }
    
}

const getTimeSpan = (day) => {
    const now = DateTime.now();

    let timeSpan = null;

    switch (day)
    {
        case -1:
            timeSpan = {
                startTimeFrom: now.plus({ days: -1 }).startOf('day').toUTC(),
                startTimeTo: now.plus({ days: -1 }).endOf('day').toUTC()
            };
            break;
        case 0:
            timeSpan = {
                startTimeFrom: now.startOf('day').toUTC(),
                startTimeTo: now.endOf('day').toUTC()
            };
            break;
        default:
            timeSpan = {
                startTimeFrom: now.plus({ days: -day }).startOf('day').toUTC(),
                startTimeTo: now.toUTC()
            };
            break;
    }

    return timeSpan;
};

const resultsService = new ResultsService();

export { resultsService };