const httpClient = App.utils.httpClient;
const requestHeaders = {
    'Content-Type': 'application/json',
};
class BettingTypesLookupService {

    findLookup() {
        const url = `${BaseAddress}platform/betting-types?rpp=2000&translate=true`
        return httpClient.get(url, requestHeaders, { responseType: 'json' })
    }
}

const bettingTypesLookupService = new BettingTypesLookupService();
export {
    bettingTypesLookupService
}