import { observable, action, computed, when, reaction } from 'mobx';
import { LoaderStore } from '../../../../state/stores/common';

import { ExpandableCheckBoxNodeStore as NodeStore } from './node';

/**
 * Constructor takes
 * @param {object} rootStore 
 * 
 * Store provides:
 * @param {NodeStore} menu That can be overridden with a class of same type for additional node functionality
 * @param {boolean} isLoading 
 * @param {boolean} isEmpty
 * 
 * @param {function} assignMenu Assignees raw menu response to node menu
 * @args {array} rawMenu
 * 
 * @param {function} selectFirstSportOnFirstResponse Selects first sport in menu if store is not initialized
 * If you wish to override behavior call @param {function} selectFirstSport to select first sport in menu
 * 
 * Requires overrides
 * @param {function} fetchData Override this method to implement menu fetching.
 * 
 * Optional override
 * @param {function} onBeforeInitialize Override this method with actions that should be executed before store is initialized.
 * 
 * Before starting fetching call
 * @param {function} onStartFetching To start loader 
 * 
 * When fetching is done call
 * @param {function} onDoneFetching To stop loader and compleat store initialization
 * 
 * When disposing call
 * @param {function} onBaseDispose To dispose base store reactions and to deInitialize store
 */
export class BaseOfferMenuStore {

    //#region observable

    @observable menu = new NodeStore();
    @observable isStoreInitialized = false;
    @observable isFetchingData = false;

    //#endregion observable



    //#region computed

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get isEmpty() {
        return this.menu.children.length === 0 && !this.isFetchingData && this.isStoreInitialized;
    }

    //#endregion computed



    //#region constructor

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.hub = rootStore.hub;
        this.loader = new LoaderStore();
    }

    //#endregion constructor



    //#region fetching data

    @action.bound
    onInitialize() {

        this.onStartFetching();
        this.onBeforeInitialize();

        // If we mount and action is not history back clear selected data
        if (App.state.history.action !== 'POP') {
            this.resetCheckedItems();
        }

        this.disposeWhenHubStarted();
        this.whenHubStartedDisposer = when(
            () => this.hub.isStarted,
            this.fetchData
        )
    }

    /**
     * Implement this in a subclass to perform actions before store is initialized
     */
    @action.bound
    onBeforeInitialize() {
        // To be overridden by subclass.
    }

    @action.bound
    onStartFetching() {
        this.isFetchingData = true;
        this.loader.suspend();
    }

    @action.bound
    onDoneFetching() {
        this.isStoreInitialized = true;
        this.isFetchingData = false;
        this.loader.resume();
    }

    @action.bound
    assignMenu(rawMenu) {

        const formattedMenu = rawMenu.map(sportLevel => ({
            n: { ...sportLevel.sport, count: sportLevel.count, type: "sport" },
            c: sportLevel.subItems.map(categoryLevel => ({
                n: { ...categoryLevel.sportCategory, count: categoryLevel.count, type: "category" },
                c: categoryLevel.subItems.map(tournamentLevel => ({
                    n: {
                        ...tournamentLevel.tournament,
                        id: tournamentLevel.tournament.abrv === 'special-offer' ? 'special' : tournamentLevel.tournament.id,
                        count: tournamentLevel.count,
                        type: "tournament"
                    },
                }))
            }))
        }))

        this.menu.assignStructure({ n: { isRoot: true }, c: formattedMenu });
    }

    @action.bound
    selectFirstSportOnFirstResponse() {
        if (
            !this.isStoreInitialized &&             // Do this only once on first data load
            this.menu.checkState === 0 &&           // IF menu has nothing selected select first child
            // Else there is previous selection

            this.menu?.children?.length > 0         // If menu has no children there is nothing to select
        ) {
            this.selectFirstSport();
        }
    }

    @action.bound
    selectFirstSport() {
        this.selectedSport = this.menu?.children?.[0];
        this.selectedSport.onCheck(1);
    }

    //#endregion fetching data



    //#region actions

    @action.bound
    resetCheckedItems() {
        this.menu.onCheck(0);
    }

    //#endregion actions



    //#region disposers

    @action.bound
    onBaseDispose() {
        this.disposeWhenHubStarted();

        this.isStoreInitialized = false;
    }

    @action.bound
    disposeWhenHubStarted() {
        if (this.whenHubStartedDisposer != null) {
            this.whenHubStartedDisposer();
            this.whenHubStartedDisposer = null;
        }
    }

    //#endregion disposers



    //#region reset menu on url segments

    /**
     * @param {string} segments menu check state encoded in url form
     * 
     * Sets menu check state based on ids in segments
     */
    @action.bound
    resetMenuOnUrlParamsFromRouter(segments) {

        if (segments != null) {
            this.selectInMenuFromUrlSegments(segments);
        }
    }

    @action.bound
    selectInMenuFromUrlSegments(segments) {

        if (segments == null) {
            return;
        }

        const formattedSegments = this.formatUrlSegments(segments);

        if (formattedSegments != null) {
            this.selectInMenuFromFormattedSegments(formattedSegments);
        }
    }

    @action.bound
    selectInMenuFromFormattedSegments(formattedSegments) {

        if (formattedSegments == null) {
            return;
        }

        this.menu.resetCheckState(formattedSegments);
    }

    formatUrlSegments(segments) {
        if (segments == null) {
            return;
        }

        const formattedSegments = segments
            .split('/')
            .map(sportSegment => {

                const sport = {};
                const sportSegments = sportSegment.split('|');

                sport.id = sportSegments.shift();

                sport.children = sportSegments.map(catSegment => {

                    const category = {};
                    const catSegments = catSegment.split('-');

                    category.id = catSegments.shift();

                    category.children = catSegments.map(tSegment => ({
                        id: tSegment,
                    }))

                    if (category.children.length === 0) {
                        category.children = null;
                    }
                    return category;
                })

                if (sport.children.length === 0) {
                    sport.children = null;
                }
                return sport
            });

        return { id: 'root', children: formattedSegments }
    }

    //#endregion reset menu on url segments
}