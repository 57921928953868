import {
    action,
    computed,
    observable
} from 'mobx';
import { LoaderStore } from '../../../../state/stores/common';

import {
    HighlightsViewStore,
    LastMinuteViewStore,
} from './components';

const logger = App.state.logger;

class HomeViewStore {
    @computed get isEmpty() {
        return (
            this.highlightsViewStore.isEmpty &&
            this.lastMinuteViewStore.isEmpty
        );
    }

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @observable isInitialize = true;

    constructor(rootStore) {
        this.highlightsViewStore = new HighlightsViewStore(rootStore);
        this.lastMinuteViewStore = new LastMinuteViewStore(rootStore);
        this.loader = new LoaderStore();
    }

    @action.bound
    async onInitialize() {
        this.loader.suspend();
        this.isInitialize = true;

        logger.logTrace('Initialize home view store');

        await this.highlightsViewStore.onHighlightsInitialize();
        await this.lastMinuteViewStore.onLastMinuteOfferInitialize({
            // exclude highlight events
            excludeEventIds: this.highlightsViewStore.events.map(e => e.id)
        });
        //this.rootStore.highlightsViewStore.eventsWithOffer.events.map(event => event.id)

        this.isInitialize = false;
        this.loader.resume();
    }

    @action.bound
    onDispose() {
        this.highlightsViewStore.onDispose();
        this.lastMinuteViewStore.onDispose();

        logger.logTrace('Disposed home view store');
    }
}

export default HomeViewStore;