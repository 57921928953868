import { BettingActivityService as BettingActivityDataService } from "../../../../../administration/services/account-settings/betting-activity";
import { ErrorResponse, Response, UserDataService } from "../../../../../administration/services/common";



export class BettingActivityService {
    constructor() {
        this.bettingActivityDataService = new BettingActivityDataService();
        this.userDataService = new UserDataService();
    }

    async getBettingActivityData() {
        try {
            const filter = {
                Filter: {
                    AccessChannels: {
                        Agencies: null,
                        Shops: [
                            {
                                AgencyId: App.utils.getAgencyId(),
                                Id: App.utils.getShopId()
                            }
                        ]
                    },
                    Parameters: [
                        {
                            Column: 'StartDate',
                            Value: "30"
                        }
                    ]
                },
                Paging: null
            }

            const userActivityResponse = await this.bettingActivityDataService.fetchUserAccountActivity(filter);
            const bettingActivityResponse = await this.bettingActivityDataService.fetchUserBettingActivity(filter);

            let userActivity = null;
            let bettingActivity = null;

            if (userActivityResponse.result != null && userActivityResponse.result.length > 0) {
                const activity = userActivityResponse.result[0];
                if (activity.NumberOfTransactions > 0) {
                    userActivity = userActivityResponse.result;
                }
            }

            if (bettingActivityResponse.result != null && bettingActivityResponse.result.length > 0) {
                const activity = bettingActivityResponse.result[0];
                if (activity.NumberOfBets > 0) {
                    bettingActivity = bettingActivityResponse.result;
                }
            }

            return new Response({
                userActivity: userActivity,
                bettingActivity: bettingActivity
            });
        } catch (error) {
            throw new ErrorResponse(error);
        }
    }


    async updateBettingActivityReview() {
        try {
            await this.bettingActivityDataService.updateBettingActivityReview();
        }
        catch (error) {
            throw new ErrorResponse(error);
        }
    }
}