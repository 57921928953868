import { observable, action, computed } from "mobx";

export class PrematchPeriodSubMenuStore {


    //#region constructor

    constructor(parentMenu) {
        this.parentMenu = parentMenu;
        this.rootStore = this.parentMenu.rootStore;
    }

    //#endregion constructor



    //#region period menu structure

    periodMenu = [
        {
            period: 'today',
            name: 'PREMATCH.SIDEMENU.PERIOD.TODAY',
        },
        {
            period: '3h',
            name: 'PREMATCH.SIDEMENU.PERIOD.3H',
        },
        {
            period: '48h',
            name: 'PREMATCH.SIDEMENU.PERIOD.48H',
        },
        {
            period: '3d',
            name: 'PREMATCH.SIDEMENU.PERIOD.3D',
        },
        {
            period: 'full',
            name: 'PREMATCH.SIDEMENU.PERIOD.FULL',
        }
    ];

    //#endregion period menu structure



    //#region observable

    @observable isPeriodMenuExpanded = false;
    @observable selectedPeriodKey = 'full';

    @computed get selectedPeriod() {
        return this.periodMenu.find(i => i.period === this.selectedPeriodKey) || { period: '', name: '' };
    }

    //#endregion observable



    //#region period menu setters

    @action.bound
    setPeriodKey(period) {
        const prevPeriod = this.selectedPeriodKey;

        if (this.selectedPeriodKey !== period) {

            if (this.periodMenu.find(item => item.period === period) == null) {
                // Period is not valid
                return;
            }
            this.selectedPeriodKey = period;

            this.parentMenu.fetchData();
            this.parentMenu.openOfferMenu();
        }
    }

    //#endregion period menu setters



    //#region menu actions

    @action.bound
    togglePeriodMenu() {
        if (this.isPeriodMenuExpanded) {
            this.closePeriodMenu();
        } else {
            this.expandPeriodMenu();
        }
    }

    @action.bound
    closePeriodMenu() {
        this.isPeriodMenuExpanded = false;
        this.rootStore.closeSideMenu();
    }

    @action.bound
    expandPeriodMenu() {
        this.isPeriodMenuExpanded = true;
        this.parentMenu.isOfferMenuOpen = false;
        this.rootStore.openSideMenu();
    }

    //#endregion menu actions
}