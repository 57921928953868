import {
	BettingOfferManagementHub,
	BettingOfferManagementHubOptions,
	CashoutHub,
	CashoutHubOptions,
	HubConnectionFactory,
	HubConnectionParametersFactory,
	IConnection
} from '@gp/hub';
import { reaction } from 'mobx';

export class WebHub {
	/**
	 * @type {IConnection}
	 */
	connection;

	constructor() {
		const connParamsFactory = new HubConnectionParametersFactory({
			shopId: App.utils.getShopId(),
			agency: App.utils.getAgencyKey(),
			locale: App.utils.getCurrentLanguage()
			// version: 1
		});

		const connectionFactory = new HubConnectionFactory(connParamsFactory, {
			serverUrl: BaseProxyAddress + 'sync',
			enableLogging: false,
		});

		this.connection = connectionFactory.createConnection();

		this._initializeBettingOfferHub()

		// if (IsJacktimeEnabled) {
		// 	this._initializeCashoutHub();
		// }

		this.disconnectReaction = reaction(
			() => App.state.rootStore.shouldDisconnect, 
			disconnectionFlag => {
				if (disconnectionFlag) {
					this.connection.disconnect();
					App.state.rootStore.resetDisconnect();
				}
			},
			{
                fireImmediately: true,
            }
		);
		// $(document).bind('status.sessionExpired', () => {
		// 	this.connection.disconnect();
		// });
	}

	/**
	 * Connects to the SignalR
	 */
	async connect() {
		await this.connection.connect();
	}

	/**
	 * Kills connection
	 */
	destroy() {
		this.connection.disconnect();
	}

	/**
	 * @private
	 * 
	 * Initializes betting offer hub
	 */
	_initializeBettingOfferHub() {
		const options = new BettingOfferManagementHubOptions();
		this.offerHub = new BettingOfferManagementHub(options, this.connection)
		this.offerHub.initialize();
	}

	/**
	 * @private
	 * 
	 * Initializes cashout offer hub
	 */
	// _initializeCashoutHub() {
	// 	const options = new CashoutHubOptions();
	// 	this.cashoutHub = new CashoutHub(options, this.connection);
	// 	this.cashoutHub.initialize();
	// }
}