export class CountryLookupModel {
    constructor(country) {
        this.id = country.id,
            this.name = country.name
    }
}

export class Response {
    constructor(data = null) {
        this.success = true;
        this.data = data;
    }
}

export class ErrorResponse {
    constructor(error = null) {
        this.error = error;
        this.success = false;
    }
}