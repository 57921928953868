import { observable, computed, action, runInAction, when } from 'mobx';
import moment from 'moment';

import { PrematchOfferStore } from '@gp/offer';

import AdditionalOfferStore from '../../AdditionalOfferStore';
import { BettingTypeSelectorsStore } from '../../components/selectors';
import { ExpandableCheckBoxNodeStore, CheckStateEnum } from '../../components/offer-menu/node';
import { LoaderStore } from '../../../../state/stores/common';

class NodeStore extends ExpandableCheckBoxNodeStore {

    @action.bound
    resetCheckState(nodeCheckState) {
        if (nodeCheckState == null) {
            this.onCheck(0);
            this.onShadowCheck(0);
            return;
        }

        if (nodeCheckState.children == null) {
            this.onCheck(1);
            this.onShadowCheck(1);
            return;
        }

        for (const child of this.children) {

            const childCheckState = nodeCheckState.children.find(ch => ch.id === child.node.id);

            child.resetCheckState(childCheckState);
        }
    }

    // Override shadow sync actions
    //#region sync actions 

    @action.bound
    setShadow() {
        this.shadowChecked = this.checked;
        if (!this.isLeaf) {
            for (const child of this.children) {
                child.setShadow();
            }
        }

        if (this.parent?.node?.isRoot) {

            if (this.parent.selectedShadowSubStructure.length > 1) {
                this.parent.selectedShadowSubStructure.forEach(child => child.shadowCollapse());
            } else {
                this.parent.selectedShadowSubStructure?.[0]?.shadowExpand();
            }
        }
    }

    //#endregion sync actions
}

const PeriodConverter = App.state.PeriodConverter;
const logger = App.state.logger;

/**
 * @param {NodeStore} menu Node tree store that contains sport offer menu, this is from prematch offer menu
 * @param {boolean} isEmpty is sports page offer empty
 * @param {boolean} isLoading is sports page offer loading 
 * @param {function selectTournament(node)} selectTournament Select tournament from sports page and open it.
 * 
 * ^ these params must match sports viewStore for mobile as same template is displaying data
 */
class SportsPromoOfferViewStore extends PrematchOfferStore {

    //#region observable

    @observable selectedTournamentIds = [];

    @observable isInitialize = true;
    @observable menu = new NodeStore();

    //#endregion observable



    //#region computed

    @computed get isEmpty() {
        return !this.isInitialize && this.menu.selectedShadowSubStructure.length === 0;
    }

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get topTournamentsList() {
        return App.offer.rootStore.promoOfferViewStore.topTournaments;
    }

    @computed get totalTournamentCount() {
        return this.selectedList.length;
    }

    //#endregion computed



    //#region constructor

    constructor(rootStore) {
        super(rootStore, {
            storageProvider: App.state.localStorageProvider,
            logger: logger
        });

        this.rootStore = rootStore;
        this.loader = new LoaderStore();

        this.additionalOfferStore = new AdditionalOfferStore(rootStore);
        this.bettingTypeSelectorsStore = new BettingTypeSelectorsStore();

        this.pageSize = 10000;
    }

    //#endregion constructor



    //#region fetching data

    /** Get data for selected tournament */
    @action.bound
    async fetchTopTournamentData(topTournamentId) {
        await when(() => this.topTournamentsList.length > 0);

        this.loader.suspend();
        //this.isInitialize = true;
        const topTournament = this.topTournamentsList.find(tt => tt.id === topTournamentId);
        const tournamentIds = (topTournament?.tournamentIds || []);
        runInAction(() => this.selectedTournamentIds = tournamentIds)

        const filters = [];

        // We only need tournament structure hear, not offer
        // This results in about 15 times less data transported. 
        filters.push({
            tournamentId: {
                eq: tournamentIds
            },
            liveStatus: 0,
            offers: null,
        });

        try {
            let offerResponse = await this.rootStore.hub.getOffer({
                paging: {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize
                },
                filter: filters,
                grouping: 'tournamentGroup'
            });

            runInAction(() => {
                this.assignOfferData(offerResponse);

                this.isInitialize = false;
                this.loader.resume();
            });
        }
        catch (err) {
            logger.logError(err);

            runInAction(() => {
                this.isInitialize = false;
                this.loader.resume();
            });
        }
    }

    @action.bound
    assignOfferData(data) {
        this.menu.onDispose();
        const formattedStructure = this.formatTopTournamentDataForMenu(data);
        this.menu.assignStructure(formattedStructure)
        this.menu.onShadowCheck(1);
        this.menu.children.forEach(c => c.shadowExpand());
    }

    formatTopTournamentDataForMenu(data) {

        const formattedStructure = [];

        for (const [key, sp] of Object.entries(data?.lookup?.sports)) {
            formattedStructure.push({
                n: {
                    id: sp.id,
                    abrv: sp.abrv,
                    name: sp.name,
                },
                c: [],
            });
        };

        data?.items.forEach(i => {
            /* Categories. */
            const catLookup = data?.lookup?.categories[i.categoryId];
            const catNode = {
                n: {
                    id: catLookup.id,
                    abrv: catLookup.abrv,
                    name: catLookup.name,
                },
                c: [],
            };

            i.items.forEach(t => {

                if (t.isSpecial) {
                    t.events.forEach(e => {

                        const tId = e.event.tournamentId;

                        if (catNode.c.find(j => j.n.id === tId) != null) {
                            return;
                        }

                        const tLookup = data?.lookup?.tournaments[tId];

                        catNode.c.push({
                            n: {
                                id: tLookup.id,
                                abrv: tLookup.abrv,
                                name: tLookup.name,
                                isOutright: true,
                            }
                        })
                    })

                } else {

                    if (catNode.c.find(j => j.n.id === t.tournamentId) != null) {
                        return;
                    }
                    const tLookup = data?.lookup?.tournaments[t.tournamentId];
                    const tNode = {
                        n: {
                            id: tLookup.id,
                            abrv: tLookup.abrv,
                            name: tLookup.name,
                        },
                    }
                    catNode.c.push(tNode);
                }



            })

            formattedStructure.find(sp => sp.n.id === i.sportId).c.push(catNode);
        });

        return { n: { isRoot: true, }, c: formattedStructure };
    }

    //#endregion fetching data



    //#region disposers

    @action.bound
    onDispose() {

        this.disposeSubscription();
        this.onReset();
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    //#endregion disposers



    //#region  actions

    @action.bound
    setSelectedTournaments(tournaments) {
        this.selectedTournaments = tournaments;
    }

    @action.bound
    selectTournament(tournamentNode) {
        // Dummy: we don't need this here as we don't have an actual menu to update
    }

    //#region actions
}

export default SportsPromoOfferViewStore;