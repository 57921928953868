import { action, observable } from 'mobx'

class BasePopupStore {
    @observable isActive = false;
    timerId = null;

    @action.bound
    activatePopup() {
        this.isActive = true;
    }

    @action.bound
    deactivatePopup() {
        this.isActive = false;
    }

    clearTimer() {
        if (this.timerId != null) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }
}

export default BasePopupStore;