import { action } from "mobx";
import { UserDataService } from "../../../administration/services/common";
import BettingActivityStore from "./BettingActivityStore";
import { AuthMessageHandler } from "../../services/common";
import BaseUserAuthStore from "./BaseUserAuthStore";
class UserAuthStore extends BaseUserAuthStore {
	constructor(rootStore) {
		super();
		this.rootStore = rootStore;
		this.userDataService = new UserDataService();
		this.bettingActivityStore = new BettingActivityStore();
		this.initialize();
	}

	/* initialize user data  */
	@action.bound
	initialize() {
		const user = JSON.parse(localStorage.getItem("user"));
		const token = JSON.parse(localStorage.getItem("userToken"));
		const activationToken = JSON.parse(
			localStorage.getItem("accountActivation")
		);
		this.loginHistory = JSON.parse(localStorage.getItem("loginHistory"));
		this.userAccounts = JSON.parse(localStorage.getItem("userAccounts"));
		if (activationToken != null) {
			this.accountActivation = activationToken;
			if (this.accountActivation.activationType === 0) {
				this.shouldDisplayAccountActivationAfterLogin = true;
			} else {
				this.shouldDisplayForcePasswordChangeAfterLogin = true;
			}
		}

		if (user != null) {
			// close popups after login
			if (!user.accountLockInfo.viewed) {
				user.accountLockInfo.viewed = true;
			}

			if (typeof user.isAlreadyLoggedIn === "object") {
				user.isAlreadyLoggedIn.viewed = true;
			}

			if (!user.bettingTimeoutInfo.viewed) {
				user.bettingTimeoutInfo.viewed = true;
			}
			this.setUser(user);
			this.token = token;
		}

		this.authMsgHandler = new AuthMessageHandler();

		this.authMsgHandler.onInit();
	}
	@action.bound
	resolveAccountLockAfterLogin() {
		this.user.accountLockInfo.viewed = true;
		localStorage.setItem("user", JSON.stringify(this.user));
	}

	@action.bound
	resolveBettingTimeoutAfterLogin() {
		this.user.bettingTimeoutInfo.viewed = true;
		localStorage.setItem("user", JSON.stringify(this.user));
	}

	@action.bound
	resolveAlreadyLoggedInPopup() {
		this.user.isAlreadyLoggedIn.viewed = true;
		localStorage.setItem("user", JSON.stringify(this.user));
	}

	@action.bound
	setUser(user) {
		if (user == null) {
			this.user = user;
			localStorage.setItem("user", JSON.stringify(user));
		} else {
			if (user?.bettingTimeoutInfo?.viewed == null) {
				user.bettingTimeoutInfo = {};
				user.bettingTimeoutInfo.viewed = false;
			}
			if (user?.accountLockInfo?.viewed == null) {
				user.accountLockInfo = {};
				user.accountLockInfo.viewed = false;
			}
			this.user = user;
			localStorage.setItem("user", JSON.stringify(user));
		}
	}

	@action.bound
	login(userData) {
		const { user, token, accountActivation, loginHistory, userAccounts } =
			userData;
		if (accountActivation == null && user.isAlreadyLoggedIn) {
			user.isAlreadyLoggedIn = {
				isAlreadyLoggedIn: user.isAlreadyLoggedIn,
				viewed: false,
			};
		}

		this.setToken(token);
		this.setUser(user);
		this.setAuthenticationToken(accountActivation);
		this.setLoginHistory(loginHistory);
		this.setUserAccounts(userAccounts);
		this.setLastTimeChangedTokenExpiration();

		//TODO
		if (user) {
			this.resolveConnection();

			const { autoLogoutPeriod, depositLimitUpdate } = user;

			if (autoLogoutPeriod != null && autoLogoutPeriod > 0) {
				const currentDate = new Date();
				const autoLogoutPeriodDuration =
					currentDate.getTime() + autoLogoutPeriod * 60 * 60 * 1000;
				localStorage.setItem(
					"auto-logout-period",
					JSON.stringify(autoLogoutPeriodDuration)
				);
			}

			if (IsRealityCheckEnabled) {
				const currentDate = new Date();
				const realityCheck =
					currentDate.getTime() + RealityCheckInterval * 60 * 1000;
				localStorage.setItem(
					"reality-check-expiration",
					JSON.stringify(realityCheck)
				);
			}

			this.rootStore.initClick();
		}
	}
}

export default UserAuthStore;
