const regex = new RegExp('{(.*)}');

String.prototype.pluralize = function(count) {
    const match = regex.exec(this);

    if(match != null) {
        const split = match[1].split('|');

        let replacement = '';
        if(count < 2) {
            if(split.length > 1) {
                replacement = split[0];
            }
        }
        else {
            if(split.length < 2) {
                replacement = split[0] || '';
            }
            else {
                replacement = split[1];
            }
        }

        return this.replace(regex, replacement);
    }

    return this;
}