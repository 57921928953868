
import { EventType } from '@gp/models';
import { NameProvider } from '@gp/utility';

export function getTip(offer) {
    if (offer.sportData.eventType != EventType.NORMAL) {
        if (offer.playerId != null) {
            return `${offer.playerLastName}, ${offer.playerFirstName}`;
        } else if (offer.teamId != null) {
            return offer.teamName;
        }
        return offer.bettingTypeTip;
    }
    const nameProvider = new NameProvider();
    const offerSpecifier = getOfferSpecifier(offer);
    const bettingTypeTip = nameProvider.getName(offer.bettingTypeTip, offerSpecifier);
    return bettingTypeTip;
}

export function getType(offer) {
    const nameProvider = new NameProvider();
    const offerSpecifier = getOfferSpecifier(offer);
    const bettingType = nameProvider.getName(offer.sportData.bettingTypeNameForBettingSlip, offerSpecifier);
    return bettingType;
}

function getOfferSpecifier(offer) {
    return {
        competitor1: offer.sportData.teamOneName,
        competitor2: offer.sportData.teamTwoName,
        ...offer.specifier
    };
}