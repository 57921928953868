import { observable, computed, action, runInAction } from 'mobx';
import { merge } from 'lodash';
import moment from 'moment';

import { EventType, LiveStatus } from '@gp/models';
import { OfferMapper, PrematchOfferStore } from '@gp/offer';
import { ConsoleLogger } from '@gp/utility';

import AdditionalOfferStore from '../../AdditionalOfferStore';
import { BettingTypeSelectorsStore } from '../../components/selectors';
import { parsePageNumberToValid } from '../../../../ui/common/utils';
import { LoaderStore } from '../../../../state/stores/common';

const PeriodConverter = App.state.PeriodConverter;
const logger = App.state.logger;

const DefaultConfig = {
    columns: []
};

class BaseSearchStore extends PrematchOfferStore {
    config = DefaultConfig;
    /***********************************************************************************
    *
    * Title: Observables
    *
    ***********************************************************************************/

    @observable isFetchingData = false;

    /***********************************************************************************
    *
    * Title: Computed
    *
    ***********************************************************************************/


    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get isEmpty() {
        /** Override is empty */
        return this.eventsMap.size === 0 && !this.isFetchingData;
    }

    @computed get searchElementsCount() {
        return this.eventsMap.size;
    }

    /***********************************************************************************
    *
    * Title: constructor
    *
    ***********************************************************************************/



    constructor(rootStore, config = undefined) {
        super({
            logger: new ConsoleLogger(false),
            removeDelay: 10,
            throttle: 4,
            enableThrottling: true,
            bettingTypeSelectorsStore: new BettingTypeSelectorsStore()
        });

        if (config != null) {
            this.config = merge({}, DefaultConfig, config);
        }

        this.rootStore = rootStore;
        this.loader = new LoaderStore();

        this.additionalOfferStore = new AdditionalOfferStore(rootStore);

        this.pageNumber = 1;
        this.pageSize = 1000;
    }

    @action.bound
    async onInitialize(pageNumber) {

        this.loader.suspend();
        this.disposeSubscription();

        await this.fetchPrematchOffer(pageNumber);

        if (this.lookupsStore.tournaments.size > 0) {
            // Live subscription will suspend loader when it receives data
            this.subscribeToLiveEvents();
        } else {
            runInAction(() => {
                this.loader.resume();
                this.isFetchingData = false;
            })
        }

    }

    @action.bound
    async fetchPrematchOffer(pageNumber) {
        this.pageNumber = parsePageNumberToValid(pageNumber);
        App.state.updateUrlParams({ page: this.pageNumber });

        let offerResponse = null;
        try {
            offerResponse = await this.rootStore.hub.getOffer(this.getOfferRequest());
        } catch (err) {
            logger.logError(err);

            runInAction(() => {
                this.loader.resume();
                this.isFetchingData = false;
            });
            return;
        }
        runInAction(() => {
            this.assignOfferData(offerResponse);
        })
    }

    @action.bound
    subscribeToLiveEvents() {
        // add live subscription
        this.subscription = this.rootStore.hub.getOfferSubscription(this.getLiveSubscriptionRequest())
            .subscribe(response => {
                runInAction(() => {
                    this.assignLiveData(response, true);
                    this.loader.resume();
                    this.isFetchingData = false;
                });
            }, err => {
                console.error(err);
                runInAction(() => {
                    this.loader.resume();
                    this.isFetchingData = false;
                });
            }
            );
    }

    /***********************************************************************************
    *
    * Section: Search filter
    *
    ***********************************************************************************/


    /** Pageable request. */
    getOfferRequest() {
        return {
            paging: {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            },
            filter: this.generateOfferFilter(),
            grouping: 'tournamentGroup'
        }
    }

    generateOfferFilter() {

        const searchFilter = this.rootStore.searchBarViewStore.searchFilterArray;

        let filters = [
            ...this.generateFilterForTeam(0),    // Team one 
            ...this.generateFilterForTeam(1),    // Team two

            {
                ...searchFilter[2], // Event name
                liveStatus: LiveStatus.PREMATCH,
                eventType: {
                    eq: [EventType.SHORT_TERM, EventType.LONG_TERM]
                }
            },
        ];

        return filters;
    }

    generateFilterForTeam(teamIdx) {

        const prematchBettingTypes = OfferMapper.getBettingTypes('prematch', this.config.columns);
        const liveBettingTypes = OfferMapper.getBettingTypes('live');

        const searchFilter = this.rootStore.searchBarViewStore.searchFilterArray;

        return [
            /** Normal offer filter for one team. */
            {
                ...searchFilter[teamIdx],   // Team name filter
                liveStatus: LiveStatus.PREMATCH,
                eventType: EventType.NORMAL,
                offers: [
                    {
                        bettingType: {
                            abrv: {
                                eq: prematchBettingTypes.normal
                            }
                        }
                    },
                    {
                        bettingType: {
                            abrv: {
                                eq: prematchBettingTypes.marginal
                            }
                        },
                        isFavorite: true,
                    }
                ]
            },
            /** Live offer filter for one team. */
            {
                ...searchFilter[teamIdx], // Team name filter
                liveStatus: LiveStatus.LIVE,
                eventType: EventType.NORMAL,
                offers: [
                    {
                        bettingType: {
                            abrv: {
                                eq: liveBettingTypes.normal
                            }
                        }
                    },
                    {
                        bettingType: {
                            abrv: {
                                eq: liveBettingTypes.marginal
                            }
                        },
                        isFavorite: true,
                    }
                ]
            }
        ]
    }

    /** Live request. */
    getLiveSubscriptionRequest() {

        const liveBettingTypes = OfferMapper.getBettingTypes('live');
        const searchFilter = this.rootStore.searchBarViewStore.searchFilterArray;

        return {
            subscriptionId: 'search-' + this.rootStore.searchBarViewStore.searchTerm,
            // compress: true,
            compress: false,
            channels: [
                {
                    name: 'event',
                    filter: searchFilter,

                },
                {
                    name: 'betOffer',
                    filter: [
                        {
                            bettingType: {
                                abrv: {
                                    eq: liveBettingTypes.normal
                                }
                            },
                        },
                        {
                            bettingType: {
                                abrv: {
                                    eq: liveBettingTypes.marginal
                                }
                            },
                            isFavorite: true
                        }
                    ]
                }
            ],
        };
    }

    /***********************************************************************************
    *
    * Section: Disposers
    *
    ***********************************************************************************/



    @action.bound
    onDispose() {
        this.disposeSubscription();
        this.onReset();
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    onReset() {
        this.loader.suspend();
        this.isFetchingData = false;

        this.additionalOfferStore.onDispose();
        this.configuration.bettingTypeSelectorsStore.reset();

        this.reset();
    }
}

export default BaseSearchStore;
