import { observable, computed, action, runInAction, when } from 'mobx';

import {
    MainOfferStore,
    OfferMapper,
    getEventSortBySport,
    getEventSortBySportCategory,
    getEventSortByTournament,
    defaultRules
} from '@gp/offer';
import { EventType, LiveStatus } from '@gp/models';
import {
    ConsoleLogger,
    Sorter,
} from '@gp/utility';

import { RootStore } from '../../../';

import AdditionalOfferStore from '../../../AdditionalOfferStore';
import { BettingTypeSelectorsStore } from '../../../components/selectors'
import { LoaderStore } from '../../../../../state/stores/common';

const logger = App.state.logger;

const DefaultConfig = {
    columns: ["1", "2"]
};

class LiveHighlightsViewStore extends MainOfferStore {
    config = DefaultConfig;
    columns = null;

    //#region observables

    @observable isExpanded = true;
    @observable isStoreInitialized = false;
    @observable eventsWithErrors = [];
    @observable isFetchingData = false;

    //#endregion observables 



    //#region  computed 

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get isEmpty() {
        return this.eventsList.length === 0 && !this.isFetchingData && this.isStoreInitialized;
    }

    @computed get eventsList() {
        // this is to make it compatible with new ui components, so all stores use same name for events list
        return this.sortedEvents;
    }

    @computed get sortedEvents() {
        const filteredEvents = Array
            .from(this.eventsMap.values())
            .filter(event => !this.eventsWithErrors.includes(event.id))
            .sort(Sorter.sort(
                'isTop',
                (a, b) => getEventSortBySport(a, b, this.lookupsStore),
                'startTime',
                (a, b) => getEventSortBySportCategory(a, b, this.lookupsStore),
                (a, b) => getEventSortByTournament(a, b, this.lookupsStore),
                'id'
            ))
            .slice(0, 15);

        filteredEvents.forEach(e => {
            if (e.sport == null) {
                e.sport = this.getMappedSport(e);
            }
        })

        return filteredEvents;
    }

    //#endregion computed



    //#region constructor

    /**
     * @param {RootStore} rootStore
     */
    constructor(rootStore) {
        super({
            logger: new ConsoleLogger(false),
            removeDelay: 10,
            throttle: 4,
            enableThrottling: true,
            bettingTypeSelectorsStore: new BettingTypeSelectorsStore(),
            customConfiguration: {
                prematch: [{...defaultRules.category.highlights, isDefault: true}],
                live: [{...defaultRules.category.highlights, isDefault: true}]
            }
        });

        this.rootStore = rootStore;

        this.additionalOfferStore = new AdditionalOfferStore(rootStore);
        this.loader = new LoaderStore();
        this.loader.isLoading = true;
        this.loader.suspend();
    }

    //#endregion constructor



    //#region data fetching 


    @action.bound
    onInitialize() {

        this.onSubscriptionDispose();
        this.onDisposeWhenHubStarted();


        this.whenHubStartedDisposer = when(
            () => this.rootStore.hub.isStarted,
            () => this.onFetchData(),
        );
    }

    @action.bound
    onFetchData() {

        this.loader.suspend();
        this.isFetchingData = true;

        let subscriptionRequest = this.generateSubscriptionRequestObject()

        logger.log("Connecting to hub: top-live");

        this.subscription = this.rootStore.hub.getOfferSubscription(subscriptionRequest)
            .subscribe(response => {
                runInAction(() => {
                    this.assignOfferData(response)
                    this.onDoneFetching();
                });
            }, err => {
                console.error(err);
                this.onDoneFetching();
            });
    }

    generateSubscriptionRequestObject() {
        const bettingTypeConfiguration = OfferMapper.getSportBettingTypesByCategory('highlights');
        this.columns = bettingTypeConfiguration.columns;

        return {
            subscriptionId: 'top-live',
            // compress: true,
            compress: false,
            channels: [
                {
                    name: 'event',
                    filter: {
                        eventType: EventType.NORMAL, //EventType.Normal,
                        liveStatus: LiveStatus.LIVE,
                        //isTopEvent: true
                    }
                },
                {
                    name: 'betOffer',
                    filter: [
                        {
                            bettingType: {
                                abrv: {
                                    eq: bettingTypeConfiguration.filter.normal
                                }
                            }
                        },
                        {
                            bettingType: {
                                abrv: {
                                    eq: bettingTypeConfiguration.filter.marginal
                                }
                            },
                            isFavorite: true
                        }
                    ]
                }
            ],
        }
    }

    @action.bound
    onDoneFetching() {
        this.loader.resume();
        this.isFetchingData = false;
        this.isStoreInitialized = true;
    }

    //#endregion data fetching 



    //#region disposers

    @action.bound
    onDispose() {
        this.onSubscriptionDispose();
        this.onDisposeWhenHubStarted();


        this.onReset();
    }

    @action.bound
    onReset() {
        this.loader.suspend();
        this.isExpanded = true;
        this.isStoreInitialized = false;
        this.isFetchingData = false;

        this.reset();
    }

    @action.bound
    onSubscriptionDispose() {
        this.eventsWithErrors = [];
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    onDisposeWhenHubStarted() {
        if (this.whenHubStartedDisposer) {
            this.whenHubStartedDisposer();
            this.whenHubStartedDisposer = null;
        }
    }

    //#endregion disposers



    @action.bound
    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    @action.bound
    setEventIdsForSwitcher(eventId) {
        let list = this.eventsList.map(({ id }) => id);

        const eventIndex = list.indexOf(eventId);

        this.rootStore.eventSwitcherViewStore.setEventList(list, eventIndex);
    }

    @action.bound
    registerEventWithError(eventId) {
        if (this.eventsWithErrors.includes(eventId)) {
            return;
        }
        this.eventsWithErrors.push(eventId)
    }
}

export default LiveHighlightsViewStore;