const httpClient = App.utils.httpClient;

function generateRequestHeader() {
	return {
		"Content-Type": "application/json",
		Accept: "application/json",
	};
}

export class BettingActivityService {
	async fetchUserAccountActivity(filter) {
		return await httpClient.post(
			`${BaseAddress}platform/reports/AccountActivityReview`,
			JSON.stringify(filter),
			generateRequestHeader(),
			{ responseType: "json" }
		);
	}

	async fetchUserBettingActivity(filter) {
		return await httpClient.post(
			`${BaseAddress}platform/reports/BettingActivityReview`,
			JSON.stringify(filter),
			generateRequestHeader(),
			{ responseType: "json" }
		);
	}

	async updateBettingActivityReview() {
		return await httpClient.put(
			`${BaseAddress}platform/agency-users/update-last-betting-review-check-date`,
			null,
			generateRequestHeader(),
			{ responseType: "json" }
		);
	}
}
