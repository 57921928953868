import { observable, action } from "mobx";
import errorFormatterService from "../../common/ErrorFormatterService";

class ErrorStore {
    internalException = null;
    @observable error = null;
    constructor(rootStore) {
        this.rootStore = rootStore;

        this.initialize();
    }

    @action setError = (error) => {
        // NOTICE: #42175
        this.error = error;
    };


    initialize() {
        const self = this;
        window.addEventListener('unhandledrejection', (event) => {
            event.stopPropagation();
            event.preventDefault();

            const {
                reason
            } = event;

            if (typeof reason === 'object') {
                if (reason.request !== undefined && reason.statusCode !== undefined) {
                    return handleApiError(reason);
                }
            }
            console.log("error")
            this.setError(reason);
            return true;
        });

        window.addEventListener('error', (event) => {
            event.stopPropagation();
            event.preventDefault();
            // eslint-disable-next-line

            if (event.message != null && event.message.includes("ResizeObserver")) {
                return;
            }
            console.error("ERROR", event);
            self.setError(event)
            // App.state.history.push("/en/sports")
        });
    }
}

function handleApiError(reason) {
    const { data, message, statusCode, headers, config } = reason;
    if (statusCode === 401 || statusCode === 403) {
        // rootStore.authStore.syncToken();
        // if (rootStore.authStore.isAuthenticated) {
        //     return rootStore.routerStore.goTo('unauthorized');
        // } else {
        //     return rootStore.navigateLogin();
        // }
        console.log("unauthorized")
    }

    let error = errorFormatterService.getErrorObject(data, message, statusCode, headers, config);

    this.setError(error);
}

export default ErrorStore;