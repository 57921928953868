import { observable, action, computed } from 'mobx';
import { NodeStore, CheckStateEnum } from './';


export class CheckBoxNodeStore extends NodeStore {

    /** @returns {string} url segments */
    @computed get urlStructure() {

        let result = '';

        if (this.checkState === CheckStateEnum.unchecked) {
            return '';
        }

        switch (this.node.type) {
            case "sport":
                result += `/${this.node.id}`;
                break;
            case "category":
                result += `|${this.node.id}`;
                break;
            case "tournament":
                result += `-${this.node.id}`;
                break
            default:
            // Should not happen

        }

        if(this.checkState === CheckStateEnum.indeterminate) {
            result += this.children.reduce((acc, child) => acc += child.urlStructure, '');
        }

        return result;
    }


    //#region  observable

    @observable checked = false;

    //#endregion observable



    //#region constructor

    constructor() {
        super(...arguments);

        if (this.parent != null && !this.parent.isRoot) {
            this.checked = this.parent.checkState === CheckStateEnum.checked;
        }
    }

    //#endregion constructor



    //#region computed

    @computed get leafNodeIds() {

        if (this.isLeaf) {
            let id = this.node.id;
            if(id==='special') {
                id = this.parent.node.id + "-" + id;
            }
            return [id];
        }

        return this.children.reduce((acc, child) => {
            acc.push(...child.leafNodeIds);
            return acc;
        }, []);
    }

    @computed get checkedLeafNodeIds() {

        if (this.isLeaf) {
            if (this.checkState > 0) {
                return [this.node.id];
            } else {
                return [];
            }
        }

        return this.children.reduce((acc, child) => {
            acc.push(...child.checkedLeafNodeIds);
            return acc;
        }, [])
    }

    /**
     * 0 - node is leaf and is not checked, node is not leaf and some or all of its children are checked (not checked)
     * 1 - node is leaf and is checked, node is not leaf and all of its children are checked (checked)
     * 2 - node is not leaf and some of its children are checked (indeterminate state)
     * @returns {0|1|2}
     */
    @computed get checkState() {
        if (this._initialChecked) {
            return this._initialChecked ? CheckStateEnum.checked : CheckStateEnum.unchecked;
        }

        if (this.isLeaf) {
            return this.checked ? CheckStateEnum.checked : CheckStateEnum.unchecked;
        }

        if (this.isEveryChildChecked) {
            return CheckStateEnum.checked;
        }

        if (this.isSomeChildChecked) {
            return CheckStateEnum.indeterminate;
        }

        return CheckStateEnum.unchecked;
    }

    /**
     * @returns {boolean} True if every child node is checked
     */
    @computed get isEveryChildChecked() {
        return this.children.every(c => c.checkState === 1);
    }

    /**
     * @returns {boolean} True if atleast one child node is checked
     */
    @computed get isSomeChildChecked() {
        return this.children.some(c => c.checkState > 0);
    }

    @computed get TotalLeafNodeCount() {

        if (this.isLeaf) {
            return 1;
        }

        return this.children.reduce((acc, child) => {
            return acc += child.TotalLeafNodeCount;
        }, 0);
    }

    //#endregion computed



    //#region node actions

    /**
     * Set check state
     * @param {boolean} value
     */
    @action.bound
    onCheck(value) {

        // node checked, we are not toggling
        if (this.isLeaf) {
            this.checked = value;
            return;
        }

        this.children.forEach(child => {
            child.onCheck(value);
        });
    }

    @action.bound
    onToggleCheck() {
        if (
            this.checkState === 0 ||
            this.checkState === 2
        ) {
            this.onCheck(true);
        } else {
            this.onCheck(false);
        }

    }

    /** 
     * @param {object} nodeCheckState has matching structure to node store.
     * It is used to set check states of nodes based on it.
     * */
    @action.bound
    resetCheckState(nodeCheckState) {
        if (nodeCheckState == null) {
            this.onCheck(0);
            return;
        }

        if (nodeCheckState.children == null) {
            this.onCheck(1);
            return;
        }

        for (const child of this.children) {

            const childCheckState = nodeCheckState.children.find(ch => ch.id === child.node.id);

            child.resetCheckState(childCheckState);
        }
    }

    //#endregion node actions

}