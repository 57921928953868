import { action, computed, observable, runInAction } from 'mobx';
import { LoaderStore } from '../../../../state/stores/common';

import { HighlightsViewStore, LastMinuteViewStore } from '../home/components';
const logger = App.state.logger;

class SportsLandingViewStore {

    @observable isInitialize = true;
    @observable isStoreInitialized = false;

    @computed get isEmpty() {
        return (
            this.highlightsViewStore.isEmpty &&
            this.lastMinuteViewStore.isEmpty
        );
    }

    @computed get isLoading() {
        return this.loader.isLoading
    }

    constructor(rootStore) {
        this.highlightsViewStore = new HighlightsViewStore(rootStore);
        this.lastMinuteViewStore = new LastMinuteViewStore(rootStore);
        this.loader = new LoaderStore();
    }

    @action.bound
    async onInitialize() {
        this.isInitialize = true;
        this.loader.suspend();

        const params = new URLSearchParams(window.location.search);
        params.delete('page');
        const finalParams = params.toString();
        App.state.redirect(`${window.location.pathname}${finalParams !== '' ? '?' + finalParams : ''}`, true);

        logger.logTrace('Initialize sports landing view store.')

        await this.highlightsViewStore.onHighlightsInitialize();
        await this.lastMinuteViewStore.onLastMinuteOfferInitialize({
            // exclude highlight events
            excludeEventIds: this.highlightsViewStore.events.map(e => e.id)
        });

        this.loader.resume();
        runInAction(() => {
            this.isInitialize = false;
            this.isStoreInitialized = true;
        })
    }

    @action.bound
    onDispose() {
        // TODO: see if we need dispose
        this.highlightsViewStore.onDispose();
        this.lastMinuteViewStore.onDispose();
        this.isStoreInitialized = false;
    }
}

export default SportsLandingViewStore;