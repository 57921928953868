import { observable, action, runInAction, computed } from 'mobx';

import { MainOfferStore } from '@gp/offer';

const DEFAULT_OPTIONS = {
    enableBettingTypeGroups: false,
    type: 'prematch',
    isLive: false,
    onClose: null
}

class GroupOfferStore extends MainOfferStore {
    @observable displayBettingGroup = 'all';

    constructor(rootStore, options = null) {
        options = Object.assign({}, DEFAULT_OPTIONS, options);
        super(rootStore, {
            enableBettingTypeGroups: options.enableBettingTypeGroups
        });

        this.rootStore = rootStore;
        this.type = options.type || 'prematch';
        this.isLive = options.isLive || false;
        this.onClose = options.onClose
    }

    @action.bound
    setDisplayBettingGroup(value) {
        if (value !== this.displayBettingGroup) {
            this.displayBettingGroup = value;
        }
    }

    @action.bound
    resetDisplayBettingGroup() {
        this.displayBettingGroup = 'all';
    }
}

export default GroupOfferStore;