import { observable, action, runInAction } from 'mobx';

class LoaderStore {
    loaderTimerId;

    @observable isLoading = false;

    @action.bound
    suspend() {
        clearTimeout(this.loaderTimerId);
        this.loaderTimerId = setTimeout(() => {
            runInAction(() => {
                this.isLoading = true;
            });
        }, 250);
    }

    @action.bound
    resume() {
        if (this.loaderTimerId) {
            clearTimeout(this.loaderTimerId);
            this.loaderTimerId = null;
        }

        this.isLoading = false;
    }
}

export default LoaderStore;