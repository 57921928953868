
const httpClient = App.utils.httpClient;

const url = '/api/administration/account-settings/personal-data';


class AccountSettingsService {
    async getUserAccountSettingsPersonalData () {
        return await httpClient.get(`${url}`);
    }
}
export default AccountSettingsService;