import { observable, action } from 'mobx';

class EventOfferGroupViewStore {
    @observable isExpanded = true;

    @action.bound
    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}

export default EventOfferGroupViewStore;