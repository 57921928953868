import { observable, action, runInAction } from 'mobx';
import { SportsMenuService } from '../../../services/components';
import { generatePrematchOfferMenuFilter } from './helpers';


import { TreeStore } from './tree'

/**
 * @typedef {Object} Node
 * @property {string} label name
 * @property {string} abrv
 * @property {string} value id
 * @property {number=} count
 * @property {boolean} checked
 * @property {Node[]=} children
 */

class PrematchMenuViewStore {
    @observable isLoading = true;

    /**
     * @type {TreeStore}
     */
    @observable menu = { nodes: [] };

    /**
     * @type {Object.<string, Node>}
     */
    @observable menuState = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.service = new SportsMenuService();
    }

    /**
     * Used to initialize prematch menu. Call on componentDidMount()
     * @param {{culture: string, period: string, sports: string[], categories: string[], tournaments: string[], specials: []}} initObject 
     */
    @action.bound
    async onInitialize(initObject) {
        this.isLoading = true;
        try {

            const filter = generatePrematchOfferMenuFilter(initObject.period);
            const result = await this.service.getPrematchMenu(filter);

            runInAction(() => {
                this.menu = new TreeStore(result, {
                    culture: initObject.culture,
                    period: initObject.period,
                    selected: {
                        sports: initObject.sports || [],
                        categories: initObject.categories || [],
                        tournaments: initObject.tournaments || [],
                        specials: initObject.specials || []
                    }
                });
            });
        }
        catch (err) {
            App.state.logger.logError("Failed to fetch sports menu", err);
        }
        finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action.bound
    uncheckAll() {
        this.menu.nodes.forEach(n => n.onCheck(false))
    }

    @action.bound
    closeAll() {
        this.menu.nodes.forEach(node => {
            node.onCheck(false);
            node.collapse();
        });
        const currentScrollPosition = document.documentElement.scrollTop;
        if (currentScrollPosition !== 0) {
            window.scrollTo(0, 0);
        }
    }

    @action.bound
    closeSport(sportId) {
        this.menu.nodes.find(el => el.node.value === sportId).onCheck(false)
    }

    @action.bound
    closeTournament(sportId, categoryId, tournamentId) {
        const sportIdx = this.menu.nodes.findIndex(s => s.node.value === sportId);

        if (sportIdx > -1) {
            const categoryIdx = this.menu.nodes[sportIdx].children.findIndex(c => c.node.value === categoryId);

            if (categoryIdx > -1) {
                const tournamentIdx = this.menu.nodes[sportIdx].children[categoryIdx].children.findIndex(t => t.node.value === tournamentId);

                if (tournamentIdx > -1) {
                    this.menu.nodes[sportIdx].children[categoryIdx].children[tournamentIdx].onCheck(false);
                }
            }
        }
        // this.menu.nodes.forEach(sport => {
        //     if (sport.node.value !== sportId || !sport.children) {
        //         return;
        //     }

        //     sport.children.forEach(category => {
        //         if (category.node.value !== categoryId || !category.children) {
        //             return;
        //         }

        //         const itemIdx = category.children.findIndex(t => t.node.value === tournamentId);
        //         if (itemIdx > -1) {
        //             category.children[itemIdx].onCheck(false);
        //         }
        //     })
        // })
    }

    @action.bound
    onDispose() {
        if (this.menu.onDispose) {
            this.menu.onDispose();
        }
    }
}

export default PrematchMenuViewStore;
