import { action, runInAction } from 'mobx';

import { EventType, LiveStatus } from '@gp/models';
import { OfferMapper } from '@gp/offer';
import BaseSearchStore from './BaseSearchStore';

class SearchViewStore extends BaseSearchStore {

    //#region  constructor

    constructor(rootStore, config = undefined) {
        super(rootStore, config);
    }

    //#endregion constructor



    //#region fetching override

    @action.bound
    async onInitialize(pageNumber) {

        runInAction(() => {
            this.isFetchingData = true;
            this.loader.suspend();
        })

        await this.fetchPrematchOffer(pageNumber);

        runInAction(() => {
            this.loader.resume();
            this.isFetchingData = false;

            this.isInitialize = false;
        })
    }

    //#endregion fetch override



    //#region filter overrides

    generateOfferFilter() {

        const searchFilter = this.rootStore.searchBarViewStore.searchFilterArray;

        let filters = [
            ...this.generateFilterForTeam(0),    // Team one 
            ...this.generateFilterForTeam(1),    // Team two

            {
                ...searchFilter[2], // Event name
                liveStatus: LiveStatus.PREMATCH,
                eventType: {
                    eq: [EventType.SHORT_TERM, EventType.LONG_TERM]
                }
            },
        ];

        return filters;
    }

    generateFilterForTeam(teamIdx) {

        const prematchBettingTypes = OfferMapper.getBettingTypes('prematch');

        const searchFilter = this.rootStore.searchBarViewStore.searchFilterArray;

        return [
            /** Normal offer filter for one team. */
            {
                ...searchFilter[teamIdx],   // Team name filter
                liveStatus: LiveStatus.PREMATCH,
                eventType: EventType.NORMAL,
                offers: [
                    {
                        bettingType: {
                            abrv: {
                                eq: prematchBettingTypes.normal
                            }
                        }
                    },
                    {
                        bettingType: {
                            abrv: {
                                eq: prematchBettingTypes.marginal
                            }
                        },
                        isFavorite: true,
                    }
                ]
            },
        ]
    }

    //#endregion filter overrides
}

export default SearchViewStore;
