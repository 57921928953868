import { observable, action, runInAction, reaction, computed } from 'mobx';

import { Sorter, insert } from '@gp/utility';

import { PrematchOfferService } from '../../../services/components';
import GroupOfferStore from '../../GroupOfferStore';
import { LoaderStore } from '../../../../state/stores/common';

const logger = App.state.logger;

class AdditionalOfferViewStore extends GroupOfferStore {

    //#region observable

    @observable eventId;
    @observable isFetchingData = false;
    @observable isStoreInitialized = false;

    @observable enableBettingTypeGroups = false;

    //#endregion observable



    //#region computed

    @computed get orderedEvents() {

        this.events.forEach(event => {

            // Add sport to event
            if (event.sport == null) {
                event.sport = this.getMappedSport(event);
            }

            // Add default betting type groups to event
            event.bettingTypeGroups = [];

            const eventKeys = this.eventKeysMap.get(event.id);

            if (eventKeys?.size > 0) {

                // Loop over all event keys and add there betting type group to event
                // Also add each unique betting type into betting type group
                const btGroups = Array.from(eventKeys.values()).
                    reduce((acc, eventKey) => {

                        //#region insert betting type group

                        if (acc.find(btGroup => btGroup.abrv === eventKey.bettingType.groupId) == null) {

                            acc.push(this.lookupsStore.bettingTypeGroups.get(eventKey.bettingType.groupId))
                        }

                        //#endregion insert betting type group

                        //#region  insert betting type into betting type group

                        const bettingGroup = acc.find(btGroup => btGroup.abrv === eventKey.bettingType.groupId);

                        if (bettingGroup.bettingTypes == null) {
                            bettingGroup.bettingTypes = [];
                        }

                        if (bettingGroup.bettingTypes.find(bt => bt.id === eventKey.bettingTypeId) == null) {

                            insert(
                                bettingGroup.bettingTypes,
                                eventKey.bettingType,
                                Sorter.sort(
                                    (a, b) => {

                                        const aSort = a?.settingsPerSport?.[event.sportId]?.sortOrder;
                                        const bSort = b?.settingsPerSport?.[event.sportId]?.sortOrder;

                                        const aSortOrder = aSort == null ? 99999 : aSort;
                                        const bSortOrder = bSort == null ? 99999 : bSort;

                                        return Math.sign(aSortOrder - bSortOrder);
                                    },
                                    'id',
                                ))
                        }


                        //#endregion insert betting type into betting type group

                        return acc
                    }, [])
                    .sort(Sorter.sort('sortOrder', 'id'));


                event.bettingTypeGroups = btGroups
            }
        });



        return this.events
            .sort((a, b) => {
                return this.eventIds.indexOf(b.id) - this.eventIds.indexOf(a.id);
            });
    }

    @computed get isEmpty() {
        return this.orderedEvents.length === 0 && !this.isFetchingData && this.isStoreInitialized;
    }

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    //#endregion computed



    //#region  constructor

    constructor(rootStore, options = null) {
        super(rootStore, options);

        if (options && options.onClose) {
            this.onClose = options.onClose
        }

        console.log("LIVE ADDITIONAL OFFER", this.isLive);

        this.loader = new LoaderStore();
        this.offerService = new PrematchOfferService();
        this.enableBettingTypeGroups = options.enableBettingTypeGroups;

    }

    //#endregion constructor



    //#region actions 

    @action.bound
    updateEventId(eventId) {
        if (this.eventId != eventId) {
            this.eventId = eventId;
            this.resetDisplayBettingGroup();
        }
        this.isLMTAvailable = true;
    }

    //#endregion actions



    //#region data fetching

    initializeAdditionalOfferStore() {
        this.rootStore.setAdditionalOfferVisible(true);

        this.disposeSubscription();

        if (this.isLive) {
            this._initializeLiveAdditionalOffer();
        }

        this._initializePrematchAdditionalOffer();


    }

    @action.bound
    _initializeLiveAdditionalOffer() {
        if (!this.rootStore.hub.isStarted) {
            return;
        }

        this.onStartFetching();

        //#region  subscription request obj

        const subscriptionId = `live-additional-offer-${this.eventId}`;

        let subscriptionRequest = {
            subscriptionId: subscriptionId,
            // compress: true,
            compress: false,
            channels: [
                {
                    name: 'event',
                    filter: {
                        id: this.eventId
                    }
                },
                {
                    name: 'betOffer',
                }
            ],
            throttle: 3
        };

        //#endregion  subscription request obj

        logger.log("Connecting to hub for live additional offer.", subscriptionId);

        // call unsubscribe() in order to dispose subscription on the application hub
        this.subscription = this.rootStore.hub.getOfferSubscription(subscriptionRequest)
            .subscribe(response => {
                const { lookups, offerChanges, startingVersion } = response;
                if (startingVersion === 0 && lookups == null && offerChanges == null) {
                    this.onDispose();
                    if (this.onClose) {
                        this.onClose();
                    }

                    return;
                }

                runInAction(() => {
                    this.assignOfferData(response);
                    this.onDoneFetching();
                });
            }, err => {
                console.error(err);
                this.onDoneFetching();
            });

        this.syncReactionDisposer = reaction(() => ({ eventsSize: this.eventsMap.size }), (change, r) => {
            if (change.eventsSize === 0) {
                // dispose reaction
                r.dispose();

                this.onDispose();

                if (this.onClose) {
                    this.onClose();
                }
            }
        });
    }

    @action.bound
    async _initializePrematchAdditionalOffer() {
        this.onStartFetching();

        logger.log("Connecting to hub for prematch additional offer.", this.eventId);

        try {
            const offer = await this.offerService.getEventAdditionalOffer({
                eventIds: [this.eventId]
            });

            this.assignOfferData(offer);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            this.onDoneFetching();
        }
    }

    @action.bound
    onStartFetching() {
        this.isFetchingData = true;
        this.loader.suspend();
    }

    @action.bound
    onDoneFetching() {

        /** If There are no events or there are no betting types ( no offer ).
         *  Close additional offer store. ( this currently only affects twd desktop as mill-v1 does not use url params) */
        if (this.events.length === 0 || this.eventKeysMap.size === 0) {
            this.rootStore.setAdditionalOfferVisible(false);

            const searchParams = new URLSearchParams(App.state.history.location.search);

            if (searchParams.has('event')) {
                searchParams.delete('event');
                App.state.redirect(App.state.history.location.pathname + '?' + searchParams.toString(), true);
            }
        }
        this.isFetchingData = false;
        this.loader.resume();
        this.isStoreInitialized = true;
    }

    //#endregion data fetching



    //#region  disposers

    @action.bound
    onDispose() {
        this.eventId = null;
        this.isStoreInitialized = false;
        this.isFetchingData = false;
        this.resetDisplayBettingGroup();

        this.disposeSubscription();

        this.rootStore.setAdditionalOfferVisible(false);
        this.reset();
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    //#endregion disposers
}

export default AdditionalOfferViewStore;