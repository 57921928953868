import { DateTime } from "luxon";
import { observable, action, computed } from "mobx";
import { UserAccountType } from "../../../ui/common/constants";
import { HubConnectionFactory, HubConnectionParametersFactory } from "@gp/hub";

class BaseUserAuthStore {
	@observable user = null;
	@observable token = null;
	@observable accountActivation = null;
	@observable userAccounts = null;
	@observable loginHistory = null;
	@observable activeAccountTypeId = localStorage.getItem("acc") || null;
	@observable shouldDisplayBettingActivityAfterLogin = false;
	@observable shouldDisplayAccountActivationAfterLogin = false;
	@observable shouldDisplayAcceptNewTermsAfterLogin = false;
	@observable shouldDisplayAfterLogin = false;
	@observable lastTimeChangedTokenExpiration = null;
	@observable shouldDisplayForcePasswordChangeAfterLogin = false;
	authMsgHandler = null;

	@computed get isLogoutDisabled() {
		return (
			App.offer.rootStore.myBetsViewStore?.isCountdownVisible ||
			App.offer.rootStore.betSlipStore?.isCountdownVisible
		);
	}

	@action.bound
	syncToken() {
		if (this.user != null) {
			const currentDateTime = DateTime.utc().toMillis();
			const storageTokenTimeInMillis = DateTime.fromISO(
				this.token.expirationDate
			).toMillis();

			if (currentDateTime >= storageTokenTimeInMillis) {
				// this.removeUserFromLocalStorage();
				// this.authMsgHandler.sendLogoutMessage();
			} else {
				this.setLastTimeChangedTokenExpiration(DateTime.utc().toISO());
				const newTokenExpirationDate = DateTime.utc().plus(
					this.token.slidingWindow * 1000
				);
				this.setTokenExpirationDate(newTokenExpirationDate.toISO());
			}
		}
	}

	@action.bound
	removeUserFromLocalStorage() {
		this.isUserLoggedOut();
		this.bettingActivityStore.bettingActivity = null;
		this.shouldDisplayAcceptNewTermsAfterLogin = false;
		this.shouldDisplayAccountActivationAfterLogin = false;
		this.shouldDisplayAfterLogin = false;
		this.shouldDisplayBettingActivityAfterLogin = false;
		this.shouldDisplayForcePasswordChangeAfterLogin = false;

		localStorage.removeItem("reality-check-expiration");
		localStorage.removeItem("auto-logout-period");
		localStorage.removeItem("acc");
		localStorage.removeItem("token-expiration-last-change");
		localStorage.removeItem("sln");
		localStorage.removeItem("ocb");
		//localStorage.removeItem('dsv');
	}

	@action.bound
	setLastTimeChangedTokenExpiration(value) {
		if (value) {
			this.lastTimeChangedTokenExpiration = value;
			localStorage.setItem(
				"token-expiration-last-change",
				JSON.stringify(this.lastTimeChangedTokenExpiration)
			);
		} else {
			this.lastTimeChangedTokenExpiration = DateTime.utc().toISO();
			localStorage.setItem(
				"token-expiration-last-change",
				JSON.stringify(this.lastTimeChangedTokenExpiration)
			);
		}
	}

	@action.bound
	setToken(value) {
		this.token = value;
		localStorage.setItem("userToken", JSON.stringify(value));
	}

	@action.bound
	setTokenExpirationDate(expirationDate) {
		const newUserToken = {
			...this.token,
			expirationDate: expirationDate,
		};
		localStorage.setItem("userToken", JSON.stringify(newUserToken));
	}

	@action.bound
	setLoginHistory(value) {
		this.loginHistory = value;
		localStorage.setItem("loginHistory", JSON.stringify(value));
	}

	@action.bound
	setUserAccounts(value) {
		if (value && value.length > 0) {
			this.userAccounts = this.sortUserAccounts(value);
			localStorage.setItem(
				"userAccounts",
				JSON.stringify(this.userAccounts)
			);
			if (localStorage.getItem("acc") == null) {
				if (DefaultAccount != null && DefaultAccount !== "") {
					const defaultUserAccount = this.userAccounts.find(
						(ua) => ua.abrv === DefaultAccount
					);

					if (defaultUserAccount != null) {
						this.setActiveUserAccountTypeId(
							defaultUserAccount.accountTypeId
						);
					} else {
						this.setActiveUserAccountTypeId(
							this.userAccounts[0].accountTypeId
						);
					}
				} else {
					const sportBettingAccount = this.userAccounts.find(
						(ua) =>
							ua.abrv ===
							UserAccountType.SportBettingAccountShopOnline
					);

					if (sportBettingAccount != null) {
						this.setActiveUserAccountTypeId(
							sportBettingAccount.accountTypeId
						);
					} else {
						this.setActiveUserAccountTypeId(
							this.userAccounts[0].accountTypeId
						);
					}
				}
			} else {
				const currentlyActiveAccount = localStorage.getItem("acc");
				const isActiveAccountAvailable = this.userAccounts.some(
					(ua) => ua.accountTypeId === currentlyActiveAccount
				);

				if (!isActiveAccountAvailable) {
					this.setActiveUserAccountTypeId(
						this.userAccounts[0].accountTypeId
					);
				}
			}
		}
	}

	@action.bound
	resolveForcePasswordChange(user, token, userAccounts) {
		this.setAuthenticationToken(null);
		user["bettingActivityNeeded"] = false;
		this.setUser(user);
		this.setToken(token);
		this.setUserAccounts(userAccounts);
		this.shouldDisplayForcePasswordChangeAfterLogin = false;
	}

	@action.bound
	async checkBalanceEntry() {
		if (this.user && this.activeAccountTypeId.includes("object")) {
			await this.refreshUserBalance();
		}
	}

	@action.bound
	async refreshUserBalance() {
		const response = await this.userDataService.getUserAccounts();
		this.setUserAccounts(response);
	}

	@action.bound
	setUser(user) {
		this.user = user;
		localStorage.setItem("user", JSON.stringify(user));
	}

	@action.bound
	setAuthenticationToken(accountActivation) {
		this.accountActivation = accountActivation;
		localStorage.setItem(
			"accountActivation",
			JSON.stringify(accountActivation)
		);
	}

	sortUserAccounts = (userAccounts) => {
		const sortOrderOfAccounts = [
			UserAccountType.SportBettingAccountShopOnline,
			UserAccountType.CashToDigitalAccount,
			UserAccountType.SportBettingAccountShop,
		];

		const sortedUserAccounts = userAccounts.some(
			(ua) => ua.abrv === UserAccountType.CashToDigitalAccount
		)
			? userAccounts.sort(
					(a, b) =>
						sortOrderOfAccounts.indexOf(a.abrv) -
						sortOrderOfAccounts.indexOf(b.abrv)
			  )
			: userAccounts;

		return sortedUserAccounts;
	};

	@action.bound
	async logoutUser(reasonsObj = {}) {
		await this.userDataService.logoutUser(reasonsObj);
		this.removeUserFromLocalStorage();
		this.authMsgHandler.sendLogoutMessage();
		sessionStorage.removeItem("slip");
		if (App.offer) {
			App.offer.rootStore.betSlipStore.betSlipState.reset();
		}
		this.resolveConnection();

		if (App.offer.rootStore.betSlipStore.deactivateOneClickBet) {
			App.offer.rootStore.betSlipStore.deactivateOneClickBet();
		}
		if (App.offer.rootStore.betSlipStore.resetBetSlipTypeToDefault) {
			App.offer.rootStore.betSlipStore.resetBetSlipTypeToDefault();
		}
	}

	@action.bound
	isUserLoggedOut() {
		this.token = null;
		this.user = null;
		this.accountActivation = null;
		this.loginHistory = null;
		this.userAccounts = null;
		localStorage.removeItem("reality-check-expiration");
		localStorage.removeItem("loginHistory");
		localStorage.removeItem("userAccounts");
		localStorage.removeItem("user");
		localStorage.removeItem("userToken");
		localStorage.removeItem("accountActivation");
	}

	@action.bound
	resolveAccountActivation(user, token, userAccounts) {
		this.setAuthenticationToken(null);
		this.setUser(user);
		this.setToken(token);
		this.setUserAccounts(userAccounts);
		this.shouldDisplayAccountActivationAfterLogin = false;
	}

	@action.bound
	resolveUserEmail(newEmail) {
		this.user["email"] = newEmail;
		localStorage.setItem("user", JSON.stringify(this.user));
	}

	@action.bound
	resolveBalance(update, accountTypeId = null) {
		this.refreshUserBalance();

		// // for future: change abrv check with user-selected user account
		// let accounts = this.userAccounts;

		// const checkForAccount = (a) => {
		//     return accountTypeId != null ?
		//         a.accountTypeId.value === accountTypeId : a.abrv === UserAccountType.SportBettingAccountShopOnline;
		// }

		// const currentAccountBalance = accounts.find(a => checkForAccount(a))['balance'];
		// const calculatedNewAccountBalance = currentAccountBalance + update;
		// if (calculatedNewAccountBalance < 0) {
		//     this.refreshUserBalance();
		// } else {
		//     this.setUserAccounts(accounts.filter(a => {
		//         if (checkForAccount(a)) {
		//             a.balance += update;
		//         }
		//         return a;
		//     }))
		// }
	}

	@action.bound
	setActiveUserAccountTypeId(id) {
		localStorage.setItem("acc", id);
		this.activeAccountTypeId = id;
	}

	resolveConnection() {
		App.offer.rootStore.hub.connection.disconnect();
		const connParamsFactory = new HubConnectionParametersFactory({
			shopId:
				this.user != null ? this.user.shopId : App.utils.getShopId(),
			agency:
				this.user != null
					? this.user.agencyKey
					: App.utils.getAgencyKey(),
			locale: App.utils.getCurrentLanguage(),
		});

		const connectionFactory = new HubConnectionFactory(connParamsFactory, {
			serverUrl: BaseProxyAddress + "sync",
			enableLogging: false,
		});

		const newConnection = connectionFactory.createConnection();

		App.offer.rootStore.hub.initialize(newConnection);
		App.offer.rootStore.hub.connection.connect();
	}

	@action.bound
	resetUser() {
		this.user = null;
	}

	handleKeepAlive = async () => {
		const currentDate = new Date();

		// token expiration last changed date time in milliseconds
		const lastChangedTokenExpirationDate = JSON.parse(
			localStorage.getItem("token-expiration-last-change")
		);
		const tokenExpirationLastChanged = DateTime.fromISO(
			lastChangedTokenExpirationDate
		).toMillis();
		const tokenProlongTime =
			currentDate.getTime() - tokenExpirationLastChanged;
		const isProlongTimeExpired = 10 * 60 * 1000;

		if (isProlongTimeExpired <= tokenProlongTime) {
			await this.keepAlive();
			this.syncToken();
		}
	};

	async keepAlive() {
		if (!App.utils.isUserAuthenticated()) return;
		try {
			await this.keepAliveService.ping();
		} catch {
			this.setStorageLogoutEvent();

			// resolve hub connection
			this.resolveConnection();
		}
	}
}

export default BaseUserAuthStore;
