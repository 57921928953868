import { observable, action, computed, reaction, when } from 'mobx';

import { OfferMapper } from '@gp/offer';
import { Sorter, insert } from '@gp/utility';

import GroupOfferStore from '../../GroupOfferStore';
import { LoaderStore } from '../../../../state/stores/common';

const logger = App.state.logger;

class EventViewStore extends GroupOfferStore {

    //#region observable

    @observable eventIdsRaw = '';
    @observable isBodyCollapsed = false;

    @observable isInitialize = true;
    @observable isFetchingData = false;
    @observable isStoreInitialized = false;

    @observable expandedEvents = [];
    @observable showOfferTable = true;
    @observable isScore = false;
    @observable isStatsExpanded = false;
    @observable enableBettingTypeGroups = false;

    //#endregion observable



    //#region computed

    @computed get isMultiEvent() {
        return this.eventIds.length > 1;
    }

    @computed get eventIds() {
        // slice ensures to remove first empty element because of leading '/'
        return this.eventIdsRaw.split('/').slice(1);
    }

    @computed get orderedEvents() {

        this.events.forEach(event => {

            // Add sport to event
            if (event.sport == null) {
                event.sport = this.getMappedSport(event);
            }

            // Add default betting type groups to event
            event.bettingTypeGroups = [];

            const eventKeys = this.eventKeysMap.get(event.id);

            if (eventKeys?.size > 0) {

                // Loop over all event keys and add there betting type group to event
                // Also add each unique betting type into betting type group
                const btGroups = Array.from(eventKeys.values()).
                    reduce((acc, eventKey) => {

                        //#region insert betting type group

                        if (acc.find(btGroup => btGroup.abrv === eventKey.bettingType.groupId) == null) {

                            acc.push(this.lookupsStore.bettingTypeGroups.get(eventKey.bettingType.groupId))
                        }

                        //#endregion insert betting type group

                        //#region  insert betting type into betting type group

                        const bettingGroup = acc.find(btGroup => btGroup.abrv === eventKey.bettingType.groupId);

                        if (bettingGroup.bettingTypes == null) {
                            bettingGroup.bettingTypes = [];
                        }

                        if (bettingGroup.bettingTypes.find(bt => bt.id === eventKey.bettingTypeId) == null) {

                            insert(
                                bettingGroup.bettingTypes,
                                eventKey.bettingType,
                                Sorter.sort(
                                    (a, b) => {

                                        const aSort = a?.settingsPerSport?.[event.sportId]?.sortOrder;
                                        const bSort = b?.settingsPerSport?.[event.sportId]?.sortOrder;

                                        const aSortOrder = aSort == null ? 99999 : aSort;
                                        const bSortOrder = bSort == null ? 99999 : bSort;
                                        
                                        return Math.sign(aSortOrder - bSortOrder);
                                    },
                                    'id',
                                ))
                        }


                        //#endregion insert betting type into betting type group

                        return acc
                    }, [])
                    .sort(Sorter.sort('sortOrder', 'id'));


                event.bettingTypeGroups = btGroups
            }
        });



        return this.events
            .sort((a, b) => {
                return this.eventIds.indexOf(b.id) - this.eventIds.indexOf(a.id);
            });
    }

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get isEmpty() {
        return this.eventsMap.size === 0 && !this.isInitialize && !this.isLoading && !this.isFetchingData;
    }

    //#endregion computed



    //#region constructor

    constructor(rootStore, options = null) {
        super(rootStore, options);

        this.loader = new LoaderStore();

        this.isEventDisplayed = this.isEventDisplayed.bind(this);
        this.isEventExpanded = this.isEventExpanded.bind(this);
        this.isBettingTypeGroupOfferEmpty = this.isBettingTypeGroupOfferEmpty.bind(this);
        this._updateUrl = this._updateUrl.bind(this);

        this.expandedEventsReaction = reaction(() => this.expandedEvents.map(i => i), expandedEvts => {
            this.initializeEventView();
        });

        this.enableBettingTypeGroups = options.enableBettingTypeGroups;
    }

    //#endregion constructor



    //#region actions 

    @action.bound
    addEvent(eventId) {
        if (this.eventIdsRaw.indexOf(eventId) === -1) {
            this.eventIdsRaw += `/${eventId}`;
            this._updateUrl();
        }
    }

    @action.bound
    removeEvent(eventId) {
        if (this.eventIdsRaw.indexOf(eventId) > -1) {
            this.eventIdsRaw = this.eventIdsRaw.replace(new RegExp(`\/${eventId}`), '');
            this._updateUrl();
        }
    }

    /**
     * @param {string} eventId 
     * @returns {boolean} True if event with eventId is displayed in multi view
     */
    isEventDisplayed(eventId) {
        return this.eventIdsRaw.indexOf(eventId) > -1;
    }

    @action.bound
    expandEvent(eventId) {
        if (this.expandedEvents.indexOf(eventId) === -1) {
            this.expandedEvents.push(eventId);
        }
    }

    @action.bound
    collapseEvent(eventId) {
        if (this.expandedEvents.includes(eventId)) {
            this.expandedEvents.remove(eventId);
        }
    }

    @action.bound
    toggleOfferTable() {
        this.showOfferTable = !this.showOfferTable;
    }

    isEventExpanded(eventId) {
        return this.expandedEvents.includes(eventId);
    }

    isBettingTypeGroupOfferEmpty(bettingTypeGroupAbrv) {
        let isEmpty = true;

        if (this.enableBettingTypeGroups) {
            const bettingTypeGroups = this.offer.sports[0].categories[0].tournaments[0].events[0].bettingTypeGroups;
            const currentBettingTypeGroup = bettingTypeGroups[bettingTypeGroupAbrv];
            const isBettingTypeGroupOfferExist = currentBettingTypeGroup.some(bettingType => bettingType.keyOffers.length > 0);

            isEmpty = !isBettingTypeGroupOfferExist;
        }

        return isEmpty;
    }

    /**
     * @param {string} eventIds event ids in format: "eventId1/eventId2/eventIdn"
     */
    @action.bound
    async updateEventIds(eventIds) {
        if (this.eventIdsRaw != eventIds) {
            this.eventIdsRaw = '/' + eventIds;
        }

        if (!this.isMultiEvent) {
            this.resetDisplayBettingGroup();
        }
        await this.initializeEventView();
    }

    @action.bound
    toggleBodyCollapsed() {
        this.isBodyCollapsed = !this.isBodyCollapsed;
    }

    /**
     * @param {string} eventIds event ids in format: "eventId1/eventId2/eventIdn"
     */
    @action.bound
    async initEventIds(eventIds) {
        await this.updateEventIds(eventIds);
    }

    @action.bound
    setDisplayBettingGroup(value) {
        if (value !== this.displayBettingGroup) {
            this.displayBettingGroup = value;
        }
    }

    @action.bound
    resetDisplayBettingGroup() {
        this.displayBettingGroup = 'all';
    }

    @action.bound
    toggleIsScore() {
        this.isScore = !this.isScore;
    }

    @action.bound
    toggleStats() {
        this.isStatsExpanded = !this.isStatsExpanded;
    }

    //#endregion actions



    //#region offer fetch

    @action.bound
    onInitialize() {

    }

    @action.bound
    async initializeEventView() {
        this.disposeSubscription();

        await when(() => this.rootStore.hub.isStarted);

        if (!this.eventIdsRaw || this.eventIdsRaw === "") {
            return;
        }

        this.onStartFetching();
        this.clearEvents();


        //#region subscription

        const subscriptionId = `events-${this.eventIdsRaw}`;

        let betOfferFilter = {
            name: 'betOffer'
        };

        if (this.isMultiEvent) {
            if (this.expandedEvents.length > 0) {
                betOfferFilter.excludeFilter = {
                    id: {
                        eq: this.expandedEvents
                    }
                };
            }

            const mainBettingTypes = OfferMapper.getBettingTypes('live');
            betOfferFilter.filter = [
                {
                    bettingType: {
                        abrv: {
                            eq: mainBettingTypes.normal
                        }
                    },
                },
                {
                    bettingType: {
                        abrv: {
                            eq: mainBettingTypes.marginal
                        }
                    },
                    isFavorite: true
                }
            ];
        }

        let subscriptionRequest = {
            subscriptionId: subscriptionId,
            // compress: true,
            compress: false,
            channels: [
                {
                    name: 'event',
                    filter: {
                        id: {
                            eq: this.eventIds
                        }
                    }
                },
                betOfferFilter
            ],
            // throttle: 3
        };

        //#endregion subscription

        logger.log("Connecting to hub for event offer.", subscriptionId);

        this.subscription = this.rootStore.hub.getOfferSubscription(subscriptionRequest)
            .subscribe(this.handleResponse, this.handleError);
    }

    @action.bound
    handleResponse(response) {
        this.assignOfferData(response);

        // check what was response in case we looked for multiple events and got less than that
        if (this.eventIds.length !== this.events.length) {
            this.events.forEach((value, key) => {
                if (this.eventIds.indexOf(key) === -1) {
                    // found missing event id, kill it with fire
                    this.eventIdsRaw.replace('/' + key, '');
                    this._updateUrl();
                }
            });
        }

        if (this.events.length === 0 && !this.isMultiEvent) {

            this.rootStore?.eventSwitcherViewStore?.removeEventId(this.eventIdsRaw.split('/').find(el => el !== ''))

            if (this.rootStore?.eventSwitcherViewStore?.eventIds.length >= 1) {
                /** If requested event is not in offer then we will fetch next one from event switcher.
                 * If event is removed from event switcher and there are 2 or more events left
                 * Dont stop the loader
                 * Set store state to fetching to prevent isEmpty to resolving to true
                */
                this.onStartFetching();
                return;
            }
        }

        this.onEndFetching();
    }

    @action.bound
    handleError(err) {
        console.error(err);
        this.onEndFetching();
    }

    @action.bound
    onStartFetching() {
        if (!this.isFetchingData) {
            this.loader.suspend();
            this.isFetchingData = true;
            this.isInitialize = true;
        }
    }

    @action.bound
    onEndFetching() {
        this.onBeforeFetchEnd();
        this.loader.resume();
        this.isFetchingData = false;
        this.isInitialize = false;
        this.isStoreInitialized = true;
    }

    @action.bound
    onBeforeFetchEnd() {
        // Override this as needed in subclasses
    }

    //#endregion offer fetch



    //#region disposers

    @action.bound
    onDispose() {

        this.disposeSubscription();
        this.resetDisplayBettingGroup();
        this.reset();


        this.eventIdsRaw = '';
        this.isInitialize = true;
        this.isStoreInitialized = false;
        this.isLMTAvailable = true;
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    clearEvents() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.reset();
    }

    //#endregion disposers



    //#region helpers

    _updateUrl() {
        const culture = App.utils.getCurrentCulture();

        App.state.redirect(`/${culture}/live/events${this.eventIds.length > 0 ? '/' : ''}${this.eventIds.join('/')}`, true);
    }

    //#endregion helpers

}

export default EventViewStore;