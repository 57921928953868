import { observable, action, computed } from 'mobx';
import { merge } from 'lodash';

import { EventType, LiveStatus } from '@gp/models';
import { OfferMapper, PrematchOfferStore } from '@gp/offer';
import { ConsoleLogger } from '@gp/utility';

import AdditionalOfferStore from '../../../AdditionalOfferStore';
import { BettingTypeSelectorsStore } from '../../../components/selectors';
import { LoaderStore } from '../../../../../state/stores/common';

const logger = App.state.logger;

const DefaultConfig = {
    columns: ["1", "2"]
};

class MostPlayedViewStore extends PrematchOfferStore {
    config = DefaultConfig;
    columns = null;
    selectedBettingType;

    @observable isExpanded = true;
    /** Is store initializing  */
    @observable isInitialize = true;

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get selectedBettingTypeAbrv() {
        return this.selectedBettingType.abrv;
    }

    @computed get displayedBettingType() {
        return this.bettingTypesByAbrv[this.selectedBettingTypeAbrv];
    }

    @computed get eventsList() {
        return this.events
            .map(e => {
                e.sport = this.getMappedSport(e);
                return e;
            })
    }

    @computed get isEmpty() {
        return this.eventsList.length === 0 && !this.isInitialize && !this.isLoading;
    }

    /**
     * @param {*} rootStore 
     * @param {{columns: string[]}} config number of columns to fetch
     */
    constructor(rootStore, config = undefined) {
        super({
            logger: new ConsoleLogger(false),
            removeDelay: 10,
            throttle: 4,
            enableThrottling: true,
            pageSize: 5,
            bettingTypeSelectorsStore: new BettingTypeSelectorsStore()
        });

        if (config != null) {
            this.config = merge({}, DefaultConfig, config);
        }

        this.rootStore = rootStore;
        this.loader = new LoaderStore();

        this.additionalOfferStore = new AdditionalOfferStore(rootStore);
    }

    @action.bound
    async initializeStore() {
        this.loader.suspend();
        this.isInitialize = true;
        // const allBts = OfferMapper.getBettingTypes('prematch');
        const allBts = OfferMapper.getSportBettingTypesByCategory('mostPlayed');
        try {
            const offer = await this.rootStore.hub.getMostPlayed({
                language: App.utils.getCurrentLanguage(),
                numberOfEvents: 15,
                filter: {
                    eventType: EventType.NORMAL,
                    liveStatus: LiveStatus.PREMATCH,
                    offers: [
                        {
                            bettingType: {
                                abrv: {
                                    eq: allBts.filter.normal
                                }
                            }
                        },
                        {
                            bettingType: {
                                abrv: {
                                    eq: allBts.filter.marginal
                                }
                            },
                            isFavorite: true
                        }
                    ]
                }
            });

            this.assignOfferData(offer);
            this.isInitialize = false;
        }
        catch (err) {
            logger.logError('Error while fetching most played events', err);
        }
        finally {
            this.loader.resume();
            this.isInitialize = false;
        }
    }

    @action.bound
    onDispose() {
        this.isExpanded = true;
    }

    @action.bound
    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    @action.bound
    setEventIdsForSwitcher(eventId) {

        const list = this.eventsList.map(({ id }) => id);

        const eventIndex = list.indexOf(eventId);

        this.rootStore.eventSwitcherViewStore.setEventList(list, eventIndex);
    }
}

export default MostPlayedViewStore;