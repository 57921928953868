import { observable, action, computed } from 'mobx';

import BaseSearchBarViewStore from './BaseSearchBarViewStore';

class SearchBarViewStore extends BaseSearchBarViewStore {
    @observable persistentTerm;
    @observable period;

    constructor() {
        super({
            onTermChange: val => this.persistentTerm = val
        });

    }

    @action.bound
    onTermChange(term) {
        this.searchTerm = term;
    }
    @action.bound
    setRedirect(value) {
        this.redirect = value
    }

    @action.bound
    onSubmit({ event, period, redirect }) {
        event.preventDefault();
        event.stopPropagation();
        if (this.trimSearchTerm === '') {
            return;
        }

        if (!this.isValid) {
            this.displayValidationPopup = true;
            return;
        }
        else {
            this.displayValidationPopup = false;
        }

        this.persistentTerm = this.trimSearchTerm;

        if (redirect) {
            App.state.redirect(`/${App.utils.getCurrentCulture()}/search/${period}/${this.persistentTerm}`)
        }
    }

    @action.bound
    onReset() {
        this.searchTerm = '';

        if (this.config.onTermChange) {
            this.config.onTermChange('');
        }

        App.state.redirect(`/${App.utils.getCurrentCulture()}/home/full`)
    }

}

export default SearchBarViewStore;