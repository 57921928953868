import { observable, action, computed } from 'mobx';

import { insert, Sorter } from '@gp/utility';
export class BaseNodeStore {

    //#region  observable

    @observable parent = null;
    @observable node = {};
    @observable children = [];

    //#endregion observable

    //#region computed

    /**
     * @returns {boolean} True if it has no parent
     */
    @computed get isRoot() {
        return this.parent == null;
    }

    /**
    * If true, node is parent
    * @returns {boolean} True if node has children
    */
    @computed get isParent() {
        return this.children.length > 0;
    }

    /**
     * @returns {boolean} True if node does not have children
     */
    @computed get isLeaf() {
        return !this.isParent;
    }

    /**
     * @returns {boolean} True if node has parent
     */
    @computed get isChild() {
        return this.parent != null;
    }

    //#endregion computed


    //#region constructor

    constructor(parent = null) {
        this.parent = parent;
    }

    //#endregion constructor



    //#region assign data

    /**
     * @param {array} rawStructure array of items containing:
     * n for node 
     * c for children
     */
    @action.bound
    assignStructure(rawStructure) {

        if (rawStructure == null) {
            return;
        }

        const { n, c } = rawStructure;

        this.assignChildren(c);

        this.assignNodeData(n);
    }

    /**
     * @param {array} children array of items containing {n, c}
     */
    @action.bound
    assignChildren(children) {

        if (children == null) {
            this.children = [];
            return;
        }

        this.pruneChildren(children)

        // When ever assigning children assign them in order received
        // Children come sorted
        // We don't want to recreate children structure for non updated nodes to prevent rerenders
        const newMyChildrenSort = [];

        for (const childItem of children) {

            const { n } = childItem;

            const childInMyChildren = this.children.find(child => child.node.id === n.id);
            if (childInMyChildren != null) {
                childInMyChildren.assignStructure(childItem);
                newMyChildrenSort.push(childInMyChildren)
                continue;
            }

            this.insertNewChild(childItem, newMyChildrenSort);
        }

        this.children = newMyChildrenSort
    }

    /**
     * @param {{n, c}} childItem to be inserted into children array
     * @param {array} childrenArray array into which to add new child
     */
    @action.bound
    insertNewChild(childItem, childrenArray) {
        const newChild = new this.constructor(this);
        newChild.assignStructure(childItem);

        childrenArray.push(newChild);
    }

    /**
     * @param {array} freshChildren remove all children of current node that are not in freshChildren
     * Compares by child.n.id
     */
    @action.bound
    pruneChildren(freshChildren) {
        for (let i = this.children.length - 1; i >= 0; i--) {
            const currentChild = this.children[i];

            if (currentChild == null) {
                continue;
            }

            const childInFreshWithCurrentChildId = freshChildren.find(({ n }) => n.id === currentChild.node.id);

            if (childInFreshWithCurrentChildId == null) {
                const deletedChildren = this.children.splice(i, 1);
                for (const deletedChild of deletedChildren) {
                    deletedChild.onDispose();
                }
            }
        }
    }

    /**
     * @param {object} freshNodeData assigns node data to this.node
     */
    @action.bound
    assignNodeData(freshNodeData) {
        this.node = freshNodeData;
    }

    //#endregion assign data



    //#region disposer

    @action.bound
    onDispose() {

        for (const child in this.children) {
            child.onDispose();
        }

        this.parent = null;
        this.node = {};
        this.children = [];
    }

    //#endregion disposer
}