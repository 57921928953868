import {
	observable,
	action,
	reaction,
	computed,
	runInAction,
	_allowStateChanges,
} from "mobx";
import {
	groupBy,
	each,
	find,
	isMatch,
	map,
	uniqBy,
	includes,
	unionBy,
} from "lodash";

import {
	BetSlipStore as GpBetSlipStore,
	EventType,
	BetSlipErrorCodes,
	parseSpecifier,
	BetSlipBetType,
} from "@gp/gp-javascript-utils";

import { getTip, playerSports } from "../../../../ui/common/utils";
import { LoaderStore } from "../../../../state/stores/common";
import { BetSlipService } from "../../../../offer/services/components";

const storageProvider = App.state.sessionStorageProvider;
const localStorageProvider = App.state.localStorageProvider;

const titleErrorCodes = [
	4000011, 400012, 400023, 400026, 400027, 400031, 400035,
];
const stakeErrorCodes = [
	400017, 400018, 400019, 400020, 400024, 400030, 400032, 400033, 400036,
	400037, 400038,
];
const valueErrorCodesWithDetails = ["400065", "400068"];
const errorCodesWithoutDetails = ["400091", "400092"];

const keysList = [
	"paymentPerCombination",
	"gain",
	"totalStake",
	"payment",
	"stakePerCombination",
	"maxGain",
	"gainBonusAmount",
	"gainTaxAmount",
];

const getName = (name, gender) => {
	if (gender === 1) {
		return `${name} (${App.state.localizationService.t(
			"OFFER.GENDER.WOMEN"
		)})`;
	}

	return name;
};

const MAX_TOASTS = 1;

class BetSlipStore {
	@observable betSlipState;

	// ---- Modals ------------
	@observable showConfirmationModal = false;
	@observable showSubmitSuccessModal = false;
	@observable showValidationModal = false;
	@observable fullErrorMessage = "";
	@observable isCountdownVisible = false;
	@observable countdownCounter = 0;
	@observable showReuseButton = false;
	@observable isSubscribed = false;

	@observable showOneClickBetClearSlipModal = false;
	@observable showSlipResetModal = false;
	@observable isReuseInProgress = false;

	@computed get isLoading() {
		return this.betSlipState.isSubmitting; //|| this.betSlipState.isProcessing;
	}

	@computed get hasInvalidBets() {
		return (
			this.betSlipState.slip.betSlipOffers.filter(
				(o) =>
					o.isEventLocked ||
					o.isKeyLocked ||
					o.isOfferLocked ||
					o.isDeleted
			).length > 0
		);
	}

	constructor(rootStore) {
		this.rootStore = rootStore;

		this.betSlipService = new BetSlipService();

		this.betSlipState = new GpBetSlipStore(
			this.betSlipService,
			{
				storageProvider: storageProvider,
				localStorageProvider: localStorageProvider,
				useDefaultBetSlipStake: true,
				hub: rootStore.hub,
				isUserAuthenticated: App.utils.isUserAuthenticated,
				notificationProvider: {
					// onError: this.onErrorHandler,
					onError: this.handleError,
					onSuccess: () => {
						// nToastNotify.showMessage({ message: App.state.localizationService.t('BET_SLIP.SUBMIT_SUCCESS'), options: { title: 'Success', type: 'success' } });
						toastr.success(
							App.state.localizationService.t(
								"BET_SLIP.SUBMIT_SUCCESS"
							),
							null,
							{ preventDuplicates: false }
						);

						if (this.isCountdownVisible) {
							this.isCountdownVisible = false;
						}

						//#region inject newly submitted events into played events counter

						if (
							this.rootStore.liveOfferMenuStore
								?.liveEventsFromMyBets != null
						) {
							let eventIds = [];

							if (this.betSlipState.isOneClickBetActive) {
								this.rootStore.liveOfferMenuStore.liveEventsFromMyBets.successfulOneClickTips(
									this.betSlipState._oneClickTips
								);
							} else {
								eventIds =
									this.betSlipState.slip.betSlipOffers.reduce(
										(acc, offer) => {
											acc.push(offer.eventId);
											return acc;
										},
										[]
									);
								this.rootStore.liveOfferMenuStore.liveEventsFromMyBets.updateEvents(
									eventIds
								);
							}
						}

						//#endregion inject newly submitted events into played events counter

						if (!this.isSubscribed) {
							toastr.subscribe(function (args) {
								if (args.state === "visible") {
									var toasts = $(
										"#toast-container > *:not([hidden])"
									);
									if (toasts && toasts.length > MAX_TOASTS)
										toasts[toasts.length - 1].hidden = true;
								}
							});
							this.isSubscribed = true;
						}

						if (rootStore.myBetsViewStore) {
							rootStore.myBetsViewStore.refresh();
						}
					},
				},
				oddTypeStore: rootStore.oddTypeStore,
				updateBalance: this.updateAccountBalance,
				slipIndicatorDuration: 2000,
				isReuseEnabled: true,
			},
			{ totalStake: DefaultStake }
		);

		this.loader = new LoaderStore();

		this.errorsReaction = reaction(
			() => this.betSlipState.error,
			(error) => this.showHideErrorModal(error)
		);

		this.isCombinationSelected = this.isCombinationSelected.bind(this);
		this.isEventBank = this.isEventBank.bind(this);
	}

	@action.bound
	async onInitialize() {
		if (this.isReuseInProgress) {
			this.isReuseInProgress = false;
			return;
		}

		try {
			// check if bet slip is not empty and revalidate slip!
			await this.betSlipState.processCurrentBetSlipState();

			const response = await this.betSlipService.delay();
			this.betSlipState.setSubmitDelay(response ? response.value : null);
		} catch (err) {
			this.betSlipState.setSubmitDelay(null);
		}
	}

	@action.bound
	onDispose() {
		if (this.errorsReaction) {
			this.errorsReaction();
		}

		if (this.slipTypeObserve) {
			this.slipTypeObserve();
		}
	}

	getCountdownValue = () => {
		if (this.betSlipState.submitDelay != null) {
			// we increase timer by 1 for failsafe (this ensures countdown does not close before submit response is received)
			return this.betSlipState.submitDelay;
		}

		return 0;
	};

	@action.bound
	startCountdown() {
		this.countdownCounter = this.getCountdownValue();
		if (this.countdownCounter !== 0) {
			this.isCountdownVisible = true;
			this.countdownInterval = setInterval(async () => {
				--this.countdownCounter;
				if (this.countdownCounter === 0) {
					clearInterval(this.countdownInterval);
					this.isCountdownVisible = false;
				}
			}, 1000);
		} else {
			this.isCountdownVisible = false;
		}
	}

	@action.bound
	changeBetSlipBetType(value) {
		if (
			value === BetSlipBetType.OneClickBet &&
			this.betSlipState.isSlipEmpty
		) {
			this.betSlipState.onBetSlipBetTypeChange(value);
			return;
		} else if (
			value === BetSlipBetType.OneClickBet &&
			!this.betSlipState.isSlipEmpty
		) {
			this.showOneClickBetClearSlipModal = true;
			return;
		}

		this.betSlipState.onBetSlipBetTypeChange(value);
	}

	@action.bound
	onBetSlipBetTypeChangeAccept() {
		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.OneClickBet);
		this.showOneClickBetClearSlipModal = false;
	}

	@action.bound
	onCloseOneClickBetChangeModal() {
		this.showOneClickBetClearSlipModal = false;
	}

	@action.bound
	showHideErrorModal(error) {
		if (!error) {
			this.showValidationModal = false;
			return;
		}

		if (error.statusCode === BetSlipErrorCodes.Unauthenticated) {
			this.showLoginPopup();
		}
	}

	showLoginPopup() {
		document
			.querySelector("#loginPopupContainer")
			.classList.add("is-active");
	}

	@action.bound
	hideErrorModal() {
		this.betSlipState.resetError();
		this.showValidationModal = false;
	}

	@action.bound
	async onSubmitSlip() {
		this.loader.suspend();

		// needs to happen because of possible wrong data formating in acc key in localstorage !!!! - can be removed after some time but doesn't hurt
		await App.state.rootStore.userAuthStore.checkBalanceEntry();

		this.showConfirmationModal = false;
		if (this.betSlipState.slip.isLive) {
			this.startCountdown();
		}

		const currentAccountType =
			App.state.rootStore.userAuthStore.activeAccountTypeId;

		await this.betSlipState.submitSlip(currentAccountType);

		runInAction(() => {
			if (!this.betSlipState.hasErrors) {
				this.rootStore?.liveMenuViewStore?.liveEventsFromMyBets?.updateEvents(
					this.betSlipState.subscription.events
				);
				this.betSlipState.reset(true, { resetSubmitDelay: false });
			}
		});

		this.loader.resume();
	}

	@action.bound
	async reuseBetSlip() {
		await this.betSlipState.reuseBetSlip();
		runInAction(() => {
			this.showSubmitSuccessModal = false;
			this.showReuseButton = false;
		});
	}

	@action.bound
	async removeInactiveBets() {
		this.betSlipState.removeInactiveOffers();
	}

	/**
	 * Handles bet slip error notification
	 * @param {{statusCode: number, message: string, error?: *, mappedError?: *, offers?: []}} rawError
	 */
	@action.bound
	handleError(rawError) {
		// clear interval for submitting live ticket
		clearInterval(this.countdownInterval);
		this.isCountdownVisible = false;

		if (rawError) {
			if (rawError.error && rawError.error.statusCode === 401) {
				//the user is logged out on some way and needs to be redirected to login page
				window.location.href = `/${App.utils.getCurrentCulture()}/auth/login?isSessionTerminated=true`;
			}
			if (rawError.error && rawError.error.errorCode === 400007) {
				this._showErrorMessage(rawError.error.details);
				return;
			}
			if (rawError.mappedError != null) {
				if (Array.isArray(rawError.mappedError)) {
					if (!this.betSlipState.isSubmitting) {
						rawError.mappedError = rawError.mappedError.filter(
							(errorItem) =>
								errorItem.errorCode !== 400062 &&
								errorItem.errorCode !== 400063
						);
					}
					each(rawError.mappedError, (errorItem) => {
						if (errorItem.errorMessage.includes("{currency}")) {
							errorItem.errorMessage =
								errorItem.errorMessage.replaceAll(
									"{currency}",
									App.state.rootStore.currencyStore.currency
								);
						}
						if (errorItem.errorMessage.includes("{0}")) {
							const currentSportData = errorItem.sportData[0];
							if (errorItem.errorCode === 400091) {
								const eventName = currentSportData.sportData
									.eventName
									? currentSportData.sportData.eventName
									: `${currentSportData.sportData.teamOneName} - ${currentSportData.sportData.teamTwoName}`;
								errorItem.errorMessage = `<p class="slip-error">${errorItem.errorMessage.replace(
									"{0}",
									eventName
								)}</p>`;
							} else if (errorItem.errorCode === 400092) {
								const bettingType = parseSpecifier(
									currentSportData.sportData
										.bettingTypeNameForBettingSlip,
									currentSportData.specifier
										? currentSportData.specifier
										: {}
								);

								errorItem.errorMessage = `<p class="slip-error">${errorItem.errorMessage.replace(
									"{0}",
									bettingType.parsedString
								)}</p>`;
							} else if (errorItem.errorCode === 400098) {
								errorItem.errorMessage =
									errorItem.errorMessage.replace(
										"{0}",
										errorItem.totalNumberOfOffersPerBetSlip
									);
							}
						}
					});
					const isTitleNeeded = rawError.mappedError.some((i) =>
						titleErrorCodes.includes(i.errorCode)
					);
					const errorsByErrorCode = groupBy(
						rawError.mappedError,
						"errorCode"
					);
					let errorMessages = Object.keys(errorsByErrorCode);
					each(errorMessages, (key) => {
						//key is error code
						const containsSportData =
							find(
								errorsByErrorCode[key],
								(item) => item.sportData.length > 0
							) !== undefined;
						if (
							containsSportData &&
							!errorCodesWithoutDetails.includes(key)
						) {
							var errorMessageContainsSuggestedValue = find(
								errorsByErrorCode[key],
								(item) => /\d/.test(item.errorMessage)
							);
							if (
								!errorMessageContainsSuggestedValue ||
								valueErrorCodesWithDetails.includes(key)
							) {
								const currentMessageList =
									errorsByErrorCode[key];
								const eventIdNotExists =
									currentMessageList.some((o) => !o.eventId);
								const groupedCurrentMessageList = groupBy(
									currentMessageList,
									eventIdNotExists
										? "sportData[0].eventId"
										: "eventId"
								);
								let currentMessageDetailsList = [];
								each(
									Object.keys(groupedCurrentMessageList),
									(innerKey) => {
										let details = null;
										const sportDataList =
											groupedCurrentMessageList[
												innerKey
											][0].sportData;
										if (
											sportDataList &&
											sportDataList.length > 0
										) {
											let sportData =
												sportDataList[0].sportData;
											if (key === "400039") {
												details = {
													teams: "",
													bettingType: `${groupedCurrentMessageList[innerKey][0].bettingTypeOne}<br>${groupedCurrentMessageList[innerKey][0].bettingTypeTwo}`,
												};
											} else if (key === "400043") {
												const secondTournament =
													this.betSlipState.slip.betSlipOffers.find(
														(offer) =>
															offer.eventId ===
															groupedCurrentMessageList[
																innerKey
															][0].eventTwoId
													);
												let secondTournamentName = null;
												if (secondTournament) {
													secondTournamentName =
														secondTournament
															.sportData
															.tournamentName;
												}
												details = {
													teams: `${
														sportData.tournamentName
													}${
														secondTournamentName
															? `<br>${secondTournamentName}`
															: ""
													}`,
													bettingType: "",
												};
											} else if (
												key === "400040" ||
												key === "400041"
											) {
												const eventOne =
													this.betSlipState.slip.betSlipOffers.find(
														(offer) =>
															offer.eventId ===
															groupedCurrentMessageList[
																innerKey
															][0].eventId
													);
												const eventTwo =
													this.betSlipState.slip.betSlipOffers.find(
														(offer) =>
															offer.eventId ===
															groupedCurrentMessageList[
																innerKey
															][0].eventTwoId
													);
												let eventOneName = null;
												let eventTwoName = null;

												if (
													eventOne &&
													eventOne.sportData
												) {
													eventOneName = eventOne
														.sportData.eventName
														? eventOne.sportData
																.eventName
														: `${eventOne.sportData.teamOneName} - ${eventOne.sportData.teamTwoName}`;
												}
												if (
													eventTwo &&
													eventTwo.sportData
												) {
													eventTwoName = eventTwo
														.sportData.eventName
														? eventTwo.sportData
																.eventName
														: `${eventTwo.sportData.teamOneName} - ${eventTwo.sportData.teamTwoName}`;
												}

												details = {
													teams:
														eventOneName &&
														eventTwoName
															? `${eventOneName}<br>${eventTwoName}`
															: "",
													bettingType: "",
												};
											} else if (key === "400044") {
												details = {
													teams: sportData.tournamentName,
													bettingType: "",
												};
											} else {
												const homeTeamName =
													sportData.eventType ===
													EventType.Normal
														? getName(
																sportData.teamOneName,
																sportData.teamOneGender
														  )
														: "";
												const awayTeamName =
													sportData.eventType ===
													EventType.Normal
														? getName(
																sportData.teamTwoName,
																sportData.teamTwoGender
														  )
														: "";

												details = {
													teams:
														sportData.eventType ===
														EventType.Normal
															? `${homeTeamName} - ${awayTeamName}`
															: `${sportData.eventName}`,
													bettingType: "",
												};
											}
											if (
												![
													"400039",
													"400040",
													"400041",
													"400043",
													"400044",
												].includes(key)
											) {
												each(
													groupedCurrentMessageList[
														innerKey
													],
													(o, idx) => {
														const currentSportData =
															o.sportData[0];

														let c1Key =
															"OFFER.COMPETITORS.HOME";
														let c2Key =
															"OFFER.COMPETITORS.AWAY";
														if (
															playerSports.includes(
																currentSportData
																	.sportData
																	.sportAbrv
															)
														) {
															c1Key =
																"OFFER.COMPETITORS.PLAYER1";
															c2Key =
																"OFFER.COMPETITORS.PLAYER2";
														}

														const bettingType =
															parseSpecifier(
																currentSportData
																	.sportData
																	.bettingTypeNameForBettingSlip,
																currentSportData.specifier
																	? currentSportData.specifier
																	: {},
																{
																	competitor1:
																		App.state.localizationService.t(
																			c1Key
																		),
																	competitor2:
																		App.state.localizationService.t(
																			c2Key
																		),
																}
															);
														const stringComposition = `${
															bettingType.parsedString
														} ${
															currentSportData.playerId
																? `${currentSportData.playerFirstName} ${currentSportData.playerLastName}`
																: currentSportData.teamId
																? currentSportData.teamName
																: currentSportData.displayTip
														}`;
														if (idx === 0) {
															details.bettingType +=
																stringComposition;
														} else {
															details.bettingType += `\n${stringComposition}`;
														}
													}
												);
											}
											const existingDetails = find(
												currentMessageDetailsList,
												(o) => {
													return isMatch(o, details);
												}
											);
											if (!existingDetails) {
												currentMessageDetailsList.push(
													details
												);
											}
										}
									}
								);
								if (currentMessageDetailsList.length > 0) {
									each(currentMessageList, (errorItem) => {
										each(
											currentMessageDetailsList,
											(detailObj) => {
												errorItem.errorMessage += `<p class="slip-error">${
													detailObj.teams
												}${
													key === "400065"
														? ` : ${parseInt(
																errorItem.suggestedValue
														  )}`
														: ""
												}</p>${
													key === "400065"
														? ""
														: `<p class="bet-stop">${detailObj.bettingType}</p>`
												}`;
											}
										);
									});
								}
							}
						}
					});

					// If errors contain stake error, reset stake to last valid
					const stakeErrorCodes = [
						400017, 400018, 400019, 400020, 400024, 400030, 400032,
						400033, 400036, 400037, 400038, 400064,
					];
					const hasStakeErrors = rawError.mappedError.some((i) =>
						stakeErrorCodes.includes(i.errorCode)
					);
					if (
						hasStakeErrors &&
						this.betSlipState.isChangingSlipValues
					) {
						this.betSlipState.totalStake =
							this.betSlipState._memory.totalStake;
						this.betSlipState.stakePerCombination =
							this.betSlipState._memory.stakePerCombination;
						this.betSlipState.paymentPerCombination =
							this.betSlipState._memory.paymentPerCombination;
						this.betSlipState.gain = this.betSlipState._memory.gain;
						this.betSlipState.payment =
							this.betSlipState._memory.payment;
						this.betSlipState.maxGain =
							this.betSlipState._memory.maxGain;
						this.betSlipState.gainBonusPercentage =
							this.betSlipState._memory.gainBonusPercentage;
						this.betSlipState.gainBonusAmount =
							this.betSlipState._memory.gainBonusAmount;
						this.betSlipState.gainTaxPercentage =
							this.betSlipState._memory.gainTaxPercentage;
						this.betSlipState.gainTaxAmount =
							this.betSlipState._memory.gainTaxAmount;
						this.betSlipState.handlingFeePercentage =
							this.betSlipState._memory.handlingFeePercentage;
						this.betSlipState.handlingFeeAmount =
							this.betSlipState._memory.handlingFeeAmount;
						this.betSlipState.combinationBettingOffersCount =
							this.betSlipState._memory.combinationBettingOffersCount;
					}

					const errorMessage = map(
						uniqBy(rawError.mappedError, (o) => o.errorMessage),
						"errorMessage"
					).join("\n");
					if (errorMessage !== "") {
						this._showErrorMessage(errorMessage, isTitleNeeded);
					}
				} else {
					this._showErrorMessage(rawError.mappedError.message);
				}
			} else if (rawError.statusCode === BetSlipErrorCodes.Generic) {
				this._showErrorMessage(rawError.message);
			} else {
				this._showErrorMessage(
					App.state.localizationService.t(rawError.message)
				);
			}
		}
	}

	_showErrorMessage(errorMessage, showTitle = false) {
		if (!this.isSubscribed) {
			toastr.subscribe(function (args) {
				if (args.state === "visible") {
					var toasts = $("#toast-container > *:not([hidden])");
					if (toasts && toasts.length > MAX_TOASTS)
						toasts[toasts.length - 1].hidden = true;
				}
			});
			this.isSubscribed = true;
		}
		// toastr.remove();
		// nToastNotify.showMessage({ message: errorMessage, options: { title: showTitle ? App.state.localizationService.t('BET_SLIP.ERRORS.VALIDATION_ERROR') : '', type: 'error' } });
		toastr.error(
			errorMessage,
			showTitle
				? App.state.localizationService.t(
						"BET_SLIP.ERRORS.VALIDATION_ERROR"
				  )
				: null,
			{ preventDuplicates: true }
		);
	}

	@action.bound
	async onTotalStakeApply() {
		await this.betSlipState.onTotalStakeApply();
		this.checkBetSlipValues();
	}

	@action.bound
	async onGainApply() {
		await this.betSlipState.onGainApply();
		this.checkBetSlipValues();
	}

	@action.bound
	async checkLengthValue(e) {
		if (e.target.value.length > 16) {
			e.target.value = e.target.value.substr(0, 16);
		}
	}

	@action.bound
	async onPaymentPerCombinationApply() {
		await this.betSlipState.onPaymentPerCombinationApply();
		this.checkBetSlipValues();
	}

	checkBetSlipValues = () => {
		if (this.betSlipState.error && this.betSlipState.error.error) {
			const containsStakeError =
				find(this.betSlipState.error.error, (errorItem) =>
					includes(stakeErrorCodes, errorItem.errorCode)
				) !== undefined;

			if (containsStakeError) {
				each(keysList, (key) => {
					this.betSlipState[key] = this.betSlipState._memory[key];
				});
			} else {
				each(keysList, (key) => {
					this.betSlipState._memory[key] = this.betSlipState[key];
				});
			}
		}
	};

	isCombinationSelected(key) {
		return this.betSlipState.selectedCombinations.indexOf(key) > -1;
	}

	isEventBank(eventId) {
		return this.betSlipState.bankEvents.indexOf(eventId) > -1;
	}

	@action.bound
	async addRemoveOffer(tip) {
		this.loader.suspend();
		if (!this.betSlipState.isReuseOn) {
			this.showReuseButton = false;
		}
		if (!this.betSlipState.isSubmitting) {
			if (
				this.betSlipState.isOneClickBetActive &&
				(this.betSlipState.oneClickBetAmount === "" ||
					isNaN(parseFloat(this.betSlipState.oneClickBetAmount)) ||
					parseFloat(this.betSlipState.oneClickBetAmount) <= 0)
			) {
				this._showErrorMessage(
					App.state.localizationService.t(
						"BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE"
					)
				);
				this.loader.resume();
				return;
			}

			if (this.betSlipState.isOneClickBetActive) {
				if (tip.isLive) {
					this.startCountdown();
				}

				if (
					this.rootStore.liveOfferMenuStore?.liveEventsFromMyBets !=
					null
				) {
					this.rootStore.liveOfferMenuStore.liveEventsFromMyBets.addPlayedOneClickTip(
						tip
					);
				}
			}

			const currentAccountType = localStorage.getItem("acc");

			await this.betSlipState.addRemoveOffer(tip, currentAccountType);
			this.loader.resume();
		}
	}

	@action.bound
	onResetSlip() {
		this.showSlipResetModal = true;
	}

	@action.bound
	onAcceptSlipReset() {
		this.betSlipState.reset(true, { resetSubmitDelay: false });
		this.fullErrorMessage = "";
		this.showSlipResetModal = false;
	}

	@action.bound
	onRejectSlipReset() {
		this.showSlipResetModal = false;
	}

	@action.bound
	async onRemoveEventOfferFromBetSlip(eventId) {
		this.showReuseButton = false;
		this.betSlipState.removeEventOfferFromBetSlip(eventId);

		if (this.betSlipState.slip.betSlipOffers.length === 0) {
			runInAction(() => {
				this.fullErrorMessage = "";
			});
		}
	}

	@action.bound
	async onOneClickBetValueChange(currentValue) {
		if (
			this.betSlipState.isOneClickBetActive &&
			(currentValue === "" ||
				isNaN(parseFloat(currentValue)) ||
				parseFloat(currentValue) <= 0)
		) {
			this._showErrorMessage(
				App.state.localizationService.t(
					"BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE"
				)
			);
		}

		this.betSlipState.onOneClickBetAmountChange(currentValue);
	}

	@action.bound
	updateAccountBalance(slipAmount) {
		if (!this.betSlipState.hasErrors) {
			App.state.rootStore.userAuthStore.resolveBalance(-1 * slipAmount);
		} else {
			App.state.logger.logError("Error", this.betSlipState.error);
		}
	}

	@action.bound
	toggleConfirmationModal(e) {
		e.preventDefault();

		if (App.utils.isUserAuthenticated()) {
			this.showConfirmationModal = !this.showConfirmationModal;
		} else {
			App.utils.authorization.loginPopup();
		}
	}

	@action.bound
	onPaymentPerCombinationChange(value) {
		if (isNaN(value) || value < 0) {
			this._showErrorMessage(
				App.state.localizationService.t(
					"BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE"
				)
			);
			return;
		}
		this.showReuseButton = false;
		this.betSlipState.onPaymentPerCombinationChange(value);
	}

	@action.bound
	onTotalStakeChange(value) {
		if (isNaN(value) || value <= 0) {
			this._showErrorMessage(
				App.state.localizationService.t(
					"BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE"
				)
			);
			return;
		}
		this.showReuseButton = false;
		this.betSlipState.onTotalStakeChange(value);
	}

	@action.bound
	onGainChange(value) {
		if (isNaN(value) || value < 0) {
			this._showErrorMessage(
				App.state.localizationService.t(
					"BET_SLIP.ERRORS.VALUE_LESS_THAN_ONE"
				)
			);
			return;
		}
		this.showReuseButton = false;
		this.betSlipState.onGainChange(value);
	}

	@action.bound
	addRemoveBank(eventId) {
		this.showReuseButton = false;
		this.betSlipState.addRemoveBank(eventId);
	}

	@action.bound
	addRemoveCombination(comboKey) {
		this.showReuseButton = false;
		this.betSlipState.addRemoveCombination(comboKey);
	}

	@action.bound
	onBetSlipTypeChange(betSlipType) {
		this.betSlipState.onBetSlipTypeChange(betSlipType);
	}

	@action.bound
	toggleShowReuseButton() {
		this.showReuseButton = true;
	}

	isInBetSlip = (tipId) => {
		return this.betSlipState.isInBetSlip(tipId);
	};

	@action.bound
	setDefaultBetSlipStake() {
		localStorageProvider.set("dsv", this.betSlipState.payment);
		toastr.success(
			App.state.localizationService.t("BET_SLIP.DEFAULT_STAKE_MESSAGE", {
				stake: this.betSlipState.payment,
				currency: App.state.rootStore.currencyStore.currency,
			})
		);
	}

	@action.bound
	async getSlipAndMap(slipId) {
		runInAction(() => {
			this.isReuseInProgress = true;
		});
		let response = await this.betSlipService.getSlip(slipId);
		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.Normal);
		// display tip comes unformatted from API, we need to format it (pre:outcometext from API)
		response.betSlipOffers.forEach(
			(offer) => (offer.displayTip = getTip(offer))
		);
		response.betSlipOffers = unionBy(
			this.betSlipState.slip.betSlipOffers.slice(),
			response.betSlipOffers,
			"bettingOfferId"
		);
		if (response !== null) {
			runInAction(async () => {
				await this.betSlipState.useExistingSlip(response, {
					acceptOddsChanges: true,
					useValidOffersOnly: true,
					paymentType: response.betSlipOffers.some(
						(element, index) => {
							return (
								response.betSlipOffers.findIndex(
									(item) => item.eventId === element.eventId
								) !== index
							);
						}
					)
						? "PerCombination"
						: "Total",
				});
			});
		}

		const params = new URLSearchParams(window.location.search);
		params.delete("bid");
		const finalParams = params.toString();
		window.history.pushState(
			{},
			"",
			`${window.location.pathname}${
				finalParams !== "" ? "?" + finalParams : ""
			}`
		);
	}

	@action.bound
	resetBetSlipTypeToDefault() {
		this.betSlipState.onBetSlipBetTypeChange(BetSlipBetType.Normal);
	}
}

export default BetSlipStore;
