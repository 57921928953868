import { observable, action, runInAction, computed, reaction } from 'mobx';

import { MainOfferStore, OfferMapper, rules, FavoritesStore } from '@gp/offer';
import { EventType, LiveStatus } from '@gp/models';
import { ConsoleLogger } from '@gp/utility';

import { BettingTypeSelectorsStore } from '../../components/selectors';
import AdditionalOfferStore from '../../AdditionalOfferStore';
import { LoaderStore } from '../../../../state/stores/common';

const logger = App.state.logger;

class LiveViewStore extends MainOfferStore {

    //#region observable

    @observable period = 'events';

    @observable eventId = null;
    @observable brid = null;
    @observable sportAbrv = null;
    @observable isLiveMatchTrackerExpanded = false;

    @observable isInitialize = false;
    @observable isStoreInitialized = false;
    @observable displayFavorites = false;

    //#endregion observable



    //#region computed

    @computed get isLive() {
        return true;
    }

    @computed get isLoading() {
        return this.loader.isLoading;
    }

    @computed get isEmpty() {
        return this.eventsToDisplayIsEmpty && this.isStoreInitialized;
    }

    @computed get eventsToDisplayIsEmpty() {

        let isEmpty = true;
        this.sportsToDisplay.some(
            s => {
                const eventsList = this.displayFavorites ? s.events.filter(item => this.eventFavoritesFilter(item.id)) : s.events;
                if (eventsList.length > 0) {
                    isEmpty = false;
                    return true;    // Break out of some when we know it is not empty
                }
            });
        return isEmpty
    }

    @computed get sportsToDisplay() {
        const listToFilter = this.displayFavorites ? this.eventsInSports.sports.filter(item => item.events.some(event => this.activeFavorites.find(af => af.id === event.id))) : this.eventsInSports.sports;
        if (this.rootStore.liveMenuViewStore.selected.length > 0) {
            return listToFilter.filter(s => this.rootStore.liveMenuViewStore.selected.includes(s.id));
        }

        return listToFilter;
    }

    @computed get bettingTypesByAbrv() {
        let btArr = [...this.bettingTypes.values()];

        return btArr.reduce((acc, item) => {
            acc[item.abrv] = item;
            return acc;
        }, {});
    }

    @computed get hasVisibleEvents() {
        return Array.from(this.eventsMap.keys()).some(eid => this.eventFavoritesFilter(eid));
    }

    @computed get allEventsAreFavorites() {
        return this.eventsMap.size > 0 && !this.displayFavorites && !this.hasVisibleEvents;
    }

    @computed get getEventId() {
        return this.eventId;
    }

    @computed get getBrid() {
        return this.brid;
    }

    @computed get getSportAbrv() {
        return this.sportAbrv;
    }

    @computed get isTrackerExpanded() {
        return this.isLiveMatchTrackerExpanded;
    }

    @computed get activeFavorites() {
        return this.configuration.favoritesStore.favoriteEventIds.filter(feId => this.eventsMap.has(feId)).map(feId => this.eventsMap.get(feId));
    }

    @computed get activeFavoritesCount() {
        return this.configuration.favoritesStore.favoriteEventIds.filter(feId => this.eventsMap.has(feId)).length;
    }

    @computed get isFavoritesEnabled() {

        return this.period !== 'upcoming';
    }

    //#endregion computed



    //#region constructor

    constructor(rootStore) {
        super({
            logger: new ConsoleLogger(false),
            removeDelay: 10,
            throttle: 4,
            enableThrottling: true,
            favoritesStore: new FavoritesStore(),
            bettingTypeSelectorsStore: new BettingTypeSelectorsStore()
        });

        this.rootStore = rootStore;
        this.additionalOfferStore = new AdditionalOfferStore(rootStore);
        this.period = 'events';

        // this.liveMatchTrackerViewStore = new LiveMatchTrackerViewStore();

        // this.liveCountReactionDisposer = reaction(() => this.eventsCount, count => {
        //     this.rootStore.liveSubheaderViewStore.setLiveCount(count);
        // }, {
        //     fireImmediately: true
        // });

        this.liveFavoritesCountReactionDisposer = reaction(() => ({
            favorites: this.activeFavoritesCount
        }), count => {
            this.rootStore.liveSubheaderViewStore.setCount('favoritesCount', count.favorites);
        }, {
            fireImmediately: true
        });

        this.loader = new LoaderStore();
    }

    //#endregion constructor



    //#region fetching data

    @action.bound
    onInitialize() {
        this.loader.suspend();
        this.isInitialize = true;

        const allBts = OfferMapper.getBettingTypes('live');

        let subscriptionRequest = {
            subscriptionId: 'live',
            // compress: true,
            compress: false,
            channels: [
                {
                    name: 'event',
                    filter: {
                        eventType: EventType.NORMAL,
                        liveStatus: LiveStatus.LIVE,
                    }
                },
                {
                    name: 'betOffer',
                    filter: [
                        {
                            bettingType: {
                                abrv: {
                                    eq: allBts.normal
                                }
                            }
                        },
                        {
                            bettingType: {
                                abrv: {
                                    eq: allBts.marginal
                                }
                            },
                            isFavorite: true
                        }
                    ]
                }
            ]
        };

        this.subscription = this.rootStore.hub.getOfferSubscription(subscriptionRequest)
            .subscribe(response => {
                runInAction(() => {
                    if (this.rootStore.betSlipStore.betSlipState.submitted) {
                        this.rootStore.betSlipStore.toggleShowReuseButton();
                    }
                    this.assignOfferData(response);
                    this.loader.resume();
                    this.isInitialize = false;
                    this.isStoreInitialized = true;
                });
            }, err => {
                console.error(err);
                runInAction(() => {
                    this.loader.resume();
                    this.isInitialize = false;
                    this.isStoreInitialized = true;
                });
            });
    }

    //#endregion fetching data



    //#region  disposers

    @action.bound
    onDispose() {

        this.isStoreInitialized = false;

        this.disposeSubscription();
    }

    @action.bound
    disposeSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    @action.bound
    onReset() {
        this.setDisplayFavorites(false);

        this.period = 'events';
        // this.loader.suspend();

        this._resetData();
    }

    //#endregion disposers



    @action.bound
    setPeriod(period) {
        if (period !== this.period) {
            this.period = period;
        }
    }

    @action.bound
    setEventDetails(lmtData) {
        if (this.eventId !== lmtData.eventId) {
            this.eventId = lmtData.eventId;
            this.isLiveMatchTrackerExpanded = true;
        }
        else {
            this.isLiveMatchTrackerExpanded = false;
            this.eventId = undefined;
        }
        this.brid = lmtData.matchId;
        this.sportAbrv = lmtData.abrv;
    }

    @action.bound
    triggerRemoveEventFromFavorites(eventId) {
        this.removeEventFromFavorites(eventId);

        if (this.favoriteEventIds.length === 0) {
            App.state.redirect(`/${App.utils.getCurrentCulture()}/live/events`)
        }
    }

    @action.bound
    async loadTvs(event) {
        if (event.tvCoverage != null) {
            return;
        }

        const result = await this.rootStore.tvInfoService.getTvInfo(event.id);

        event.setTvCoverage(result);
    }

    @action.bound
    eventFavoritesFilter = (eventId) => {
        return this.configuration.favoritesStore.isInFavorites(eventId);
    }

    /**
     * Sets display favorites flag
     * @param display true if favorites should be displayed, false otherwise
     * @description WARNING: setting this will change behaviour of computeds and events filtered
     */
    @action.bound
    setDisplayFavorites(display) {
        this.displayFavorites = display;
    }
}

export default LiveViewStore;