import { observable, computed, action } from "mobx";

/**
 * @typedef {Object} Node
 * @property {string} label name
 * @property {string} abrv
 * @property {string} value id
 * @property {number=} count
 * @property {boolean} checked
 * @property {boolean} expanded
 * @property {Node[]=} children
 */

class NodeStore {
    _defaultVisibleChildrenCount = 10;

    /**
     * Node object without children
     * @type {Node}
     */
    @observable node = {};

    @observable checked = false;
    @observable expanded = false;

    /**
     * @type {NodeStore}
     */
    @observable parent = null;

    /**
     * @type {NodeStore[]}
     */
    @observable children = [];

    /**
     * @type {number}
     */
    @observable depth;

    /**
     * Defines number of visible children.
     * Default: 10
     * If value is -1, all elements are visible
     * @see {toggleVisible}
     * @type {number}
     */
    @observable visibleChildrenCount = this._defaultVisibleChildrenCount;

    /**
     * @param {Node} node 
     * @param {NodeStore} parent
     */
    constructor(node, treeStore, parent = null, depth = 0) {
        this.getCheckedState = this.getCheckedState.bind(this);

        const { children, ...plainNode } = node;

        this.node = plainNode;
        this.parent = parent;
        this.depth = depth;

        function isChecked() {
            if (plainNode.abrv === 'special-offer') {
                // return treeStore.selected.indexOf(`s:${parent.node.value}`) > -1 || (parent && parent.checked);
                return treeStore.specials.indexOf(parent.node.value) > -1 || (parent.checked);
            }
            else {
                return treeStore.selected.indexOf(plainNode.value) > -1 || (parent && parent.checked);
            }
        }

        this.checked = isChecked();

        if (children) {
            this.children = children.map(c => new NodeStore(c, treeStore, this, depth + 1));
        }

        this.expanded = this.isSomeChildChecked;
    }

    /**
     * @returns {boolean} True if every child node is checked
     */
    @computed get isEveryChildChecked() {
        return this.children.every(c => c.checkState === 1);
    }

    /**
     * @returns {boolean} True if atleast one child node is checked
     */
    @computed get isSomeChildChecked() {
        return this.children.some(c => c.checkState > 0);
    }

    @computed get isSomeChildExpanded() {
        return this.children.some(c => c.expanded);
    }

    /**
     * @returns {boolean} True if all children should be visible
     */
    @computed get allChildrenVisible() {
        return this.visibleChildrenCount === -1;
    }

    /**
     * @returns {boolean} True if there are more children than visible children limit
     */
    @computed get showMoreVisible() {
        return this.children.length > this.visibleChildrenCount;
    }

    /**
     * 0 - node is leaf and is not checked, node is not leaf and some or all of its children are checked (not checked)
     * 1 - node is leaf and is checked, node is not leaf and all of its children are checked (checked)
     * 2 - node is not leaf and some of its children are checked (indeterminate state)
     * @returns {0|1|2}
     */
    @computed get checkState() {
        if (this._initialChecked) {
            return this._initialChecked ? 1 : 0;
        }

        if (this.isLeaf) {
            return this.checked ? 1 : 0;
        }

        if (this.isEveryChildChecked) {
            return 1;
        }

        if (this.isSomeChildChecked) {
            return 2;
        }

        return 0;
    }

    /**
     * If true, node is parent
     * @returns {boolean} True if node has children
     */
    @computed get isParent() {
        return this.children.length > 0;
    }

    /**
     * @returns {boolean} True if node does not have children
     */
    @computed get isLeaf() {
        return !this.isParent;
    }

    /**
     * @returns {boolean} True if node has parent
     */
    @computed get isChild() {
        return this.parent != null;
    }

    /**
     * Set check state
     * @param {boolean} value - calculated using getCheckedState({toggle: true}) in PrematchSideMenuTemplate.jsx
     */
    @action
    onCheck(value) {
        // node checked, we are not toggling
        if (this.isLeaf) {
            this.checked = value;
        }
        else {
            if (this.isParent) {
                this.children.forEach(child => {
                    child.onCheck(value, false);
                    //child.expand();
                });
                if(value) {
                    this.expand();
                } else if(!this.isLeaf) {
                    this.expanded = false;
                }
            }
        }
    }

    @action.bound
    toggleParent() {
        if (this.isChild) {
            this.parent.toggleParent();
        }
        else {
            // set checked on parent only if all nodes are checked
            this.checked = this.isEveryChildChecked;
        }
    }

    @action.bound
    toggleExpanded() {
        if (this.isLeaf) {
            // TODO: check
        }
        else {
            this.expanded = !this.expanded;
        }
    }

    @action.bound
    expand() {
        if (this.isLeaf) {
            // TODO: check
        }
        else {
            if( !this.expanded) {
                this.expanded = true;
            }
        }
    }

    @action.bound
    onlyCollapse() {
        this.expanded = false;
        
        this.children.forEach(c => c.onlyCollapse());
    }

    /**
     * Collapses node and all children
     */
    @action.bound
    collapse() {
        this.expanded = false;
        this.checked = false;

        this.children.forEach(c => c.collapse());
    }

    @action.bound
    toggleVisible(event) {
        if (this.visibleChildrenCount === this._defaultVisibleChildrenCount) {
            this.visibleChildrenCount = -1;
        }
        else {
            this.visibleChildrenCount = this._defaultVisibleChildrenCount
            window.scrollTo(0, event.currentTarget.closest('li').firstChild.firstChild.getBoundingClientRect().top + window.scrollY - 184);
        }
    }

    /**
     * @param {{toggle: boolean}} params 
     */
    getCheckedState(params) {
        const { toggle } = params;
        // toggle off state to checked
        if (this.checkState === 0 && toggle) {
            return true;
        }

        // node already checked and we are not toggling
        if (this.checkState === 1 && !toggle) {
            return true;
        }

        // partial state toggle - optimistic toggle
        if (this.checkState === 2) {
            return true;
        }

        return false;
    }
}


export default NodeStore;