const httpClient = App.utils.httpClient;

const requestHeaders = {
	'Content-Type': 'application/json',
};

class TvInfoService {
	async getTvInfo(eventId) {
		const url = `${BaseAddress}platform/events/tv-information`;
		var query = new URLSearchParams();
		query.set('rpp', 1);
		query.set('page', 1);
		query.set('translate', true);
		query.set('ids', eventId);
		const result = await httpClient.get(
			`${url}?${query}`,
			requestHeaders,
			{ responseType: "json" }
		);

		if (result && result.item != null && result.item[0] != null) {
			return result.item[0].info;
		}

		return '';
	}
}

export default TvInfoService;