import { observable, action, computed } from "mobx";

export class LivePeriodSubMenuStore {


    //#region constructor

    constructor(parentMenu) {
        this.parentMenu = parentMenu;
        this.rootStore = this.parentMenu.rootStore;
    }

    //#endregion constructor

    @observable selectedPeriodKey = 'events';
    @observable prevPeriod = 'events';

    @computed get selectedPeriod() {
        return { period: this.selectedPeriodKey };
    }


    @action.bound
    setPeriodKey(period) {
        if (this.selectedPeriodKey !== period) {
            this.prevPeriod = this.selectedPeriodKey;
            this.selectedPeriodKey = period;

            if (this.selectedPeriodKey !== 'events') {
                if (App.state.history.action !== 'POP') {
                    this.parentMenu.menu.onCheck(0);
                }
                this.parentMenu.deselectSport(null);
                this.parentMenu.closeOfferMenu();
            }

            // Scroll to top of the page on period change
            window.scroll(0, 0);

            if (this.selectedPeriodKey === 'played-events' && this.parentMenu?.liveEventsFromMyBets?.count === 0) {
                App.state.redirect('events');
            }
        }
    }
}